import { UserCredentials } from "../models/UserCredentials";
import { Config } from "../config/Config";
import { Response } from "../models/Response";

const loginUrl = Config.apiUrl + "auth/login";

export interface ILoginService {
  login(userCredentials: UserCredentials): Promise<Response<void>>;
}

export class LoginService implements ILoginService {
  async login(userCredentials: UserCredentials): Promise<Response<void>> {
    const resp = await fetch(loginUrl, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userCredentials),
    });

    if (resp.ok) {
      return { kind: "success", value: undefined };
    } else if (resp.status === 400) {
      const error = await resp.json();

      return {
        kind: "error",
        code: error.code,
      };
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }
}

export const loginService = new LoginService();
