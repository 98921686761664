interface Result {
  type: string;
}

interface AuthenticationResult extends Result {
  authorization_code: string;
  code_verifier: string;
}

export default function SendMessage(dataObject: AuthenticationResult) {
  try {
    //Iframe
    if (window.parent) {
      window.parent.postMessage(dataObject, "*");
      console.log("authorization_code", dataObject.authorization_code);
      console.log("code_verifier", dataObject.code_verifier);
    }

    //Cordova
    if (window.webkit !== undefined) {
      window.webkit.messageHandlers.cordova_iab.postMessage(
        JSON.stringify(dataObject)
      );
    }
  } catch (e) {
    console.log(e);
  }

  return;
}

export function CloseMessage() {
  const dataObject = {
    type: "close",
  };
  try {
    //Iframe
    if (window.parent) {
      window.parent.postMessage(dataObject, "*");
    }

    //Cordova
    if (window.webkit !== undefined) {
      window.webkit.messageHandlers.cordova_iab.postMessage(
        JSON.stringify(dataObject)
      );
    }
  } catch (e) {
    console.log(e);
  }

  return;
}

export function ScrollToTopMessage() {
  const dataObject = {
    type: "scroll_to_top",
  };
  try {
    //Iframe
    if (window.parent) {
      window.parent.postMessage(dataObject, "*");
    }

    //Cordova
    if (window.webkit !== undefined) {
      window.webkit.messageHandlers.cordova_iab.postMessage(
        JSON.stringify(dataObject)
      );
    }
  } catch (e) {
    console.log(e);
  }

  return;
}
