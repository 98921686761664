import React, { useEffect, useState, ChangeEvent } from "react";
import { useForm, SubmitHandler, UseFormRegister } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import CredentialsFooter from "../../../../components/continente-credentials/footer/Footer";
import CredentialsForm from "../../../../components/continente-credentials/Form/Form";
import CredentialsHeader from "../../../../components/continente-credentials/header/Header";
import MantraButton from "../../../../components/continente-credentials/mantra-button/MantraButton";
import CredentialsInput from "../../../../components/continente-credentials/input/Input";
import TermsAndConditionsCheckbox from "../../../../components/continente-credentials/terms-and-conditions-checkbox/TermsAndConditionsCheckbox";
import CheckBullet from "../../../../components/continente-credentials/bullets/CheckBullet";
import { PhoneNumberOtpValidationResponse } from "../../../../models/continente-credentials/mobile/PhoneNumberOtpValidationResponse";
import { SetPassword } from "../../../../models/continente-credentials/mobile/SetPassword";
import { FormError } from "../../../../models/continente-credentials/common/FormError";
import { setPasswordService } from "../../../../services/continente-credentials/mobile/SetPasswordService";
import { useTranslation } from "react-i18next";
import {
  verifySpecialChars,
  verifyLowerCase,
  verifyMinChar,
  verifyNumber,
  verifyUpperCase,
} from "../../../../utils/passwordRules";
import ErrorMessage from "../../../../components/continente-credentials/error-message/error-message";

type Form = SetPassword & FormError;

export default function PhonenumberSetPassword() {
  const history = useHistory();
  const [labelPasswordVisible, setLabelPasswordVisible] =
    useState<boolean>(false);
  const [
    labelPasswordConfirmationVisible,
    setLabelPasswordConfirmationVisible,
  ] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [acceptedTermsConditions, setAcceptedTermsConditions] =
    useState<boolean>(true);
  const [minCharacters, setMinCharacters] = useState<boolean>(false);
  const [containNumber, setContainNumber] = useState<boolean>(false);
  const [containUppercase, setContainUppercase] = useState<boolean>(false);
  const [containLowercase, setContainLowercase] = useState<boolean>(false);
  const [containSpecialChar, setContainSpecialChar] = useState<boolean>(false);
  const [passwordsMatch, setPasswordsMatch] = useState<boolean>(false);
  const [isButtonEnabled, setButtonEnabled] = useState<boolean>(false);
  const [isCheckBoxChecked, setCheckBoxChecked] = useState<boolean>(false);
  const goToFaq = (anchor?: string) => {
    if (anchor) anchor = "#" + anchor;
    history.push("/site-credentials/faq" + anchor);
  };
  const ANCHOR_NEED_HELP = "";
  const { t } = useTranslation();
  const location = useLocation<PhoneNumberOtpValidationResponse>();

  const formState = useForm<Form>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = formState;

  const handlePasswordChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    clearErrors();
    setPassword(value);
    setValue("password", value);
    checkAndEnableCheckBox(value, passwordConfirmation);
    setLabelPasswordVisible(value.length > 0 ? true : false);
  };

  const handlePasswordConfirmationChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    clearErrors();
    setPasswordConfirmation(value);
    setValue("passwordConfirmation", value);
    checkAndEnableCheckBox(password, value);
    setLabelPasswordConfirmationVisible(value.length > 0 ? true : false);
  };

  const handleAcceptedTermsConditionsChange = (): void => {
    setAcceptedTermsConditions((acceptedTermsConditions) => {
      return !acceptedTermsConditions;
    });

    setValue("acceptedTermsConditions", acceptedTermsConditions);
    setCheckBoxChecked(acceptedTermsConditions);
    checkAndEnableCheckBox(
      password,
      passwordConfirmation,
      acceptedTermsConditions
    );
  };

  const onSubmit: SubmitHandler<SetPassword> = async (data) => {
    setLoading(true);
    if (password === passwordConfirmation) {
      try {
        const response = await setPasswordService.setPassword(
          {
            password: data.password,
            passwordConfirmation: data.passwordConfirmation,
            acceptedTermsConditions: data.acceptedTermsConditions,
          },
          location.state != null ? location.state.nextStep : ""
        );

        switch (response.kind) {
          case "success":
            history.push(response.value.nextStep.substring(4), response.value);
            break;
          default:
            setError("submitError", { message: t("common.unexpected_error") });
            break;
        }

        setLoading(false);
      } catch (e) {
        setError("submitError", { message: t("common.unexpected_error") });
        setLoading(false);
      }
    } else {
      setError("password", {
        message: t("password_definition.passwords_not_match"),
      });
      setError("passwordConfirmation", {
        message: t("password_definition.passwords_not_match"),
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    const passwordValidator = (register: UseFormRegister<Form>) =>
      register("password", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
        pattern: {
          value: /^((?=.*\d)(?=.*[A-Za-z])).{6,}$/,
          message: t("password_definition.password_invalid"),
        },
      });
    const passwordConfirmationValidator = (register: UseFormRegister<Form>) =>
      register("passwordConfirmation", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
        pattern: {
          value: /^((?=.*\d)(?=.*[A-Za-z])).{6,}$/,
          message: t("password_definition.password_invalid"),
        },
      });
    const acceptedTermsConditionsValidator = (
      register: UseFormRegister<Form>
    ) =>
      register("acceptedTermsConditions", {
        required: {
          value: true,
          message: "",
        },
      });
    passwordValidator(register);
    passwordConfirmationValidator(register);

    if (location.state.properties.mustAcceptTermsConditions) {
      acceptedTermsConditionsValidator(register);
    }
  }, [location.state.properties.mustAcceptTermsConditions, register, t]);

  useEffect(() => {
    if (!verifyMinChar(password)) setMinCharacters(false);
    else setMinCharacters(true);

    if (verifyNumber(password)) setContainNumber(true);
    else setContainNumber(false);

    if (verifyUpperCase(password)) setContainUppercase(true);
    else setContainUppercase(false);

    if (verifyLowerCase(password)) setContainLowercase(true);
    else setContainLowercase(false);

    if (verifySpecialChars(password)) setContainSpecialChar(true);
    else setContainSpecialChar(false);
  }, [password]);

  function verifyPasswordsMatch(pw: string, pwConfirmation: string) {
    if (pw === pwConfirmation && pw !== "") {
      setPasswordsMatch(true);
      return true;
    }

    setPasswordsMatch(false);
    return false;
  }

  function checkAndEnableCheckBox(
    pw: string,
    pwConfirmation: string,
    isChecked: boolean | null = null
  ) {
    const checked = isChecked ?? isCheckBoxChecked;

    if (
      verifyPasswordsMatch(pw, pwConfirmation) &&
      verifyMinChar(pw) &&
      verifyNumber(pw) &&
      verifyLowerCase(pw) &&
      verifyUpperCase(pw) &&
      verifySpecialChars(pw) &&
      checked
    ) {
      setButtonEnabled(true);
    } else {
      setButtonEnabled(false);
    }
  }

  return (
    <>
      <CredentialsForm
        id="SetUserPassword"
        form={formState}
        autoComplete="on"
        onSubmit={(e) => {
          clearErrors();
          handleSubmit(onSubmit)(e);
        }}
      >
        <div className="row justify-content-center">
          <CredentialsHeader
            title={t("password_definition.header_title")}
            subtitle={t("password_definition.header_message")}
            showLoading={loading}
            previousStepBackXTimes={2}
          />
        </div>
        <div className="row d-flex justify-content-center">
          <div className="input-outline-eye">
            <CredentialsInput
              labelText={t("password_definition.password_input")}
              isFilled={labelPasswordVisible}
              isError={errors.password ? true : false}
              errorMsg={errors.password ? errors.password.message : ""}
              onChange={handlePasswordChange}
              type="password"
            />
            <CredentialsInput
              labelText={t("password_definition.password_input")}
              isFilled={labelPasswordConfirmationVisible}
              isError={errors.passwordConfirmation ? true : false}
              errorMsg={
                errors.passwordConfirmation
                  ? errors.passwordConfirmation.message
                  : ""
              }
              onChange={handlePasswordConfirmationChange}
              type="password"
            />
          </div>
          <div className="form-check-group mt-4">
            <div className="d-flex w-75 m-auto flex-wrap flex-column">
              <div className="row d-flex justify-content-left password_rules_header">
                {t("password_definition.password_validation_header")}
              </div>

              <CheckBullet
                checked={minCharacters}
                text={t(
                  "password_definition.password_validation_length_maximum"
                )}
              />
              <CheckBullet
                checked={containNumber}
                text={t("password_definition.password_validation_number")}
              />
              <CheckBullet
                checked={containUppercase}
                text={t("password_definition.password_validation_uppercase")}
              />
              <CheckBullet
                checked={containLowercase}
                text={t("password_definition.password_validation_lowercase")}
              />
              <CheckBullet
                checked={containSpecialChar}
                text={t("password_definition.password_validation_special_char")}
              />
              <CheckBullet
                checked={passwordsMatch}
                text={t("password_definition.passwords_match")}
              />
            </div>
          </div>
        </div>
        {location.state.properties.mustAcceptTermsConditions && (
          <div className="form-check-group-copy">
            <div className="form-check">
              <TermsAndConditionsCheckbox
                onClick={handleAcceptedTermsConditionsChange}
                isError={errors.acceptedTermsConditions ? true : false}
              />
            </div>
          </div>
        )}
        <ErrorMessage error={errors.submitError} />
        <div className="row d-flex justify-content-center">
          <MantraButton
            isDisabled={!isButtonEnabled}
            text={t("password_definition.button_next_message")}
            type="submit"
          />
        </div>
        <div className="contextual-information-header text-align-center">
          <span
            className="verification-resend-code link"
            onClick={() => goToFaq(ANCHOR_NEED_HELP)}
          >
            {t("common.need_help")}
          </span>
        </div>
        <div className="row d-flex flex-column">
          <CredentialsFooter />
        </div>
      </CredentialsForm>
    </>
  );
}
