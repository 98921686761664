import React from "react";

type FAQButtonPros = {
  onClick: () => void;
};

export default function FAQButton({ ...props }: FAQButtonPros) {
  const questionWhite =
    require("../../../assets/images/question-white.svg") as string;
  return (
    <div className="sc-faq-container" onClick={props.onClick}>
      <img src={questionWhite} alt="question-mark" />
    </div>
  );
}
