import { CardCreation } from "./../../../models/continente-credentials/mobile/CardCreation";
import { Response } from "../../../models/continente-credentials/common/Response";
import { CardCreationResponse } from "../../../models/continente-credentials/mobile/CardCreationResponse";

export interface ICardCreationService {
  cardCreation(
    cardCreation: CardCreation,
    cardCreationUrl: string
  ): Promise<Response<CardCreationResponse>>;
}

export class CardCreationService implements ICardCreationService {
  async cardCreation(
    cardCreation: CardCreation,
    cardCreationUrl: string
  ): Promise<Response<CardCreationResponse>> {
    const resp = await fetch(cardCreationUrl, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(cardCreation),
    });
    if (resp.ok) {
      return { kind: "success", value: await resp.json() };
    } else if (resp.status === 400) {
      const error = await resp.json();

      const returnedError = error.find((c: any) => c.Code != null);
      return {
        kind: "error",
        code: returnedError.Code ? returnedError.Code : 0,
        message: returnedError.message ? returnedError.message : "",
      };
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }
}

export const cardCreationService = new CardCreationService();
