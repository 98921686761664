import React, { useState } from "react";
import CredentialsHeader from "../../../components/continente-credentials/header/Header";
import CredentialsFooter from "../../../components/continente-credentials/footer/Footer";
import { useTranslation } from "react-i18next";
import ContinenteCardSvg from "../../../assets/images/continente-card.svg";
import ContinenteAppPng from "../../../assets/images/app-cc.svg";
import ContinenteOnlinePng from "../../../assets/images/continente-online.svg";
import ContinenteSigaPng from "../../../assets/images/siga.svg";
import { useLocation, useHistory } from "react-router-dom";
import { IdentifierValidationResponse } from "../../../models/continente-credentials/loyaltycard/IdentifierValidationResponse";
import { loyaltyCardService } from "../../../services/continente-credentials/loyaltycard/LoyaltyCardService";
import BottomSheet from "../../../components/continente-credentials/bottom-sheet/BottomSheet";
import LoyaltyCardAdvantages from "../../../components/continente-credentials/loyalty-card-advantages/LoyaltyCardAdvantages";
import ErrorMessage from "../../../components/continente-credentials/error-message/error-message";
import MantraButton from "../../../components/continente-credentials/mantra-button/MantraButton";

export default function LoyaltyCardAssociationSuccess() {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation<IdentifierValidationResponse>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isBottomSheetOpen, setBottomSheetOpen] = useState(false);

  const closeBottomSheet = (isClosed: boolean) => {
    setBottomSheetOpen(!isClosed);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await loyaltyCardService.loyaltyCardSuccessAssociation(
        location.state != null ? location.state.nextStep : ""
      );
      if (response.kind === "success") {
        history.push(response.value.nextStep.substring(4), response.value);
      }
      if (response.kind === "error") {
        setError(t("common.unexpected_error"));
      }

      setLoading(false);
    } catch (e) {
      setError(t("common.unexpected_error"));
      setLoading(false);
    }
  };

  return (
    <>
      <div className="row justify-content-center">
        <CredentialsHeader
          title={t("loyalty_card_association_success.header_title")}
          subtitle={t("loyalty_card_association_success.header_message")}
          showLoading={loading}
        />
      </div>

      <div className="row justify-content-center">
        <img
          src={ContinenteCardSvg}
          className="continente-card-little"
          alt=""
        />
      </div>
      <div className="row d-flex flex-column">
        <div>
          <div className="contextual-information-copy-2">
            {t("common.mobile_footer_message")}
          </div>
          <div className="container group">
            <div className="row align-items-center">
              <div className="col">
                <img
                  src={ContinenteOnlinePng}
                  className="login-footer"
                  alt=""
                />
              </div>
              <div className="col">
                <img src={ContinenteAppPng} className="login-footer" alt="" />
              </div>
              <div className="col">
                <img src={ContinenteSigaPng} className="login-footer" alt="" />
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col icon-label">
                {t("common.continente_online")}
              </div>
              <div className="col icon-label">
                {t("common.cartao_continente")}
              </div>
              <div className="col icon-label">
                {t("common.continente_siga")}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row d-flex justify-content-center mt-5">
        <MantraButton
          text={t("loyalty_card_association_success.button_next_message")}
          type="button"
          size="large"
          onClick={handleSubmit}
        />
      </div>
      <ErrorMessage errorString={error} />
      <div className="row d-flex flex-column">
        <CredentialsFooter
          contextualInformationVisible={false}
          footerAppsWebsitesIconsVisible={false}
        />
      </div>
      {isBottomSheetOpen && (
        <BottomSheet
          content={<LoyaltyCardAdvantages />}
          closeBottomSheet={closeBottomSheet}
        />
      )}
    </>
  );
}
