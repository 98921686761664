import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";

import Header from "../../../components/site-credentials/header/Header";
import Footer from "../../../components/site-credentials/footer/Footer";
import { PhoneNumberDuplicated } from "../../../models/site-credentials/mobile/PhoneNumberDuplicated";
import { phoneNumberDuplicatedService } from "../../../services/site-credentials/mobile/DuplicatedPhoneNumberAssociationService";
import { UserSignInResponse } from "../../../models/site-credentials/mobile/UserSignInResponse";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";

import megaphone from "../../../assets/images/megaphone.svg";

export default function DuplicatedPhoneNumberAssociation() {
  const history = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const location = useLocation<UserSignInResponse>();
  const phoneNumber =
    location.state != null ? location.state.properties.usernamePhoneNumber : "";

  const { handleSubmit, setError, clearErrors } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const onSubmit: SubmitHandler<PhoneNumberDuplicated> = async (data) => {
    setLoading(true);
    try {
      const response = await phoneNumberDuplicatedService.phoneNumberDuplicated(
        {
          phoneNumber: phoneNumber,
        },
        location.state != null ? location.state.nextStep : ""
      );

      switch (response.kind) {
        case "success": {
          history.push(response.value.nextStep.substring(4), {
            ...response.value,
            responseCode: "100000",
          });
          break;
        }
        case "error":
          if (response.code === "300002") {
            setError("submitError", {
              message: t("common.unexpected_error"),
            });
            break;
          } else if (response.code === "300017") {
            history.push("/site-credentials", {
              responseCode: response.code,
            });
          }
          break;

        default:
          setError("submitError", { message: t("common.unexpected_error") });
          break;
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <>
      <form
        id="DuplicatedPhoneNumberAssociation"
        className="d-flex space-bet"
        autoComplete="off"
        onSubmit={(e) => {
          clearErrors();
          handleSubmit(onSubmit)(e);
        }}
      >
        <div className="row justify-content-center">
          <Header
            title={t("site_credentials.alert")}
            subtitle={t(
              "site_credentials.phonenumber_association.duplicated_information_description"
            )}
            showLoading={loading}
            isTextAlignCenter={false}
            previousStepBackXTimes={2}
          />
          <img src={megaphone} className="sc-image-center" alt="megaphone" />
        </div>
        <Footer />
      </form>
    </>
  );
}
