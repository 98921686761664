import React from "react";
import SiteCredentialsButton from "../button/Button";

import { useTranslation } from "react-i18next";

type FooterProps = {
  previousStepBackXTimes?: number;
  isDisabled?: boolean;
  isSubmitButtonVisible?: boolean;
  method?: () => Promise<void>;
};

export default function Footer({
  previousStepBackXTimes = 0,
  isDisabled = false,
  isSubmitButtonVisible = true,
  method,
}: FooterProps) {
  const { t } = useTranslation();

  return (
    <div className="sc-footer-container">
      {isSubmitButtonVisible && (
        <div className="row d-flex justify-content-center">
          {method ? (
            <SiteCredentialsButton
              text={t("site_credentials.button_next_message")}
              type="button"
              onClick={method}
              isDisabled={isDisabled}
            />
          ) : (
            <SiteCredentialsButton
              text={t("site_credentials.button_next_message")}
              type="submit"
              isDisabled={isDisabled}
            />
          )}
        </div>
      )}
      <div className="row d-flex justify-content-center">
        {previousStepBackXTimes && previousStepBackXTimes > 0 ? (
          <SiteCredentialsButton
            text={t("site_credentials.button_back_message")}
            type="button"
            isBackButton
            previousStepBackXTimes={previousStepBackXTimes}
          />
        ) : null}
      </div>
    </div>
  );
}
