import React from "react";
import useGetDigitalAssetId from "../hooks/useGetDigitalAssetId";

function onMessage(e: MessageEvent) {
  switch (e.data.type) {
    case "success":
      alert(
        `login success: authorizationCode: ${e.data.authorization_code} codeVerifier: ${e.data.code_verifier} `
      );
      break;
  }
}

export default function IFrame() {
  const digitalAssetId = useGetDigitalAssetId() || 1;

  window.onmessage = onMessage;

  return (
    <>
      <div style={{ marginTop: "50px", display: "flex" }}>
        <iframe
          title="credenciais-continente"
          src={`/user-register?clientId=${digitalAssetId}`}
          frameBorder={0}
          width="400"
          height="840"
          style={{
            margin: "0 auto",
            backgroundColor: "white",
            border: "0.5px solid black",
            borderRadius: "1%",
            boxShadow: "5px 5px 5px grey",
          }}
        ></iframe>
      </div>
    </>
  );
}
