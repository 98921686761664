import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import CredentialsFooter from "../../../components/continente-credentials/footer/Footer";
import CredentialsHeader from "../../../components/continente-credentials/header/Header";
import CredentialsForm from "../../../components/continente-credentials/Form/Form";
import { PasswordRecoverValidateIdentifierResponse } from "../../../models/continente-credentials/mobile/PasswordRecoverValidateIdentifierResponse";
import { FormError } from "../../../models/continente-credentials/common/FormError";
import { passwordRecoverService } from "../../../services/continente-credentials/mobile/PasswordRecoverService";
import { useTranslation } from "react-i18next";
import { PasswordRecoverValidateIdentifier } from "../../../models/continente-credentials/mobile/PasswordRecoverValidateIdentifier";
import MantraButton from "../../../components/continente-credentials/mantra-button/MantraButton";

type Form = PasswordRecoverValidateIdentifier & FormError;

export default function SuccessMessage() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const location = useLocation<PasswordRecoverValidateIdentifierResponse>();
  const identifier =
    location.state != null ? location.state.properties.identifier : "";

  const formState = useForm<Form>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const {
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = formState;

  const onSubmit: SubmitHandler<PasswordRecoverValidateIdentifier> = async (
    data
  ) => {
    setLoading(true);

    try {
      const response = await passwordRecoverService.validateIdentifier(
        {
          identifier: identifier,
          nextStep: location.state.nextStep,
          email: location.state.properties.email,
          flowType: location.state.properties.flowType,
        },
        location.state != null
          ? location.state.properties.successMessageStep
          : ""
      );

      switch (response.kind) {
        case "success":
          history.push(response.value.nextStep.substring(4), response.value);
          break;

        case "error":
          setError("submitError", { message: t("common.unexpected_error") });
          break;

        default:
          setError("submitError", { message: t("common.unexpected_error") });
          break;
      }

      setLoading(false);
    } catch (e) {
      setError("submitError", { message: t("common.unexpected_error") });
      setLoading(false);
    }
  };

  return (
    <>
      <CredentialsForm
        id="SuccessMessage"
        form={formState}
        autoComplete="off"
        onSubmit={(e) => {
          clearErrors();
          handleSubmit(onSubmit)(e);
        }}
      >
        <div className="row justify-content-center">
          <CredentialsHeader
            title={t("password_recover_success.header_title")}
            subtitle={t("password_recover_success.header_message")}
            showLoading={loading}
          />
        </div>

        <div className="row d-flex justify-content-center">
          <MantraButton
            text={t("password_recover_success.button_next_message")}
            type="submit"
          />
        </div>
        <div className="row d-flex flex-column">
          <CredentialsFooter />
        </div>
      </CredentialsForm>
    </>
  );
}
