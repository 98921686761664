import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import Footer from "../../../components/site-credentials/footer/Footer";
import Header from "../../../components/site-credentials/header/Header";

import { OtpValidation } from "../../../models/site-credentials/mobile/OtpValidation";
import { FormError } from "../../../models/site-credentials/common/FormError";

import { useTranslation } from "react-i18next";

type Form = OtpValidation & FormError;

export default function AboutAssociateLoyaltyCard() {
  const history = useHistory();
  const { t } = useTranslation();

  const { handleSubmit, clearErrors } = useForm<Form>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const onSubmit = () => {
    history.push("/site-credentials/loyaltycard/association");
  };

  return (
    <>
      <form
        id="SetUserPassword"
        autoComplete="off"
        onSubmit={(e) => {
          clearErrors();
          handleSubmit(onSubmit)(e);
        }}
      >
        <div className="row justify-content-center">
          <Header
            title={t(
              "site_credentials.loyaltycard_association.about_information.title"
            )}
            subtitle={t(
              "site_credentials.loyaltycard_association.about_information.subtitle"
            )}
            previousStepBackXTimes={1}
          />
        </div>
        <div className="row d-flex flex-column">
          <Footer />
        </div>
      </form>
    </>
  );
}
