import { Config } from "../../../config/Config";
import { OtpResend } from "../../../models/continente-credentials/common/OtpResend";
import { Response } from "../../../models/continente-credentials/common/Response";

export interface IOtpService {
  resendOTP(OtpResend: OtpResend): Promise<Response<OtpResend>>;
  resendLoyaltyOTP(OtpResend: OtpResend): Promise<Response<OtpResend>>;
}

export class OtpService implements IOtpService {
  async resendOTP(OtpResend: OtpResend): Promise<Response<OtpResend>> {
    const resp = await fetch(Config.ContinenteCredentials.otpResendUrl, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(OtpResend),
    });

    if (resp.ok) {
      return { kind: "success", value: await resp.json() };
    } else if (resp.status === 400) {
      const error = await resp.json();

      let errorCode: string = "0";
      let errorMessage: string = "";
      if (error.length > 0) {
        if (error[0].Code) {
          errorCode = error[0].Code.toString();
          errorMessage = error[0].Message ? error[0].Message.toString() : "";
        }
      }

      return {
        kind: "error",
        code: errorCode,
        message: errorMessage,
      };
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }

  async resendEmailOTP(OtpResend: OtpResend): Promise<Response<OtpResend>> {
    const resp = await fetch(Config.ContinenteCredentials.resendEmailOtpUrl, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(OtpResend),
    });

    if (resp.ok) {
      return { kind: "success", value: await resp.json() };
    } else if (resp.status === 400) {
      const error = await resp.json();

      let errorCode: string = "0";
      let errorMessage: string = "";
      if (error.length > 0) {
        if (error[0].Code) {
          errorCode = error[0].Code.toString();
          errorMessage = error[0].Message ? error[0].Message.toString() : "";
        }
      }

      return {
        kind: "error",
        code: errorCode,
        message: errorMessage,
      };
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }

  async resendLoyaltyOTP(OtpResend: OtpResend): Promise<Response<OtpResend>> {
    const resp = await fetch(Config.ContinenteCredentials.resendLoyaltyOtpUrl, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(OtpResend),
    });

    if (resp.ok) {
      return { kind: "success", value: await resp.json() };
    } else if (resp.status === 400) {
      const error = await resp.json();

      let errorCode: string = "0";
      let errorMessage: string = "";
      if (error.length > 0) {
        if (error[0].Code) {
          errorCode = error[0].Code.toString();
          errorMessage = error[0].Message ? error[0].Message.toString() : "";
        }
      }

      return {
        kind: "error",
        code: errorCode,
        message: errorMessage,
      };
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }

  async resendPasswordRecoverOTP(
    OtpResend: OtpResend
  ): Promise<Response<OtpResend>> {
    const resp = await fetch(
      Config.SiteCredentials.passwordRecoverOtpResendUrl,
      {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(OtpResend),
      }
    );

    if (resp.ok) {
      return { kind: "success", value: await resp.json() };
    } else if (resp.status === 400) {
      const error = await resp.json();

      let errorCode: string = "0";
      let errorMessage: string = "";
      if (error.length > 0) {
        if (error[0].Code) {
          errorCode = error[0].Code.toString();
          errorMessage = error[0].Message ? error[0].Message.toString() : "";
        }
      }

      return {
        kind: "error",
        code: errorCode,
        message: errorMessage,
      };
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }
}

export const otpService = new OtpService();
