import { EmailAssociation } from "../../../models/continente-credentials/mobile/EmailAssociation";
import { EmailAssociationResponse } from "../../../models/continente-credentials/mobile/EmailAssociationResponse";
import { Response } from "../../../models/continente-credentials/common/Response";

export interface IEmailAssociationService {
  emailAssociation(
    emailAssociation: EmailAssociation,
    emailAssociationUrl: string
  ): Promise<Response<EmailAssociationResponse>>;
}

export class EmailAssociationService implements IEmailAssociationService {
  async emailAssociation(
    emailAssociation: EmailAssociation,
    emailAssociationUrl: string
  ): Promise<Response<EmailAssociationResponse>> {
    const resp = await fetch(emailAssociationUrl, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(emailAssociation),
    });

    if (resp.ok) {
      return { kind: "success", value: await resp.json() };
    } else if (resp.status === 400) {
      const error = await resp.json();

      const returnedError = error[0];

      return {
        kind: "error",
        code: returnedError.Code,
        message: returnedError.message,
      };
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }
}

export const emailAssociationService = new EmailAssociationService();
