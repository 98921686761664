import React, { ChangeEvent, useState } from "react";
import InputMask from "react-input-mask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-regular-svg-icons";

type InputProps = {
  labelText: string;
  isError?: boolean;
  errorMsg?: string;
  isFilled: boolean;
  type?: "password" | "text";
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  disabled?: boolean;
  showCheckIcon?: boolean;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: ChangeEvent<HTMLInputElement>) => void;
  showError?: boolean;
  inputMode?: "numeric" | "text";
};

export default function PinInput({
  showError = true,
  inputMode = "text",
  ...props
}: InputProps) {
  const [isHidden, setIsHidden] = useState<boolean>(props.type === "password");
  const check = require("../../../assets/images/check2-min.svg") as string;
  const alertCircle =
    require("../../../assets/images/alert-circle.svg") as string;

  const clickEye = () => {
    setIsHidden((state) => {
      return !state;
    });
  };

  return (
    <>
      <div className={`container-input ${showError ? "margin-bottom-12" : ""}`}>
        <InputMask
          type={isHidden ? props.type : "text"}
          mask="999999"
          // @ts-ignore:next-line
          maskChar={""}
          className={`input-fields ${props.isError ? "error" : ""}`}
          onChange={props.onChange}
          value={props.value}
          inputMode={inputMode}
        />

        <label className={`label-input ${props.isFilled ? "filled" : ""}`}>
          {props.labelText}
        </label>
        {showError && (
          <div className="d-inline-flex align-items-center errorDiv">
            {props.isError && (
              <img src={alertCircle} alt="" className="alert-cicle" />
            )}
            <span className="errorLabel">{props.errorMsg}</span>
          </div>
        )}
        {props.type === "password" && isHidden && (
          <div className="input-icon-eye">
            <FontAwesomeIcon icon={faEye} onClick={clickEye} />
          </div>
        )}
        {props.type === "password" && !isHidden && (
          <div className="input-icon-eye">
            <FontAwesomeIcon icon={faEyeSlash} onClick={clickEye} />
          </div>
        )}
        {props.showCheckIcon && (
          <img src={check} alt="" className="input-check" />
        )}
      </div>
    </>
  );
}
