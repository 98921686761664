import React, { useEffect, useState, ChangeEvent } from "react";
import { useForm, SubmitHandler, UseFormRegister } from "react-hook-form";

import Header from "../../../components/site-credentials/header/Header";
import SiteCredentialsInput from "../../../components/site-credentials/input/input";
import Footer from "../../../components/site-credentials/footer/Footer";
import CheckBullet from "../../../components/site-credentials/bullets/CheckBullet";

import { SetPassword } from "../../../models/site-credentials/mobile/SetPassword";
import { FormError } from "../../../models/site-credentials/common/FormError";

import { setPasswordService } from "../../../services/site-credentials/mobile/SetPasswordService";

import {
  verifySpecialChars,
  verifyLowerCase,
  verifyMinChar,
  verifyNumber,
  verifyUpperCase,
} from "../../../utils/passwordRules";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ErrorMessage from "../../../components/continente-credentials/error-message/error-message";

type Form = SetPassword & FormError;

export default function UpdatePasswordDefinition() {
  const history = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [labelPasswordVisible, setLabelPasswordVisible] =
    useState<boolean>(false);
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const [minCharacters, setMinCharacters] = useState<boolean>(false);
  const [containNumber, setContainNumber] = useState<boolean>(false);
  const [containUppercase, setContainUppercase] = useState<boolean>(false);
  const [containLowercase, setContainLowercase] = useState<boolean>(false);
  const [containSpecialChar, setContainSpecialChar] = useState<boolean>(false);
  const [passwordsMatch, setPasswordsMatch] = useState<boolean>(false);
  const [isButtonEnabled, setButtonEnabled] = useState<boolean>(false);
  const goToFaq = (anchor?: string) => {
    if (anchor) anchor = "#" + anchor;
    history.push("/site-credentials/faq" + anchor);
  };
  const ANCHOR_NEED_HELP = "";
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<Form>({ mode: "onSubmit", reValidateMode: "onSubmit" });

  const handlePasswordChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    clearErrors();
    setPassword(value);
    setValue("password", value);
    checkAndEnableCheckBox(value, passwordConfirmation);
    setLabelPasswordVisible(value.length > 0 ? true : false);
  };

  const [
    labelPasswordConfirmationVisible,
    setLabelPasswordConfirmationVisible,
  ] = useState<boolean>(false);

  const handlePasswordConfirmationChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    clearErrors();
    setPasswordConfirmation(value);
    setValue("passwordConfirmation", value);
    checkAndEnableCheckBox(password, value);
    setLabelPasswordConfirmationVisible(value.length > 0 ? true : false);
  };

  const onSubmit: SubmitHandler<SetPassword> = async (data) => {
    setLoading(true);
    if (password === passwordConfirmation) {
      try {
        const response = await setPasswordService.setPassword(
          {
            password: data.password,
            passwordConfirmation: data.passwordConfirmation,
          },
          "api/site-credentials/password/update/definition"
        );

        switch (response.kind) {
          case "success":
            history.push(response.value.nextStep.substring(4), {
              ...response.value,
              responseCode: "100001",
            });
            break;
          case "error":
            setError("submitError", {
              message:
                response.code === "300010"
                  ? t(
                      "site_credentials.update_password.definition.password_repeated"
                    )
                  : t(response.message),
            });
            break;
          default:
            setError("submitError", { message: t("common.unexpected_error") });

            break;
        }

        setLoading(false);
      } catch (e) {
        setError("submitError", { message: t("common.unexpected_error") });
        setLoading(false);
      }
    } else {
      setError("password", {
        message: t("password_definition.passwords_not_match"),
      });
      setError("passwordConfirmation", {
        message: t("password_definition.passwords_not_match"),
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!verifyMinChar(password)) setMinCharacters(false);
    else setMinCharacters(true);

    if (verifyNumber(password)) setContainNumber(true);
    else setContainNumber(false);

    if (verifyUpperCase(password)) setContainUppercase(true);
    else setContainUppercase(false);

    if (verifyLowerCase(password)) setContainLowercase(true);
    else setContainLowercase(false);

    if (verifySpecialChars(password)) setContainSpecialChar(true);
    else setContainSpecialChar(false);
  }, [password]);

  function verifyPasswordsMatch(pw: string, pwConfirmation: string) {
    if (pw === pwConfirmation && pw !== "") {
      setPasswordsMatch(true);
      return true;
    }

    setPasswordsMatch(false);
    return false;
  }

  function checkAndEnableCheckBox(
    pw: string,
    pwConfirmation: string,
    isChecked: boolean | null = null
  ) {
    if (
      verifyPasswordsMatch(pw, pwConfirmation) &&
      verifyMinChar(pw) &&
      verifyNumber(pw) &&
      verifyLowerCase(pw) &&
      verifyUpperCase(pw) &&
      verifySpecialChars(pw)
    ) {
      setButtonEnabled(true);
    } else {
      setButtonEnabled(false);
    }
  }

  useEffect(() => {
    const passwordValidator = (register: UseFormRegister<Form>) =>
      register("password", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
        pattern: {
          value: /^((?=.*\d)(?=.*[A-Za-z])).{6,}$/,
          message: t("password_definition.password_invalid"),
        },
      });
    const passwordConfirmationValidator = (register: UseFormRegister<Form>) =>
      register("passwordConfirmation", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
        pattern: {
          value: /^((?=.*\d)(?=.*[A-Za-z])).{6,}$/,
          message: t("password_definition.password_invalid"),
        },
      });
    passwordValidator(register);
    passwordConfirmationValidator(register);
  }, [register, t]);

  return (
    <>
      <form
        id="SetUserPassword"
        autoComplete="on"
        onSubmit={(e) => {
          clearErrors();
          handleSubmit(onSubmit)(e);
        }}
      >
        <div className="row">
          <Header
            title={t("site_credentials.define_password")}
            showLoading={loading}
            isTextAlignCenter={false}
            previousStepBackXTimes={2}
          />
        </div>

        <div className="row d-flex  ">
          <div className="input-outline-eye">
            <SiteCredentialsInput
              labelText={t("site_credentials.password_input")}
              isFilled={labelPasswordVisible}
              isError={errors.password ? true : false}
              errorMsg={errors.password ? errors.password.message : ""}
              onChange={handlePasswordChange}
              type="password"
            />
            <SiteCredentialsInput
              labelText={t("site_credentials.confirm_password")}
              isFilled={labelPasswordConfirmationVisible}
              isError={errors.passwordConfirmation ? true : false}
              errorMsg={
                errors.passwordConfirmation
                  ? errors.passwordConfirmation.message
                  : ""
              }
              onChange={handlePasswordConfirmationChange}
              type="password"
            />
          </div>
          <div className="form-check-group-copy">
            <div className="form-check-group mt-4">
              <div className="d-flex w-75 m-auto flex-wrap flex-column">
                <CheckBullet
                  checked={minCharacters}
                  text={t(
                    "site_credentials.password_validation_length_maximum"
                  )}
                />
                <CheckBullet
                  checked={containNumber}
                  text={t("site_credentials.password_validation_alphanumeric")}
                />
                <CheckBullet
                  checked={containUppercase}
                  text={t("site_credentials.password_validation_uppercase")}
                />
                <CheckBullet
                  checked={containLowercase}
                  text={t("password_definition.password_validation_lowercase")}
                />
                <CheckBullet
                  checked={containSpecialChar}
                  text={t(
                    "password_definition.password_validation_special_char"
                  )}
                />
                <CheckBullet
                  checked={passwordsMatch}
                  text={t("password_definition.passwords_match")}
                />
              </div>
            </div>
          </div>
        </div>
        <ErrorMessage alignCenter={false} error={errors.submitError} />
        <div className="row d-flex flex-column">
          <Footer isDisabled={!isButtonEnabled} />
        </div>
        <div className="sc-contextual-information-header text-align-center">
          <span
            className="verification-resend-code link"
            onClick={() => goToFaq(ANCHOR_NEED_HELP)}
          >
            {t("common.need_help")}
          </span>
        </div>
      </form>
    </>
  );
}
