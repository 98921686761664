import React from "react";
import CredentialsHeader from "../../components/continente-credentials/header/Header";
import { useTranslation } from "react-i18next";

export default function Help() {
  const { t } = useTranslation();

  return (
    <>
      <>
        <form id="ContactCenter" autoComplete="off">
          <div className="row justify-content-center">
            <CredentialsHeader
              title={t("help.header_title")}
              subtitle=""
              isTextAlignCenter
            />
          </div>
        </form>
      </>
    </>
  );
}
