import React, { useState } from "react";
import CredentialsDigitInput from "../digit-input/DigitInput";

type IdentifierInputProps = {
  requestedValues: number[];
  onChange: (identification: string[]) => void;
};

export default function IdentifierInput(props: IdentifierInputProps) {
  const length = 3;
  const [identification, setIdentification] = useState<string[]>(
    Array.from({ length: length }, (_) => "")
  );

  const handleBackspace = (position: number) => {
    const actualInput = document.getElementById(
      `digit${position}`
    ) as HTMLInputElement;

    if (!actualInput?.value) {
      const prevInput = document.getElementById(`digit${position - 1}`);

      if (prevInput != null) {
        prevInput.focus();
      }

      const newIdentification = [...identification];
      newIdentification[position - 1] = "";

      setIdentification(newIdentification);

      props.onChange(newIdentification);
    } else {
      actualInput.focus();
      const newIdentification = [...identification];
      newIdentification[position] = "";

      setIdentification(newIdentification);

      props.onChange(newIdentification);
    }
  };

  const changeInput = (event: any, position: number) => {
    const value = event.target.value;
    if (!isNaN(+value)) {
      //true if its a number
      const nextInput = document.getElementById(`digit${position + 1}`);

      if (nextInput != null) {
        nextInput.focus();
      }
      const newIdentification = [...identification];
      newIdentification[position] = value;

      setIdentification(newIdentification);

      props.onChange(newIdentification);
    }
  };

  return (
    <>
      <div className="identifier-input">
        {identification.map((d, i) => (
          <div key={`div-digit-${i}`} className="identifier-digit">
            <div className="digit">{props.requestedValues[i]}.º</div>
            <CredentialsDigitInput
              id={`digit${i}`}
              key={i}
              value={d}
              onChange={(e) => changeInput(e, i)}
              onBackspace={() => handleBackspace(i)}
            />
          </div>
        ))}
      </div>
    </>
  );
}
