import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { FormError } from "../../../models/continente-credentials/common/FormError";
import { useHistory } from "react-router-dom";
import CredentialsFooter from "../../../components/continente-credentials/footer/Footer";
import { LoyaltyCardAssociationResponse } from "../../../models/continente-credentials/loyaltycard/LoyaltyCardAssociationResponse";
import CredentialsHeader from "../../../components/continente-credentials/header/Header";
import { cardAssociationService } from "../../../services/continente-credentials/mobile/CardAssociationService";
import { useTranslation } from "react-i18next";
import OptionsGroup from "../../../components/continente-credentials/option-group/OptionsGroup";
import { faPhoneAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import ErrorMessage from "../../../components/continente-credentials/error-message/error-message";

type Form = FormError;
export default function LoyaltyCardOtpOptions() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const location = useLocation<LoyaltyCardAssociationResponse>();
  const goToFaq = (anchor?: string) => {
    if (anchor) anchor = "#" + anchor;
    history.push("/continente-credentials/faq" + anchor);
  };
  const ANCHOR_NEED_HELP = "";

  const [error, setError] = useState("");

  const handleOudatedLinkOnClick = async () => {
    setLoading(true);

    const resp = await cardAssociationService.outdatedData(
      "/api/loyalty/cards/outdated-data"
    );

    switch (resp.kind) {
      case "success":
        history.push(resp.value.nextStep.substring(4), resp.value);

        break;
      default:
        // todo add generic error
        break;
    }

    setLoading(false);
  };

  const handleOnSelect = async (option: string) => {
    setLoading(true);

    const resp = await cardAssociationService.sendOtp(
      option,
      location.state != null ? location.state.nextStep : ""
    );

    switch (resp.kind) {
      case "success":
        history.push(resp.value.nextStep.substring(4), resp.value);

        break;
      case "error":
        switch (resp.code) {
          case "100015":
            setError(t("common.unexpected_error"));
            break;
          default:
            setError(t("common.unexpected_error"));
            break;
        }
        break;
      default:
        setError(t("common.unexpected_error"));
        break;
    }

    setLoading(false);
  };

  return (
    <>
      <div className="row justify-content-center">
        <CredentialsHeader
          title={t("card_association_otp_options.header_title")}
          subtitle={t("card_association_otp_options.header_message")}
          showLoading={loading}
          previousStepBackXTimes={1}
        />
      </div>

      <OptionsGroup
        options={location.state.properties.loyaltyCardOtpOptions.map((o) => ({
          icon: o.type === "MobilePhone" ? faPhoneAlt : faEnvelope,
          text: o.contact,
          value: o.type.toString(),
        }))}
        onSelect={handleOnSelect}
      />
      <ErrorMessage errorString={error} />
      <span onClick={handleOudatedLinkOnClick} className="info-link">
        {t("card_association_otp_options.information_outdated")}
      </span>
      <div className="contextual-information-header text-align-center">
        <span
          className="verification-resend-code link"
          onClick={() => goToFaq(ANCHOR_NEED_HELP)}
        >
          {t("common.need_help")}
        </span>
      </div>
      <div className="row d-flex flex-column">
        <CredentialsFooter
          contextualInformationVisible={false}
          footerAppsWebsitesIconsVisible={false}
        />
      </div>
    </>
  );
}
