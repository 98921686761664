import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

type FaqInformationProps = {
  faq: {
    question: string;
    answer: string;
    anchor: string;
  };
  anchorSelected: string | null;
  onClick: (newAnchor: string) => void;
};

function FaqInformation(props: FaqInformationProps) {
  const open = props.anchorSelected === props.faq.anchor;
  return (
    <div
      id={props.faq.anchor}
      className={`faq-information ${open ? "open" : ""}`}
    >
      <div
        className="question c-pointer"
        onClick={() => props.onClick(props.faq.anchor)}
      >
        <h2 className="text">{props.faq.question}</h2>
        <FontAwesomeIcon
          className="faq-chevron-icon"
          icon={open ? faMinus : faPlus}
        />
      </div>
      <div className="answer">
        <div
          className="text"
          dangerouslySetInnerHTML={{ __html: props.faq.answer }}
        />
      </div>
    </div>
  );
}

export default FaqInformation;
