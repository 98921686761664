import React, { useLayoutEffect, useRef, ReactNode } from "react";

type OutsideClickHandlerProps = {
  outsideclick: () => void;
  children: ReactNode;
  className?: string;
};

function OutsideClickHandler(props: OutsideClickHandlerProps) {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: any) => {
    if (wrapperRef?.current && !wrapperRef?.current.contains(event.target)) {
      props.outsideclick();
    }
  };

  useLayoutEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return <div ref={wrapperRef}>{props.children}</div>;
}

export default OutsideClickHandler;
