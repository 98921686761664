import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  IconDefinition,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";

export type OptionProps = {
  icon?: IconDefinition;
  image?: any;
  text: string;
  value: string;
  onClick: () => void;
};

export default function Option(props: OptionProps) {
  return (
    <div className="option" onClick={props.onClick}>
      <div className="icon">
        {props.icon && <FontAwesomeIcon icon={props.icon} />}
        {props.image && <props.image />}
      </div>
      <div className="text">{props.text}</div>
      <FontAwesomeIcon className="arrow" icon={faArrowRight} />
    </div>
  );
}
