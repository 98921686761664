import React, { useState } from "react";
import { Route } from "react-router";
import Layout from "./components/continente-credentials/layout/Layout";

import Home from "./pages/site-credentials/Home";
import Authorization from "./pages/site-credentials/Authorization";

import PasswordDefinition from "./pages/site-credentials/PasswordDefinition";
import Faq from "./pages/site-credentials/Faq";
import TermsAndConditions from "./pages/site-credentials/TermsAndConditions";

import SiteCredentialsTimeOutError from "./pages/site-credentials/Error";

import EmailValidatePassword from "./pages/site-credentials/EmailAssociation/ValidatePassword";
import EmailOtpVerify from "./pages/site-credentials/EmailAssociation/OtpVerify";
import EmailDefinition from "./pages/site-credentials/EmailAssociation/EmailDefinition";
import SCAccountAssociationOptions from "./pages/site-credentials/EmailAssociation/AccountAssociationOptions";

import PhoneNumberValidatePassword from "./pages/site-credentials/PhoneNumberAssociation/ValidatePassword";
import PhoneNumberDuplicatedInformation from "./pages/site-credentials/PhoneNumberAssociation/DuplicatedInformation";
import PhoneNumberMfaValidation from "./pages/site-credentials/PhoneNumberAssociation/MfaValidation";
import PhoneNumberOtpVerify from "./pages/site-credentials/PhoneNumberAssociation/OtpVerify";
import PhoneNumberDefinition from "./pages/site-credentials/PhoneNumberAssociation/PhoneNumberDefinition";

import AboutAssociateLoyaltyCard from "./pages/site-credentials/LoyaltyCardAssociation/AboutAssociateLoyaltyCard";
import SCLoyaltyCardAssociation from "./pages/site-credentials/LoyaltyCardAssociation/LoyaltyCardAssociation";
import SCIdentificationVerification from "./pages/site-credentials/LoyaltyCardAssociation/IdentificationVerification";
import SCLoyaltyCardAssociationSuccess from "./pages/site-credentials/LoyaltyCardAssociation/LoyaltyCardAssociationSuccess";
import SCLoyaltyCardContactCenter from "./pages/site-credentials/LoyaltyCardAssociation/LoyaltyCardContactCenter";
import SCLoyaltyCardCreation from "./pages/site-credentials/LoyaltyCardAssociation/LoyaltyCardCreation";
import SCLoyaltyCardOtpValidation from "./pages/site-credentials/LoyaltyCardAssociation/LoyaltyCardOtpValidation";
import SCLoyaltyCardOtpOptions from "./pages/site-credentials/LoyaltyCardAssociation/LoyaltyCardOtpOptions";
import UpdatePasswordValidate from "./pages/site-credentials/UpdatePassword/ValidatePassword";
import UpdatePasswordDefinition from "./pages/site-credentials/UpdatePassword/PasswordDefinition";
import SiteCredentialsPasswordRecoverValidateOtp from "./pages/site-credentials/password-recover/VerifyOtp";
import SiteCredentialsPasswordRecoverOtpEmail from "./pages/site-credentials/password-recover/VerifyOtpEmail";
import SiteCredentialsPasswordRecoverSetPassword from "./pages/site-credentials/password-recover/SetPassword";
import SiteCredentialsPasswordRecoverLoyaltyCardValidation from "./pages/site-credentials/password-recover/LoyaltyCardValidation";
import SiteCredentialsPasswordRecoverContactCenter from "./pages/site-credentials/password-recover/ContactCenter";
import SiteCredentialsPasswordRecoverBlocked from "./pages/site-credentials/password-recover/Blocked";

import {
  DigitalAssetContext,
  defaultDigitalAsset,
} from "./contexts/site-credentials/DigitalAssetContext";

import { DigitalAsset } from "./models/site-credentials/common/DigitalAsset";

// TODO: move to SessionMiddleware
const { fetch: originalFetch } = window;

const cookies: Record<string, string> = {};

async function fetch(
  resource: RequestInfo,
  init: RequestInit = {}
): Promise<Response> {
  const cookieNames = Object.keys(cookies);

  if (cookieNames.length > 0) {
    const cookieHeader = cookieNames
      .map((c) => `${c}=${cookies[c]}`)
      .join("; ");

    init.headers = {
      ...init.headers,
      "X-Cookie": cookieHeader,
    };
  }

  const response = await originalFetch(resource, init);

  const setCookieHeader = response.headers.get("X-Set-Cookie");

  if (setCookieHeader) {
    const parts = setCookieHeader.split("=");

    if (parts.length >= 2) {
      cookies[parts[0]] = parts[1].split(";")[0];
    }
  }

  return response;
}

window.fetch = fetch;

export default function SiteCredenciaisApp() {
  const [digitalAsset, setDigitalAsset] =
    useState<DigitalAsset>(defaultDigitalAsset);

  const updateDigitalAsset = (newDigitalAsset: DigitalAsset) => {
    setDigitalAsset(newDigitalAsset);
  };

  return (
    <DigitalAssetContext.Provider
      value={{
        digitalAsset: digitalAsset,
        updateDigitalAsset: updateDigitalAsset,
      }}
    >
      <Layout className="sc-cc_max_width">
        <Route exact path="/site-credentials" component={Home} />
        <Route path="/site-credentials/authorize" component={Authorization} />
        <Route
          path="/site-credentials/email/definition"
          component={EmailDefinition}
        />
        <Route
          path="/site-credentials/email/validate-password" // new
          component={EmailValidatePassword}
        />
        <Route
          path="/site-credentials/email/otp-validation" // new
          component={EmailOtpVerify}
        />
        <Route
          path="/site-credentials/email/loyalty-card-options" // new
          component={SCAccountAssociationOptions}
        />
        <Route
          path="/site-credentials/phonenumber/definition"
          component={PhoneNumberDefinition}
        />
        <Route
          path="/site-credentials/phonenumber/validate-password" // new
          component={PhoneNumberValidatePassword}
        />
        <Route
          path="/site-credentials/phonenumber/otp-validation" // new
          component={PhoneNumberOtpVerify}
        />
        <Route
          path="/site-credentials/phonenumber/duplicated-information" // new
          component={PhoneNumberDuplicatedInformation}
        />
        <Route
          path="/site-credentials/phonenumber/mfa-validation" // new
          component={PhoneNumberMfaValidation}
        />
        <Route
          path="/site-credentials/loyaltycard/about" // new
          component={AboutAssociateLoyaltyCard}
        />
        <Route
          path="/site-credentials/card/association" // new
          component={SCLoyaltyCardAssociation}
        />
        <Route
          path="/site-credentials/password/update/validate" // new
          component={UpdatePasswordValidate}
        />
        <Route
          path="/site-credentials/password/update/definition" // new
          component={UpdatePasswordDefinition}
        />
        <Route
          path="/site-credentials/card/identifier-verification"
          component={SCIdentificationVerification}
        />
        <Route
          path="/site-credentials/card/loyalty-card-association-success"
          component={SCLoyaltyCardAssociationSuccess}
        />
        <Route
          path="/site-credentials/loyalty/cards/otp-options"
          component={SCLoyaltyCardOtpOptions}
        />
        <Route
          path="/site-credentials/loyalty/cards/verify-otp"
          component={SCLoyaltyCardOtpValidation}
        />
        <Route
          path="/site-credentials/card/continente-card-creation"
          component={SCLoyaltyCardCreation}
        />
        <Route
          path="/site-credentials/card/call-center-loyalty-card"
          component={SCLoyaltyCardContactCenter}
        />
        <Route
          path="/site-credentials/card/loyalty-card-association-success"
          component={SCLoyaltyCardAssociationSuccess}
        />
        <Route
          path="/site-credentials/password/definition/password-definition"
          component={PasswordDefinition}
        />
        <Route path="/site-credentials/faq" component={Faq} />
        <Route
          path="/site-credentials/termsandconditions"
          component={TermsAndConditions}
        />
        <Route
          path="/site-credentials/error"
          component={SiteCredentialsTimeOutError}
        />

        <Route
          path="/site-credentials/password/recover/validate-otp"
          component={SiteCredentialsPasswordRecoverValidateOtp}
        />
        <Route
          path="/site-credentials/password/recover/validate-otp-email"
          component={SiteCredentialsPasswordRecoverOtpEmail}
        />
        <Route
          path="/site-credentials/password/recover/set-password"
          component={SiteCredentialsPasswordRecoverSetPassword}
        />
        <Route
          path="/site-credentials/password/recover/loyalty-card-validation"
          component={SiteCredentialsPasswordRecoverLoyaltyCardValidation}
        />
        <Route
          path="/site-credentials/password/recover/contact-center"
          component={SiteCredentialsPasswordRecoverContactCenter}
        />
        <Route
          path="/site-credentials/password/recover/blocked"
          component={SiteCredentialsPasswordRecoverBlocked}
        />
      </Layout>
    </DigitalAssetContext.Provider>
  );
}
