import React from "react";

import { Lang } from "../../models/site-credentials/common/Lang";

export const defaultLang: Lang = {
  code: "",
};

export const LangContext = React.createContext({
  lang: defaultLang,
  updateLang: (lang: Lang) => {},
});