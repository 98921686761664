import { OtpValidation } from "../../../models/continente-credentials/mobile/OtpValidation";
import { EmptyResponse } from "../../../models/continente-credentials/mobile/EmptyResponse";
import { Response } from "../../../models/continente-credentials/common/Response";

export interface IOtpValidationService {
  otpValidation(
    otpValidation: OtpValidation,
    otpValidationUrl: string
  ): Promise<Response<EmptyResponse>>;
}

export class OtpValidationService implements IOtpValidationService {
  async otpValidation(
    otpValidation: OtpValidation,
    otpValidationUrl: string
  ): Promise<Response<EmptyResponse>> {
    const resp = await fetch(otpValidationUrl, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(otpValidation),
    });

    if (resp.ok) {
      return { kind: "success", value: await resp.json() };
    } else if (resp.status === 400) {
      const error = await resp.json();

      const returnedError = error.find((c: any) => c.Code != null);
      return {
        kind: "error",
        code: returnedError.Code ? returnedError.Code : 0,
        message: returnedError.message ? returnedError.message : "",
      };
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }
}

export const otpValidationService = new OtpValidationService();
