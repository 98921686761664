import { PhoneNumberDefinition } from "../../../models/site-credentials/mobile/PhoneNumberDefinition";
import { PhoneNumberDefinitionResponse } from "../../../models/site-credentials/mobile/PhoneNumberDefinitionResponse";
import { Response } from "../../../models/site-credentials/common/Response";

export interface IPhoneNumberDefinitionService {
  PhoneNumberDefinition(
    PhoneNumberDefinition: PhoneNumberDefinition,
    phoneNumberDefinitionUrl: string
  ): Promise<Response<PhoneNumberDefinitionResponse>>;
}

export class PhoneNumberDefinitionService
  implements IPhoneNumberDefinitionService
{
  async PhoneNumberDefinition(
    PhoneNumberDefinition: PhoneNumberDefinition,
    phoneNumberDefinitionUrl: string
  ): Promise<Response<PhoneNumberDefinitionResponse>> {
    const resp = await fetch(phoneNumberDefinitionUrl, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(PhoneNumberDefinition),
    });
    if (resp.ok) {
      return { kind: "success", value: await resp.json() };
    } else if (resp.status === 400) {
      const error = await resp.json();

      const returnedError = error[0];

      return {
        kind: "error",
        code: returnedError.Code,
        message: returnedError.message,
      };
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }
}

export const phoneNumberDefinitionService = new PhoneNumberDefinitionService();
