import { AccountAssociation } from "../../../models/continente-credentials/mobile/AccountAssociation";
import { AccountAssociationResponse } from "../../../models/continente-credentials/mobile/AccountAssociationResponse";
import { Response } from "../../../models/continente-credentials/common/Response";

export interface IAccountAssociationService {
  accountAssociation(
    accountAssociation: AccountAssociation,
    accountAssociationUrl: string
  ): Promise<Response<AccountAssociationResponse>>;
}

export class AccountAssociationService implements IAccountAssociationService {
  async accountAssociation(
    accountAssociation: AccountAssociation,
    accountAssociationUrl: string
  ): Promise<Response<AccountAssociationResponse>> {
    const resp = await fetch(accountAssociationUrl, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(accountAssociation),
    });
    if (resp.ok) {
      return { kind: "success", value: await resp.json() };
    } else if (resp.status === 400) {
      const error = await resp.json();

      const returnedError = error.find((c: any) => c.Code != null);
      return {
        kind: "error",
        code: returnedError.Code ? returnedError.Code : 0,
        message: returnedError.message ? returnedError.message : "",
      };
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }
}

export const accountAssociationService = new AccountAssociationService();
