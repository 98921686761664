import { useTranslation } from "react-i18next";
import React, { useEffect, useState, ChangeEvent, useContext } from "react";
import { useForm, SubmitHandler, UseFormRegister } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";

import CardInput from "../../../components/site-credentials/card-input/CardInput";
import BottomSheet from "../../../components/site-credentials/bottom-sheet/BottomSheet";
import ErrorMessage from "../../../components/continente-credentials/error-message/error-message";
import CredentialsFooter from "../../../components/site-credentials/footer/Footer";
import Header from "../../../components/site-credentials/header/Header";
import WhereToFindCardNumber from "../../../components/site-credentials/where-to-find-card-number/WhereToFindCardNumber";
import { FormError } from "../../../models/site-credentials/common/FormError";
import { CardAssociation } from "../../../models/site-credentials/mobile/CardAssociation";
import { DigitalAssetContext } from "../../../contexts/site-credentials/DigitalAssetContext";
import { cardValidationService } from "../../../services/site-credentials/mobile/CardValidationService";
import { PasswordRecoverValidateIdentifierResponse } from "../../../models/site-credentials/mobile/PasswordRecoverValidateIdentifierResponse";

import loyaltyCard from "../../../assets/images/cartoes.png";

type Form = CardAssociation & FormError;

export default function LoyaltyCardValidation() {
  const [loading, setLoading] = useState(false);
  const [isBottomSheetOpen, setBottomSheetOpen] = useState(false);
  const { t } = useTranslation();
  const location = useLocation<PasswordRecoverValidateIdentifierResponse>();
  const history = useHistory();
  const digitalAssetContext = useContext(DigitalAssetContext);
  const [labelCardVisible, setLabelCardVisible] = useState<boolean>(false);
  const [cardValue, setCardValue] = useState<string>();
  const identifier =
    location.state != null ? location.state.properties.identifier : "";

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm<Form>({ mode: "onSubmit", reValidateMode: "onSubmit" });
  const closeBottomSheet = (isClosed: boolean) => {
    setBottomSheetOpen(!isClosed);
  };

  const handleWhereToFindCardNumberClick = () => {
    if (digitalAssetContext.digitalAsset.isMobile) {
      setBottomSheetOpen(true);
    } else {
      window.open("/continentecredentialsfaq", "_blank"); //TODO: rever o link
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (
      e.key !== "Enter" &&
      (!e.key.match(/[0-9\s]/) ||
        (e.target as HTMLInputElement).value.length > 12)
    ) {
      e.preventDefault();
    }
  };

  const handleCardChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    clearErrors();

    let cardNumber = value.replace(/\s/g, "");

    setValue("cardNumber", cardNumber);
    setLabelCardVisible(cardNumber.length > 0 ? true : false);
    setCardValue(cardNumber);
  };

  const onSubmit: SubmitHandler<CardAssociation> = async (data) => {
    setLoading(true);

    try {
      const response = await cardValidationService.cardValidation(
        {
          identifier: identifier,
          cardNumber: data.cardNumber,
        },
        location.state != null ? location.state.nextStep : ""
      );
      switch (response.kind) {
        case "success": {
          history.push(response.value.nextStep.substring(4), response.value);
          break;
        }
        case "error":
          if (response.code === "100013") {
            setError("cardNumber", {
              message: t("site_credentials.card_association.invalid_code"),
            });
            break;
          } else if (response.code === "100003" || response.code === "100020") {
            setError("cardNumber", {
              message: t(
                "site_credentials.card_association.invalid_loyaltycard"
              ),
            });
            break;
          }
          setError("submitError", { message: t("common.unexpected_error") });
          break;

        default:
          setError("submitError", { message: t("common.unexpected_error") });
          break;
      }

      setLoading(false);
    } catch (e) {
      setError("submitError", { message: t("common.unexpected_error") });
      setLoading(false);
    }
  };

  useEffect(() => {
    const CardValidator = (register: UseFormRegister<Form>) =>
      register("cardNumber", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
        pattern: {
          value: /^[0-9]{13}$/,
          message: t("card_association.invalid_loyaltycard"),
        },
      });
    CardValidator(register);
  }, [register, t]);

  return (
    <>
      <form
        id="CardAssociation"
        autoComplete="on"
        onSubmit={(e) => {
          clearErrors();
          handleSubmit(onSubmit)(e);
        }}
      >
        <div className="row justify-content-center">
          <Header
            title={t("site_credentials.card_validation.header_title")}
            subtitle={t("site_credentials.card_validation.header_message")}
            showLoading={loading}
            previousStepBackXTimes={3}
          />
        </div>
        <div className="row justify-content-center">
          <img src={loyaltyCard} className="continente-card" alt="" />
        </div>
        <div className="row d-flex justify-content-center">
          <div className="input-outline-eye">
            <CardInput
              labelText={t(
                "site_credentials.card_validation.card_number_input"
              )}
              isFilled={labelCardVisible}
              isError={errors.cardNumber ? true : false}
              errorMsg={errors.cardNumber ? errors.cardNumber.message : ""}
              onChange={handleCardChange}
              onKeyPress={handleKeyPress}
              value={cardValue}
            />
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          <span
            onClick={handleWhereToFindCardNumberClick}
            className="what-is-link"
          >
            {" "}
            {t("site_credentials.card_validation.where_to_find_message")}{" "}
          </span>
        </div>
        <ErrorMessage error={errors.submitError} />
        <div className="d-flex flex-column">
          <CredentialsFooter />
        </div>
      </form>
      {isBottomSheetOpen && (
        <BottomSheet
          content={<WhereToFindCardNumber />}
          closeBottomSheet={closeBottomSheet}
        />
      )}
    </>
  );
}
