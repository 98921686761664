import { useLocation } from "react-router";

import { AuthorizeQueryParams } from "../../models/site-credentials/common/AuthorizeQueryParams";

export const useAuthorizeQueryParams = (): AuthorizeQueryParams => {
  const urlSearchParams = new URLSearchParams(useLocation().search);

  return {
    clientId: urlSearchParams.get("clientId"),
    token: urlSearchParams.get("token"),
  };
};
