import React, { useEffect, useState, ChangeEvent, useContext } from "react";
import { useForm, SubmitHandler, UseFormRegister } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import Footer from "../../../components/site-credentials/footer/Footer";
import Header from "../../../components/site-credentials/header/Header";
import CardInput from "../../../components/site-credentials/card-input/CardInput";
import { UserSignInResponse } from "../../../models/site-credentials/mobile/UserSignInResponse";
import { FormError } from "../../../models/site-credentials/common/FormError";
import { useTranslation } from "react-i18next";
import BottomSheet from "../../../components/site-credentials/bottom-sheet/BottomSheet";
import WhereToFindCardNumber from "../../../components/site-credentials/where-to-find-card-number/WhereToFindCardNumber";
import { DigitalAssetContext } from "../../../contexts/site-credentials/DigitalAssetContext";
import { CardAssociation } from "../../../models/site-credentials/loyaltycard/CardAssociation";
import { cardAssociationService } from "../../../services/site-credentials/mobile/CardAssociationService";
import loyaltyCard from "../../../assets/images/cartoes.png";
import ErrorMessage from "../../../components/continente-credentials/error-message/error-message";

type Form = CardAssociation & FormError;

export default function LoyaltyCardAssociation() {
  const [loading, setLoading] = useState(false);
  const [isBottomSheetOpen, setBottomSheetOpen] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const digitalAssetContext = useContext(DigitalAssetContext);
  const [labelCardVisible, setLabelCardVisible] = useState<boolean>(false);
  const [cardValue, setCardValue] = useState<string>();
  const location = useLocation<UserSignInResponse>();

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm<Form>({ mode: "onSubmit", reValidateMode: "onSubmit" });
  const closeBottomSheet = (isClosed: boolean) => {
    setBottomSheetOpen(!isClosed);
  };

  const handleWhereToFindCardNumberClick = () => {
    if (digitalAssetContext.digitalAsset.isMobile) {
      setBottomSheetOpen(true);
    } else {
      window.open("/site-credentials/faq", "_blank"); //TODO: rever o link
    }
  };

  const SignUpClick = async () => {
    try {
      const response = await cardAssociationService.cardAssociation(
        {
          cardNumber: "0",
          addCC: true,
          skipAssociation: false,
        },
        location.state != null ? location.state.nextStep : ""
      );

      switch (response.kind) {
        case "success": {
          history.push(response.value.nextStep.substring(4), response.value);
          break;
        }
        default:
          setError("submitError", { message: t("common.unexpected_error") });
          break;
      }

      setLoading(false);
    } catch (e) {
      setError("submitError", { message: t("common.unexpected_error") });
      setLoading(false);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (
      e.key !== "Enter" &&
      (!e.key.match(/[0-9\s]/) ||
        (e.target as HTMLInputElement).value.length > 12)
    ) {
      e.preventDefault();
    }
  };

  const handleCardChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    clearErrors();

    let cardNumber = value.replace(/\s/g, "");

    setValue("cardNumber", cardNumber);
    setLabelCardVisible(cardNumber.length > 0 ? true : false);
    setCardValue(cardNumber);
  };

  const onSubmit: SubmitHandler<CardAssociation> = async (data) => {
    setLoading(true);

    try {
      const response = await cardAssociationService.cardAssociation(
        {
          cardNumber: data.cardNumber,
          addCC: false,
          skipAssociation: false,
        },
        location.state != null ? location.state.nextStep : ""
      );

      switch (response.kind) {
        case "success": {
          history.push(response.value.nextStep.substring(4), {
            ...response.value,
            responseCode: "100003",
          });
          break;
        }
        case "error":
          switch (response.code) {
            case "100013":
              setError("cardNumber", {
                message: t("card_association.invalid_code"),
              });
              break;
            case "100003":
            case "100005":
            case "100006":
              setError("cardNumber", {
                message: t("card_association.invalid_loyaltycard"),
              });
              break;
            default:
              setError("submitError", {
                message: t("common.unexpected_error"),
              });
              break;
          }
          break;
        default:
          setError("submitError", { message: t("common.unexpected_error") });
          break;
      }

      setLoading(false);
    } catch (e) {
      setError("submitError", { message: t("common.unexpected_error") });
      setLoading(false);
    }
  };

  useEffect(() => {
    const CardValidator = (register: UseFormRegister<Form>) =>
      register("cardNumber", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
        pattern: {
          value: /^[0-9]{13}$/,
          message: t("card_association.invalid_loyaltycard"),
        },
      });
    CardValidator(register);
  }, [register, t]);

  return (
    <>
      <form
        id="CardAssociation"
        autoComplete="on"
        onSubmit={(e) => {
          clearErrors();
          handleSubmit(onSubmit)(e);
        }}
      >
        <div className="row justify-content-center">
          <Header
            title={t(
              "site_credentials.loyaltycard_association.definition.header_title"
            )}
            subtitle={t(
              "site_credentials.loyaltycard_association.definition.header_message"
            )}
            showLoading={loading}
            previousStepBackXTimes={1}
          />
        </div>
        <div className="row justify-content-center">
          <img src={loyaltyCard} className="continente-card" alt="" />
        </div>
        <div className="row d-flex justify-content-center">
          <div className="input-outline-eye">
            <CardInput
              labelText={t(
                "site_credentials.loyaltycard_association.definition.card_number_input"
              )}
              isFilled={labelCardVisible}
              isError={errors.cardNumber ? true : false}
              errorMsg={errors.cardNumber ? errors.cardNumber.message : ""}
              onChange={handleCardChange}
              onKeyPress={handleKeyPress}
              value={cardValue}
            />
          </div>
        </div>
        <ErrorMessage error={errors.submitError} />
        <div className="sign-up">
          {t(
            "site_credentials.loyaltycard_association.definition.card_not_exist_message1"
          )}{" "}
          <span onClick={SignUpClick} className="sc-what-is-link">
            {" "}
            {t(
              "site_credentials.loyaltycard_association.definition.card_not_exist_message2"
            )}{" "}
          </span>
        </div>
        <div className="row d-flex justify-content-center">
          <span
            onClick={handleWhereToFindCardNumberClick}
            className="sc-what-is-link"
          >
            {" "}
            {t(
              "site_credentials.loyaltycard_association.definition.where_to_find_message"
            )}{" "}
          </span>
        </div>
        <div className="row d-flex flex-column">
          <Footer />
        </div>
      </form>
      {isBottomSheetOpen && (
        <BottomSheet
          content={<WhereToFindCardNumber />}
          closeBottomSheet={closeBottomSheet}
        />
      )}
    </>
  );
}
