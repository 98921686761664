import React from "react";
import svg from "../../assets/images/continente-close.svg";

import { consentService } from "../../services/ConsentService";
import { useAuthorizeQueryParams } from "../../hooks/useAuthorizeQueryParams";

export default function TicketBorder() {
  const authorizeQueryParams = useAuthorizeQueryParams();

  const clickDeclinedOrCancelledError = async () => {
    await consentService.declinedOrCancelledError({
      state: authorizeQueryParams.state || "",
      redirect_uri: authorizeQueryParams.redirect_uri || "",
    });
  };

  return (
    <div className="container-fluid no-padding ct-modal-dialog-secondary-grey2">
      <div className="row" style={{ backgroundColor: "transparent" }}>
        <div className="col-md-12">
          <div
            className="svg-icon-close"
            onClick={clickDeclinedOrCancelledError}
          >
            <img src={svg} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
