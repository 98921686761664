import React from "react";
import CredentialsHeader from "../../../components/site-credentials/header/Header";
import { useTranslation } from "react-i18next";

export default function ContactCenter() {
  const { t } = useTranslation();

  return (
    <>
      <form id="ContactCenter" autoComplete="off">
        <div className="row justify-content-center">
          <CredentialsHeader
            title={t("site_credentials.contact_center.header_title")}
            subtitle=""
            isTextAlignCenter
          />
        </div>
      </form>
    </>
  );
}
