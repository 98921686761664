import React, { ChangeEvent } from "react";

import check from "../../../assets/images/check2-min.svg";
import alertCircle from "../../../assets/images/alert-circle.svg";

type InputProps = {
  labelText: string;
  isError?: boolean;
  errorMsg?: string;
  isFilled: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  value?: string;
  disabled?: boolean;
  showCheckIcon?: boolean;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: ChangeEvent<HTMLInputElement>) => void;
  showError?: boolean;
};

export default function CardInput({ showError = true, ...props }: InputProps) {
  return (
    <>
      <div className={`container-input ${showError ? "margin-bottom-12" : ""}`}>
        <input
          type="text"
          inputMode="numeric"
          className={`input-fields ${props.isError ? "error" : ""}`}
          onChange={props.onChange}
          value={props.value}
          onKeyPress={props.onKeyPress}
        />

        <label className={`label-input ${props.isFilled ? "filled" : ""}`}>
          {props.labelText}
        </label>
        {showError && (
          <div className="d-inline-flex align-items-center errorDiv">
            {props.isError && (
              <img src={alertCircle} alt="" className="alert-cicle" />
            )}
            <span className="errorLabel">{props.errorMsg}</span>
          </div>
        )}

        {props.showCheckIcon && (
          <img src={check} alt="" className="input-check" />
        )}
      </div>
    </>
  );
}
