import React, { useState, useContext } from "react";
import CredentialsHeader from "../../../components/continente-credentials/header/Header";
import CredentialsFooter from "../../../components/continente-credentials/footer/Footer";
import { useTranslation } from "react-i18next";
import CallCenterSvg from "../../../assets/images/call-center.svg";
import {
  ContactCenterRequest,
  ContactCenterOption,
} from "../../../models/continente-credentials/loyaltycard/ContactCenterRequest";
import { useLocation, useHistory } from "react-router-dom";
import { IdentifierValidationResponse } from "../../../models/continente-credentials/loyaltycard/IdentifierValidationResponse";
import { loyaltyCardService } from "../../../services/continente-credentials/loyaltycard/LoyaltyCardService";
import { DigitalAssetContext } from "../../../contexts/continente-credentials/DigitalAssetContext";
import MantraButton from "../../../components/continente-credentials/mantra-button/MantraButton";

export default function LoyaltyCardContactCenter() {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation<IdentifierValidationResponse>();
  const [loading, setLoading] = useState(false);
  const digitalAssetContext = useContext(DigitalAssetContext);
  const [error, setError] = useState("");
  const goToFaq = (anchor?: string) => {
    if (anchor) anchor = "#" + anchor;
    history.push("/continente-credentials/faq" + anchor);
  };
  const ANCHOR_NEED_HELP = "";
  const handleChoosedOption = async (
    contactCenterOption: ContactCenterOption
  ) => {
    setLoading(true);

    const contactCenterRequest: ContactCenterRequest = {
      contactCenterOption: contactCenterOption,
    };

    if (
      contactCenterRequest.contactCenterOption === ContactCenterOption.CALL &&
      digitalAssetContext.digitalAsset.isMobile === true
    ) {
      await window.open("tel:+911111111", "_self");
    }

    try {
      const response = await loyaltyCardService.loyaltyCardCallCenterOption(
        contactCenterRequest,
        location.state != null ? location.state.nextStep : ""
      );
      if (response.kind === "success") {
        history.push(response.value.nextStep.substring(4), response.value);
      }
      if (response.kind === "error") {
        switch (response.code) {
          case "300007":
            setError(t("identification_verification.input_error"));
            break;
          default:
            setError(t("common.unexpected_error"));
        }
      } else {
        setError(t("common.unexpected_error"));
      }

      setLoading(false);
    } catch (e) {
      setError(t("common.unexpected_error"));
      setLoading(false);
    }
  };
  return (
    <>
      <div className="row justify-content-center">
        <CredentialsHeader
          title={t("call_center_loyalty_card.header_title")}
          subtitle={t("call_center_loyalty_card.header_message")}
          showLoading={loading}
        />
      </div>

      <div className="row justify-content-center">
        <img src={CallCenterSvg} className="call-center-loyalty-card" alt="" />
      </div>

      <div className="row d-flex justify-content-center">
        <MantraButton
          text={t("call_center_loyalty_card.button_call_center")}
          type="button"
          size="large"
          onClick={() => handleChoosedOption(ContactCenterOption.CALL)}
        />
      </div>

      <div className="row d-flex justify-content-center">
        <span
          onClick={() =>
            handleChoosedOption(ContactCenterOption.ANOTHER_LOYALTY_CARD)
          }
          className="span-options"
        >
          {" "}
          {t("call_center_loyalty_card.another_card")}{" "}
        </span>
      </div>
      {digitalAssetContext.digitalAsset.requiredCardAssociation ? (
        <div className="row d-flex justify-content-center">
          <span
            onClick={() =>
              handleChoosedOption(ContactCenterOption.NEW_LOYALTY_CARD)
            }
            className="span-options"
          >
            {" "}
            {t("call_center_loyalty_card.new_card")}{" "}
          </span>
        </div>
      ) : (
        <div className="row d-flex justify-content-center">
          <span
            onClick={() =>
              handleChoosedOption(ContactCenterOption.NO_LOYALTY_CARD)
            }
            className="span-options"
          >
            {" "}
            {t("call_center_loyalty_card.whithout_card")}{" "}
          </span>
        </div>
      )}
      <div className="row justify-content-center form-error-message-copy2">
        {error !== "" && <div>{error}</div>}
      </div>
      <div className="contextual-information-header text-align-center">
        <span
          className="verification-resend-code link"
          onClick={() => goToFaq(ANCHOR_NEED_HELP)}
        >
          {t("common.need_help")}
        </span>
      </div>
      <div className="row d-flex flex-column">
        <CredentialsFooter
          contextualInformationVisible={false}
          footerAppsWebsitesIconsVisible={false}
        />
      </div>
    </>
  );
}
