import React, { useRef, useEffect } from "react";
import { MDCLinearProgress } from "@material/linear-progress";
import "@material/linear-progress/dist/mdc.linear-progress.css";

export default function Loading() {
  const loadingcontainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const mdcLinear = new MDCLinearProgress(
      loadingcontainer.current ?? new Element()
    );
    mdcLinear.determinate = false;

    mdcLinear.open();

    return () => {
      mdcLinear.destroy();
    };
  }, [loadingcontainer]);

  return (
    <div>
      <div
        ref={loadingcontainer}
        role="progressbar"
        className="mdc-linear-progress"
        id="my-progress-bar"
      >
        <div className="mdc-linear-progress__buffering-dots"></div>
        <div className="mdc-linear-progress__buffer"></div>
        <div className="mdc-linear-progress__bar mdc-linear-progress__primary-bar">
          <span className="mdc-linear-progress__bar-inner"></span>
        </div>
        <div className="mdc-linear-progress__bar mdc-linear-progress__secondary-bar">
          <span className="mdc-linear-progress__bar-inner"></span>
        </div>
      </div>
    </div>
  );
}
