import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import FaqInformation from "../../components/continente-credentials/faq-information/faq-information";
import CredentialsHeader from "../../components/continente-credentials/header/Header";

type questionProps = {
  question: string;
  answer: string;
  anchor: string;
};

export default function TermsAndConditions() {
  const { hash } = useLocation();
  const { t } = useTranslation();
  const { t: tTermsAndConditions } = useTranslation("termsandconditions");

  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState<questionProps[]>([]);
  const [anchorSelected, setAnchorSelected] = useState<string | null>(null);

  const useScrollToLocation = () => {
    const scrolledRef = React.useRef(false);
    const hashRef = React.useRef(hash);

    React.useEffect(() => {
      if (hash) {
        const auxHash = hash.split("#")[1];
        setAnchorSelected(auxHash as string);

        // We want to reset if the hash has changed
        if (hashRef.current !== hash) {
          hashRef.current = hash;
          scrolledRef.current = false;
        }

        // only attempt to scroll if we haven't yet (this could have just reset above if hash changed)
        if (!scrolledRef.current) {
          const id = hash.replace("#", "");
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
            scrolledRef.current = true;
          }
        }
      }
    }, []);
  };

  useEffect(() => {
    const termsandconditions = tTermsAndConditions<string, questionProps[]>(
      "termsandconditions",
      {
        returnObjects: true,
      }
    );

    if (typeof termsandconditions === "object") {
      setQuestions(termsandconditions);
    }

    setLoading(false);
  }, [hash, tTermsAndConditions]);

  useScrollToLocation();

  function changeAnchorSelected(newAnchor: string | null) {
    if (newAnchor === anchorSelected) {
      newAnchor = null;
    }

    setAnchorSelected(newAnchor);
  }

  return (
    <div className="row justify-content-center">
      <CredentialsHeader
        title={t("help.legal_advice")}
        showLoading={loading}
        previousStepBackXTimes={1}
      />
      {!loading && questions != null && questions.length > 0 && (
        <div className="faq-container mt-1">
          {questions.map((q, i) => (
            <FaqInformation
              key={i}
              faq={q}
              anchorSelected={anchorSelected}
              onClick={changeAnchorSelected}
            />
          ))}
        </div>
      )}
    </div>
  );
}
