import React, { useEffect, useState, ChangeEvent, useContext } from "react";
import { useForm, SubmitHandler, UseFormRegister } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";

import CredentialsFooter from "../../../../components/continente-credentials/footer/Footer";
import CredentialsHeader from "../../../../components/continente-credentials/header/Header";
import CredentialsForm from "../../../../components/continente-credentials/Form/Form";
import CredentialsInput from "../../../../components/continente-credentials/input/Input";

import BottomSheet from "../../../../components/continente-credentials/bottom-sheet/BottomSheet";
import ContinenteCredentialsFaq from "../../../../components/continente-credentials/continente-credentials-faq/ContinenteCredentialsFaq";
import { DigitalAssetContext } from "../../../../contexts/continente-credentials/DigitalAssetContext";

import { EmptyResponse } from "../../../../models/continente-credentials/mobile/EmptyResponse";
import { EmailAssociation } from "../../../../models/continente-credentials/mobile/EmailAssociation";
import { FormError } from "../../../../models/continente-credentials/common/FormError";

import { emailAssociationService } from "../../../../services/continente-credentials/mobile/EmailAssociationService";
import { useTranslation } from "react-i18next";

import { UserContext } from "../../../../contexts/continente-credentials/UserContext";
import MantraButton from "../../../../components/continente-credentials/mantra-button/MantraButton";

type Form = EmailAssociation & FormError;

export default function EmailUserAssociation() {
  const history = useHistory();
  const [labelEmailVisible, setLabelEmailVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [isBottomSheetOpen, setBottomSheetOpen] = useState(false);
  const [email, setEmail] = useState("");
  const goToFaq = (anchor?: string) => {
    if (anchor) anchor = "#" + anchor;
    history.push("/continente-credentials/faq" + anchor);
  };
  const ANCHOR_NEED_HELP = "";
  const { t } = useTranslation();
  const location = useLocation<EmptyResponse>();
  const digitalAssetContext = useContext(DigitalAssetContext);

  const userContext = useContext(UserContext);

  const formState = useForm<Form>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = formState;

  const closeBottomSheet = (isClosed: boolean) => {
    setBottomSheetOpen(!isClosed);
  };

  const handleEmailChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    clearErrors();
    setValue("email", value.trim());
    setEmail(value.trim());
    setLabelEmailVisible(value.length > 0 ? true : false);
  };

  const skipAssociation = async () => {
    setLoading(true);
    try {
      const response = await emailAssociationService.emailAssociation(
        {
          email: "",
          skipAssociation: true,
        },
        location.state != null ? location.state.nextStep : ""
      );

      switch (response.kind) {
        case "success":
          userContext.updateUser({
            ...userContext.user,
            email: "",
          });
          history.push(response.value.nextStep.substring(4), response.value);
          break;
        default:
          setError("submitError", { message: t("common.unexpected_error") });

          break;
      }

      setLoading(false);
    } catch (e) {
      setError("submitError", { message: t("common.unexpected_error") });
      setLoading(false);
    }
  };

  const onSubmit: SubmitHandler<EmailAssociation> = async (data) => {
    setLoading(true);
    try {
      const response = await emailAssociationService.emailAssociation(
        {
          email: data.email,
          skipAssociation: false,
        },
        location.state != null ? location.state.nextStep : ""
      );

      switch (response.kind) {
        case "success":
          userContext.updateUser({
            ...userContext.user,
            email: data.email?.trim() ?? "",
          });
          history.push(response.value.nextStep.substring(4), response.value);
          break;
        case "error":
          if (response.code === "300137") {
            setError("submitError", {
              message: t("email_association.invalid_association"),
            });
          } else {
            setError("submitError", { message: t("common.unexpected_error") });
          }
          break;
        default:
          setError("submitError", { message: t("common.unexpected_error") });
          break;
      }

      setLoading(false);
    } catch (e) {
      setError("submitError", { message: t("common.unexpected_error") });
      setLoading(false);
    }
  };

  useEffect(() => {
    const emailValidator = (register: UseFormRegister<Form>) =>
      register("email", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
        pattern: {
          value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
          message: t("email_association.invalid_email"),
        },
      });
    emailValidator(register);
  }, [register, t]);

  useEffect(() => {
    clearErrors();
    setValue("email", userContext.user.email.trim());
    setEmail(userContext.user.email.trim());
    setLabelEmailVisible(userContext.user.email.length > 0 ? true : false);
  }, [clearErrors, digitalAssetContext, setValue, userContext]);

  return (
    <>
      <CredentialsForm
        id="EmailUserAssociation"
        form={formState}
        onSubmit={(e) => {
          clearErrors();
          handleSubmit(onSubmit)(e);
        }}
      >
        <div className="row justify-content-center">
          <CredentialsHeader
            title={t("email_association.header_title")}
            subtitle={t("email_association.header_message")}
            showLoading={loading}
            previousStepBackXTimes={
              userContext.user.skippedPhoneNumberAssociation ? 1 : 2
            }
          />
        </div>
        <div className="row d-flex justify-content-center">
          <CredentialsInput
            value={email}
            labelText={t("email_association.email_input")}
            isFilled={labelEmailVisible}
            isError={errors.email ? true : false}
            errorMsg={errors.email ? errors.email.message : ""}
            onChange={handleEmailChange}
            autoComplete="on"
          />
        </div>
        <div className="row justify-content-center form-error-message-copy2">
          {errors.submitError && <div>{errors.submitError.message}</div>}
        </div>
        <div className="row d-flex justify-content-center">
          <MantraButton
            text={t("email_association.button_next_message")}
            type="submit"
          />
        </div>
        <div className="row d-flex justify-content-center">
          {digitalAssetContext.digitalAsset
            .requiredEmailAssociation ? undefined : (
            <MantraButton
              text={t("email_association.jump_next_button_message")}
              type="button"
              color="secondary"
              onClick={skipAssociation}
            />
          )}
        </div>
        <div className="contextual-information-header text-align-center">
          <span
            className="verification-resend-code link"
            onClick={() => goToFaq(ANCHOR_NEED_HELP)}
          >
            {t("common.need_help")}
          </span>
        </div>
        <div className="d-flex flex-column">
          <CredentialsFooter />
        </div>
      </CredentialsForm>
      {isBottomSheetOpen && (
        <BottomSheet
          content={<ContinenteCredentialsFaq />}
          closeBottomSheet={closeBottomSheet}
        />
      )}
    </>
  );
}
