import React, { useContext } from "react";
import CredentialsHeader from "../../../components/continente-credentials/header/Header";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import callCenter from "../../../assets/images/call-center.svg";
import { DigitalAssetContext } from "../../../contexts/continente-credentials/DigitalAssetContext";

export default function Blocked() {
  const { t } = useTranslation();
  const history = useHistory();

  const ANCHOR_NEED_HELP = "";

  const digitalAssetContext = useContext(DigitalAssetContext);

  const goToFaq = (anchor?: string) => {
    if (anchor) anchor = "#" + anchor;
    history.push("/continente-credentials/faq" + anchor);
  };

  const goToLogin = () => {
    history.push(
      `/user-register?clientId=${digitalAssetContext.digitalAsset.clientId}`
    );
  };

  return (
    <>
      <form id="ContactCenter" autoComplete="off">
        <div className="row justify-content-center">
          <CredentialsHeader
            title={t("password_recover_blocked.header_title")}
            subtitle={t("password_recover_blocked.subtitle")}
            isTextAlignCenter
          />
        </div>
      </form>
      <div className="row justify-content-center">
        <img src={callCenter} className="contact-center-icon" alt="" />
      </div>
      <div className="contextual-information-header text-align-center">
        <span
          className="verification-resend-code link"
          onClick={() => goToFaq(ANCHOR_NEED_HELP)}
        >
          {t("common.need_help")}
        </span>
      </div>

      <div className="contextual-information-header text-align-center">
        <span
          className="verification-resend-code link"
          onClick={() => goToLogin()}
        >
          {t("common.back")}
        </span>
      </div>
    </>
  );
}
