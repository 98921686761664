import React, { useEffect, useState, ChangeEvent, useCallback } from "react";
import { useForm, SubmitHandler, UseFormRegister } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import CredentialsFooter from "../../../../components/continente-credentials/footer/Footer";
import CredentialsForm from "../../../../components/continente-credentials/Form/Form";
import CredentialsHeader from "../../../../components/continente-credentials/header/Header";
import MantraButton from "../../../../components/continente-credentials/mantra-button/MantraButton";
import CredentialsInput from "../../../../components/continente-credentials/input/Input";
import TermsAndConditionsCheckbox from "../../../../components/continente-credentials/terms-and-conditions-checkbox/TermsAndConditionsCheckbox";
import { UserSignInResponse } from "../../../../models/continente-credentials/mobile/UserSignInResponse";
import { ValidatePassword } from "../../../../models/continente-credentials/mobile/ValidatePassword";
import { FormError } from "../../../../models/continente-credentials/common/FormError";
import { validatePasswordService } from "../../../../services/continente-credentials/mobile/ValidatePasswordService";
import ErrorMessage from "../../../../components/continente-credentials/error-message/error-message";
import { useTranslation } from "react-i18next";

import {
  useGoogleRecaptcha,
  getRecaptchaToken,
  siteKey,
} from "../../../../hooks/continente-credentials/useGoogleRecaptcha";

type Form = ValidatePassword & FormError;

export default function SetUserPassword() {
  const history = useHistory();
  const [labelPasswordVisible, setLabelPasswordVisible] =
    useState<boolean>(false);
  const [checkAcceptedTermsConditions, setAcceptedTermsConditions] =
    useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  const [, setPassword] = useState<string>("");
  const { t } = useTranslation();
  const location = useLocation<UserSignInResponse>();
  const [isAdminBlocked, setAdminBlocked] = useState(false);
  const phoneNumber =
    location.state != null ? location.state.properties.phoneNumber : "";
  const simplePhoneNumber =
    location.state != null ? location.state.properties.simplePhoneNumber : "";

  const formState = useForm<Form>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = formState;

  const handlePasswordChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    clearErrors();
    setPassword(value);
    setValue("password", value);
    setLabelPasswordVisible(value.length > 0);
  };

  const handleGoogleChallenge = useCallback(async () => {
    try {
      const response = await validatePasswordService.validatePassword(
        {
          passwordRecover: true,
          password: "",
          acceptedTermsConditions: false,
          recaptchaToken: getRecaptchaToken(location.state),
        },
        location.state != null ? location.state.nextStep : ""
      );

      switch (response.kind) {
        case "success":
          history.push(response.value.nextStep.substring(4), response.value);
          break;

        case "error":
          setError("submitError", { message: t("common.unexpected_error") });
          break;

        default:
          setError("submitError", { message: t("common.unexpected_error") });
          break;
      }

      setLoading(false);
    } catch (e) {
      setError("submitError", { message: t("common.unexpected_error") });
      setLoading(false);
    }
  }, [history, location.state, setError, t]);

  useGoogleRecaptcha(location.state, handleGoogleChallenge);

  const handleAcceptedTermsConditionsChange = () => {
    setAcceptedTermsConditions((state) => {
      return !state;
    });
    setValue("acceptedTermsConditions", checkAcceptedTermsConditions);
  };

  const passwordRecover = async () => {
    setLoading(true);

    try {
      const response = await validatePasswordService.validatePassword(
        {
          passwordRecover: true,
          password: "",
          acceptedTermsConditions: false,
          recaptchaToken: getRecaptchaToken(location.state),
        },
        location.state != null ? location.state.nextStep : ""
      );

      switch (response.kind) {
        case "success":
          history.push(response.value.nextStep.substring(4), response.value);
          break;

        case "error":
          setError("submitError", { message: t("common.unexpected_error") });
          break;

        default:
          setError("submitError", { message: t("common.unexpected_error") });
          break;
      }

      setLoading(false);
    } catch (e) {
      setError("submitError", { message: t("common.unexpected_error") });
      setLoading(false);
    }
  };

  const onSubmit: SubmitHandler<ValidatePassword> = async (data) => {
    setLoading(true);
    try {
      const response = await validatePasswordService.validatePassword(
        {
          passwordRecover: false,
          password: data.password,
          acceptedTermsConditions: data.acceptedTermsConditions,
        },
        location.state != null ? location.state.nextStep : ""
      );

      switch (response.kind) {
        case "success":
          history.push(response.value.nextStep.substring(4), response.value);
          break;

        case "error":
          switch (response.code) {
            case "100008":
              setError("password", {
                message: t("password_definition.password_invalid"),
              });
              break;

            case "300002":
              setError("submitError", {
                message: t("common.unexpected_error"),
              });
              break;

            case "100017":
              setError("blocked", {
                message: t("login_password.temp_blocked"),
              });
              setAdminBlocked(false);
              break;

            case "100025":
              setError("blocked", {
                message: t("login_password.other_blocked"),
              });
              setAdminBlocked(false);
              break;
            default:
              setError("submitError", {
                message: t("common.unexpected_error"),
              });
              break;
          }
          break;

        default:
          setError("submitError", { message: t("common.unexpected_error") });
          break;
      }

      setLoading(false);
    } catch (e) {
      setError("submitError", { message: t("common.unexpected_error") });
      setLoading(false);
    }
  };

  useEffect(() => {
    const passwordValidator = (register: UseFormRegister<Form>) =>
      register("password", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
      });
    const contextualInformationValidator = (register: UseFormRegister<Form>) =>
      register("acceptedTermsConditions", {
        required: {
          value: true,
          message: "",
        },
      });
    passwordValidator(register);

    if (location.state.properties.mustAcceptTermsConditions) {
      contextualInformationValidator(register);
    }
  }, [location.state.properties.mustAcceptTermsConditions, register, t]);

  const goToFaq = (anchor?: string) => {
    if (anchor) anchor = "#" + anchor;
    history.push("/continente-credentials/faq" + anchor);
  };
  const ANCHOR_NEED_HELP = "";

  const handleContactFormLinkClick = () => {
    goToFaq("#");
  };

  return (
    <>
      <CredentialsForm
        id="SetUserPassword"
        form={formState}
        autoComplete="on"
        onSubmit={(e) => {
          clearErrors();
          handleSubmit(onSubmit)(e);
        }}
        noValidate // here because of hidden input is of type email but value is a phoneNumber
      >
        <div className="row justify-content-center">
          <CredentialsHeader
            title={t("phonenumber_login_password_associated.header_title")}
            subtitle={
              t("phonenumber_login_password_associated.header_message") +
              " " +
              phoneNumber
            }
            showLoading={loading}
            previousStepBackXTimes={1}
          />
        </div>
        <div className="row d-flex justify-content-center">
          <div className="input-outline-eye">
            <div
              hidden
              // this needs the tag noValidate in form to work
            >
              <input type="email" value={simplePhoneNumber} />
            </div>
            <CredentialsInput
              labelText={t(
                "phonenumber_login_password_associated.password_input"
              )}
              isFilled={labelPasswordVisible}
              isError={errors.password || errors.blocked ? true : false}
              errorMsg={errors.password ? errors.password.message : ""}
              errorMsgBlocked={errors.blocked ? errors.blocked.message : ""}
              onChange={handlePasswordChange}
              type={"password"}
              name="validLoginPasswordCC"
              autoComplete="current-password"
            />
          </div>
        </div>
        {errors.blocked && isAdminBlocked && (
          <div className="contact-form-link">
            <span
              onClick={handleContactFormLinkClick}
              className="button-as-link"
            >
              {" "}
              {t(
                "phonenumber_login_password_associated.contact_form_link"
              )}{" "}
            </span>
          </div>
        )}
        {location.state.properties.mustAcceptTermsConditions && (
          <div className="row d-flex justify-content-center">
            <div className="form-check-group-copy">
              <div className="form-check">
                <TermsAndConditionsCheckbox
                  onClick={handleAcceptedTermsConditionsChange}
                  isError={errors.acceptedTermsConditions ? true : false}
                />
              </div>
            </div>
          </div>
        )}
        <ErrorMessage
          error={errors.submitError}
          acceptTermsAndConditions={errors.acceptedTermsConditions}
        />
        <div className="row d-flex justify-content-center">
          <MantraButton
            text={t(
              "phonenumber_login_password_associated.button_next_message"
            )}
            type="submit"
          />
        </div>
        <div className="row d-flex justify-content-center">
          <div className="validate-password-resend-code">
            <span
              className="validate-password-resend-code link"
              onClick={passwordRecover}
            >
              {t(
                "phonenumber_login_password_associated.forgot_password_message1"
              )}
            </span>
          </div>
        </div>
        <div
          id="g-recaptcha"
          className="row d-flex justify-content-center input-padding-24"
          style={{ marginTop: "10px" }}
          data-sitekey={siteKey}
        />
        <div className="contextual-information-header text-align-center">
          <span
            className="verification-resend-code link"
            onClick={() => goToFaq(ANCHOR_NEED_HELP)}
          >
            {t("common.need_help")}
          </span>
        </div>
        <div className="row d-flex flex-column">
          <CredentialsFooter />
        </div>
      </CredentialsForm>
    </>
  );
}
