import React, { ChangeEvent } from "react";

type UsernameInputProps = {
  value: string;
  error: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export default function UsernameInput({
  value,
  onChange,
  error,
  ...props
}: UsernameInputProps) {
  return (
    <input
      {...props}
      type="text"
      value={value}
      onChange={onChange}
      autoComplete="username"
      placeholder="* Email"
      className={`form-control ${error ? "error" : ""}`}
    />
  );
}
