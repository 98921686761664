import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

type InputProps = {
  contextualInformationVisible?: boolean;
  footerAppsWebsitesIconsVisible?: boolean;
  contextualInformationLink?: boolean;
  message?: string;
};

export default function Footer({
  footerAppsWebsitesIconsVisible = false,
  message = "",
  contextualInformationLink = true,
  ...props
}: InputProps) {
  const continenteOnline =
    require("../../../assets/images/continente-online.svg") as string;
  const appCC = require("../../../assets/images/app-cc.svg") as string;
  const siga = require("../../../assets/images/siga.svg") as string;
  const yammi = require("../../../assets/images/yammi.svg") as string;
  const quico = require("../../../assets/images/quico.svg") as string;

  const { t } = useTranslation();
  const history = useHistory();

  const goToFaq = (anchor?: string) => {
    if (anchor) anchor = "#" + anchor;
    history.push("/continente-credentials/faq" + anchor);
  };

  const ANCHOR_WHAT_IS_SSO = "";

  const messageToShow =
    message === "" ? t("common.mobile_footer_message") : message;
  return (
    <>
      <div>
        {props.contextualInformationVisible && contextualInformationLink && (
          <div className="contextual-information-copy-2">
            <span
              className="verification-resend-code link"
              onClick={() => goToFaq(ANCHOR_WHAT_IS_SSO)}
            >
              {messageToShow}
            </span>
          </div>
        )}
        {props.contextualInformationVisible && !contextualInformationLink && (
          <div className="contextual-information-copy-2">{messageToShow}</div>
        )}
        {footerAppsWebsitesIconsVisible && (
          <div className="container group">
            <div className="row align-items-center">
              <div className="col">
                <img src={appCC} className="login-footer" alt="" />
              </div>
              <div className="col">
                <img src={continenteOnline} className="login-footer" alt="" />
              </div>
              <div className="col">
                <img src={siga} className="login-footer" alt="" />
              </div>
              <div className="col icon-label">
                {t("common.cartao_continente")}
              </div>
              <div className="col icon-label">
                {t("common.continente_online")}
              </div>
              <div className="col icon-label">
                {t("common.continente_siga")}
              </div>
            </div>

            <div className="row align-items-center">
              <div
                className="col text-center"
                style={{
                  paddingRight: 0,
                  paddingLeft: "calc(var(--bs-gutter-x))",
                }}
              >
                <img src={yammi} className="login-footer " alt="" />
                <span className="icon-label">{t("common.yammi")}</span>
              </div>
              <div
                className="col text-center"
                style={{
                  paddingLeft: 0,
                  paddingRight: "calc(var(--bs-gutter-x))",
                }}
              >
                <img src={quico} className="login-footer " alt="" />
                <span className="icon-label">{t("common.quico")}</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
