import { Config } from "../config/Config";
import { ClientConsents } from "../models/ClientConsents";
import { Response } from "../models/Response";

const consentsUrl = Config.apiUrl + "user/consents";
const declinedOrCancelledErrorUrl = Config.apiUrl + "user/consents/decline";

export interface IConsentService {
  get(appId: string): Promise<Response<ClientConsents>>;
  accept(req: AcceptRequest): Promise<Response<void>>;
}

export type AcceptRequest = {
  clientId: string;
  consentIds: Array<string>;
};

export type DeclinedOrCancelledErrorRequest = {
  state: string;
  redirect_uri: string;
};

export class ConsentService implements IConsentService {
  async get(clientId: string): Promise<Response<ClientConsents>> {
    const url = new URL(consentsUrl);
    url.searchParams.append("client_id", clientId);

    const resp = await fetch(url.toString());

    if (resp.ok) {
      const body = await resp.json();

      const consents = body.consents.map((c: any) => ({
        id: c.id,
        description: c.description,
        displayText: c.displayText,
      }));

      const newContent = {
        clientName: body.clientName,
        consents: consents,
      };

      return { kind: "success", value: newContent };
    } else if (resp.status === 401) {
      return { kind: "unauthorized" };
    } else {
      return { kind: "error", code: "" };
    }
  }

  async accept(req: AcceptRequest): Promise<Response<void>> {
    const resp = await fetch(consentsUrl, {
      method: "POST",
      body: JSON.stringify(req),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (resp.ok) {
      return {
        kind: "success",
        value: undefined,
      };
    } else if (resp.status === 401) {
      return { kind: "unauthorized" };
    } else {
      const data = await resp.json();

      return { kind: "error", code: data.code };
    }
  }

  declinedOrCancelledError(req: DeclinedOrCancelledErrorRequest) {
    const url = new URL(declinedOrCancelledErrorUrl);

    if (req.state != null) url.searchParams.append("state", req.state);
    if (req.redirect_uri != null)
      url.searchParams.append("redirect_uri", req.redirect_uri);

    window.location.href = url.href;
  }
}

export const consentService = new ConsentService();
