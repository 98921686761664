import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler, UseFormRegister } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";

import Footer from "../../../components/site-credentials/footer/Footer";
import Header from "../../../components/site-credentials/header/Header";
import SiteCredentialsOtpInput from "../../../components/site-credentials/otp-input/OtpInput";

import { UserSignInResponse } from "../../../models/site-credentials/mobile/UserSignInResponse";
import { OtpValidation } from "../../../models/site-credentials/mobile/OtpValidation";
import { FormError } from "../../../models/site-credentials/common/FormError";

import { otpValidationService } from "../../../services/site-credentials/mobile/OtpValidationService";

import { useTranslation } from "react-i18next";
import { Config } from "../../../config/Config";
import ErrorMessage from "../../../components/continente-credentials/error-message/error-message";

type Form = OtpValidation & FormError;

export default function OtpVerify() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const location = useLocation<UserSignInResponse>();

  let properties;
  if (location.state == null) {
    properties = {
      username: "",
      jwtToken: "",
      email: "gamferreira@parceiro.mc.pt",
      phoneNumber: "910296279",
      phoneNumberCountryCode: "",
      mustAcceptTermsConditions: false,
      isEmailOtp: true,
    };
  } else {
    properties = location.state.properties;
  }

  const phoneNumber = properties.usernamePhoneNumber;
  const jwtToken = properties.jwtToken;
  const email = properties.email;

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<Form>({ mode: "onSubmit", reValidateMode: "onSubmit" });

  const handleOTPChange = (value: string) => {
    clearErrors();
    setValue("code", value.trim());
  };

  const onSubmit: SubmitHandler<OtpValidation> = async (data) => {
    setLoading(true);
    try {
      const response = await otpValidationService.otpValidation(
        {
          code: data.code,
          usernamePhoneNumber: phoneNumber,
          isEmailOtp: true,
          email: email,
        },
        location.state != null ? location.state.nextStep : ""
      );

      if (response.kind == "success") {
        history.push(response.value.nextStep.substring(4), {
          ...response.value,
          responseCode: "100000",
        });
      } else if (response.kind == "error") {
        switch (response.code) {
          case "100004": {
            setError("code", {
              message: t("site_credentials.otp_validation.invalid_code"),
            });
            break;
          }
          case "100015": {
            setError("submitError", {
              message: t("site_credentials.otp_validation.invalid_nonce"),
            });
            break;
          }
          case "100016": {
            setError("submitError", {
              message: t("site_credentials.otp_validation.invalid_nonce"),
            });
            break;
          }
          case "100024": {
            setError("submitError", {
              message: t(
                "site_credentials.otp_validation.associate_accounts_error"
              ),
            });
            break;
          }
          case "300137": {
            setError("submitError", {
              message: t(
                "site_credentials.otp_validation.credential_already_completed_error"
              ),
            });
            break;
          }
          default: {
            setError("submitError", { message: t("common.unexpected_error") });
            break;
          }
        }
      } else {
        setError("submitError", { message: t("common.unexpected_error") });
      }

      setLoading(false);
    } catch (e) {
      setError("submitError", { message: t("common.unexpected_error") });
      setLoading(false);
    }
  };

  useEffect(() => {
    const otpValidator = (register: UseFormRegister<Form>) =>
      register("code", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
        pattern: {
          value: /^[0-9]*$/,
          message: t("site_credentials.otp_validation.code_must_be_numeric"),
        },
      });
    otpValidator(register);
  }, [register, t]);

  return (
    <>
      <form
        id="VerifyOtp"
        autoComplete="off"
        onSubmit={(e) => {
          clearErrors();
          handleSubmit(onSubmit)(e);
        }}
      >
        <div className="row justify-content-center">
          <Header
            title={t(
              "site_credentials.email_association.otp_verify.header_title"
            )}
            subtitle={t(
              "site_credentials.email_association.otp_verify.header_message"
            )}
            showLoading={loading}
            previousStepBackXTimes={1}
          />
        </div>
        <div className="row justify-content-center">
          <SiteCredentialsOtpInput
            isEmail
            isError={errors.code ? true : false}
            errorMsg={errors.code ? errors.code.message : ""}
            onChange={handleOTPChange}
            jwtToken={jwtToken}
            onResendClick={setLoading}
          />
        </div>
        <ErrorMessage error={errors.submitError} />
        <div className="row d-flex flex-column">
          <Footer />
        </div>
      </form>
    </>
  );
}
