import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler, UseFormRegister } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import CredentialsFooter from "../../../../components/continente-credentials/footer/Footer";
import CredentialsHeader from "../../../../components/continente-credentials/header/Header";
import CredentialsForm from "../../../../components/continente-credentials/Form/Form";
import MantraButton from "../../../../components/continente-credentials/mantra-button/MantraButton";
import CredentialsOtpInput from "../../../../components/continente-credentials/otp-input/OtpInput";
import { FormError } from "../../../../models/continente-credentials/common/FormError";
import { mfaService } from "../../../../services/continente-credentials/common/MfaService";
import { useTranslation } from "react-i18next";
import ErrorMessage from "../../../../components/continente-credentials/error-message/error-message";
import { ValidatePasswordResponse } from "../../../../models/continente-credentials/mobile/ValidatePasswordResponse";
import { OtpValidation } from "../../../../models/continente-credentials/mobile/OtpValidation";

type Form = OtpValidation & FormError;

export default function PhoneNumberLoginPhoneNumberMfaValidation() {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const location = useLocation<ValidatePasswordResponse>();
  const history = useHistory();
  const jwtToken = ""; // TODO REMOVE THIS
  const formState = useForm<Form>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = formState;

  const handleOTPChange = (value: string) => {
    clearErrors();
    setValue("code", value.trim());
  };
  const onSubmit: SubmitHandler<OtpValidation> = async (data) => {
    setLoading(true);

    try {
      const response = await mfaService.ValidateMfaChallenge({
        code: data.code,
        requestUrl: location.state != null ? location.state.nextStep : "",
      });

      switch (response.kind) {
        case "success": {
          history.push(response.value.nextStep.substring(4), response.value);
          break;
        }
        case "error":
          if (response.code === "100004") {
            setError("code", {
              message: t("mfa_validation.invalid_code"),
            });
            break;
          } else if (response.code === "100015") {
            setError("submitError", {
              message: t("mfa_validation.invalid_nonce"),
            });
            break;
          } else if (response.code === "100016") {
            setError("submitError", {
              message: t("mfa_validation.invalid_nonce"),
            });
            break;
          }
          setError("submitError", { message: t("common.unexpected_error") });
          break;

        default:
          setError("submitError", { message: t("common.unexpected_error") });
          break;
      }

      setLoading(false);
    } catch (e) {
      setError("submitError", { message: t("common.unexpected_error") });
      setLoading(false);
    }
  };

  useEffect(() => {
    const otpValidator = (register: UseFormRegister<Form>) =>
      register("code", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
        pattern: {
          value: /^[0-9]*$/,
          message: t("mfa_validation.code_must_be_numeric"),
        },
      });
    otpValidator(register);
  }, [register, t]);

  return (
    <>
      <CredentialsForm
        id="VerifyOtp"
        form={formState}
        autoComplete="off"
        onSubmit={(e) => {
          clearErrors();
          handleSubmit(onSubmit)(e);
        }}
      >
        <div className="row justify-content-center">
          <CredentialsHeader
            title={t("mfa_validation.header_title")}
            subtitle={
              t("mfa_validation.header_message_phone") +
              location.state.properties.phoneNumber
            }
            showLoading={loading}
            previousStepBackXTimes={1}
          />
        </div>
        <div className="row justify-content-center">
          <CredentialsOtpInput
            isError={errors.code ? true : false}
            errorMsg={errors.code ? errors.code.message : ""}
            onChange={handleOTPChange}
            jwtToken={jwtToken}
            onResendClick={setLoading}
            isMfa={true}
          />
        </div>
        <ErrorMessage error={errors.submitError} isHtml={true} />
        <div className="row d-flex justify-content-center">
          <MantraButton
            text={t("mfa_validation.button_next_message")}
            type="submit"
          />
        </div>
        <div className="d-flex flex-column">
          <CredentialsFooter />
        </div>
      </CredentialsForm>
    </>
  );
}
