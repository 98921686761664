import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./i18nextConf";

const baseUrl: string | undefined = document
  .getElementsByTagName("base")[0]
  .getAttribute("href")
  ?.toString();
const rootElement = document.getElementById("root");

ReactDOM.render(
  React.createElement(
    BrowserRouter,
    {
      basename: baseUrl,
    },
    React.createElement(App, null)
  ),
  rootElement
);
