import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Checkbox from "../checkbox/Checkbox";

type TermsAndConditionsCheckboxProps = {
  isError: boolean;
  onClick: () => void;
};

const defaultProps = {
  isError: false,
  onClick: (): void => void 0,
};

function TermsAndConditionsCheckbox(
  props: TermsAndConditionsCheckboxProps & typeof defaultProps
) {
  const [t] = useTranslation();
  const history = useHistory();
  const goToTermsAndConditions = (anchor?: string) => {
    if (anchor) anchor = "#" + anchor;
    history.push("/terms-and-conditions" + anchor);
  };

  const ANCHOR_TermsAndConditions = "termsandconditions0";
  const ANCHOR_PrivacyPolitics = "termsandconditions1";

  return (
    <>
      <Checkbox onClick={props.onClick} isError={props.isError} label="" />

      <div className="terms-and-conditions-checkbox-div">
        {t("common.accept_terms_message1")}{" "}
        <span
          onClick={() => goToTermsAndConditions(ANCHOR_TermsAndConditions)}
          className="button-as-link"
        >
          <b>{t("common.accept_terms_message2")}</b>
        </span>{" "}
        {t("common.accept_terms_message3")}{" "}
        <span
          onClick={() => goToTermsAndConditions(ANCHOR_PrivacyPolitics)}
          className="button-as-link"
        >
          <b>{t("common.accept_terms_message4")}</b>
        </span>
      </div>
    </>
  );
}

TermsAndConditionsCheckbox.defaultProps = defaultProps;

export default TermsAndConditionsCheckbox;
