import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import FaqInformation from "../../components/continente-credentials/faq-information/faq-information";
import CredentialsHeader from "../../components/continente-credentials/header/Header";

import { DigitalAssetContext } from "../../contexts/continente-credentials/DigitalAssetContext";

type questionProps = {
  question: string;
  answer: string;
  anchor: string;
};

export default function Faq() {
  const back = require("../../assets/images/faq-back.svg") as string;
  const continenteLogo = require("../../assets/images/logo.svg") as string;

  const digitalAssetContext = useContext(DigitalAssetContext);

  const history = useHistory();
  const { hash } = useLocation();
  const { t } = useTranslation();
  const { t: tFaqs } = useTranslation("faqs");

  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState<questionProps[]>([]);
  const [anchorSelected, setAnchorSelected] = useState<string | null>(null);

  const PertoURL =
    process.env.REACT_APP_NODE_ENV === "production"
      ? "https://modelocontinenteb2c.my.salesforce-sites.com/WebformCredenciais?tema1=Login+Continente&clientId="
      : "https://modelocontinenteb2c--quality2.sandbox.my.salesforce-sites.com/WebformCredenciais?tema1=Credenciais+Continente&clientId=";

  const useScrollToLocation = () => {
    const scrolledRef = React.useRef(false);
    const hashRef = React.useRef(hash);

    React.useEffect(() => {
      if (hash) {
        const auxHash = hash.split("#")[1];
        setAnchorSelected(auxHash as string);

        // We want to reset if the hash has changed
        if (hashRef.current !== hash) {
          hashRef.current = hash;
          scrolledRef.current = false;
        }

        // only attempt to scroll if we haven't yet (this could have just reset above if hash changed)
        if (!scrolledRef.current) {
          const id = hash.replace("#", "");
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
            scrolledRef.current = true;
          }
        }
      }
    }, []);
  };

  useEffect(() => {
    const faqs = tFaqs<string, questionProps[]>("faqs", {
      returnObjects: true,
    });

    if (typeof faqs === "object") {
      setQuestions(faqs);
    }

    setLoading(false);
  }, [hash, tFaqs]);

  useScrollToLocation();

  function changeAnchorSelected(newAnchor: string | null) {
    if (newAnchor === anchorSelected) {
      newAnchor = null;
    }

    setAnchorSelected(newAnchor);
  }

  return (
    <div className="row justify-content-center">
      <CredentialsHeader
        title={t("site_credentials.helpCenter")}
        showLoading={loading}
        previousStepBackXTimes={1}
      />

      {!loading && questions != null && questions.length > 0 && (
        <div className="faq-container mt-1">
          {questions.map((q, i) => (
            <FaqInformation
              key={i}
              faq={q}
              anchorSelected={anchorSelected}
              onClick={changeAnchorSelected}
            />
          ))}
        </div>
      )}

      <iframe
        id="sfIframe"
        className="faq-perto-webform"
        src={PertoURL + digitalAssetContext.digitalAsset.clientId}
      ></iframe>
    </div>
  );
}
