import React from "react";
import { ReactComponent as Svg } from "../../assets/images/loading.svg";

export default function Loading() {
  return (
    <div id="modalLoading">
      <div id="divLoading">
        <Svg className="loadingLogo" />
      </div>
    </div>
  );
}
