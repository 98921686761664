export type ContactCenterRequest = {
  contactCenterOption: ContactCenterOption;
};

export enum ContactCenterOption {
  CALL,
  ANOTHER_LOYALTY_CARD,
  NEW_LOYALTY_CARD,
  NO_LOYALTY_CARD,
  HOME,
}
