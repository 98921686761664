import React, { ChangeEvent, useEffect, useState } from "react";
import Input from "../input/input";
import CountriesDropdown, {
  Country,
} from "../countries-dropdown/CountriesDropdown";
import { useTranslation } from "react-i18next";
import { useTransition } from "react-transition-state";
import alertCircle from "../../../assets/images/alert-circle.svg";

type InputType = "phone" | "email-phone";

type UsernameInputProps = {
  type: InputType;
  value: string;
  disabled?: boolean;
  isError?: boolean;
  errorMsg?: string;
  isFilled: boolean;
  defaultCountryCode: string;
  defaultPhoneCountryCode: string;
  onChange: (input: string, phoneNumberCode: string) => void;
};

const defaultProps = {
  defaultCountryCode: "PT",
  defaultPhoneCountryCode: "+351",
  onChange: (input: string, countryCode: string): void => void 0,
  value: "",
};

function isNumber(input: string) {
  if (input === null || input === undefined) {
    return false;
  }

  return !isNaN(Number(input)) && input.length > 2;
}

function UsernameInput(props: UsernameInputProps & typeof defaultProps) {
  const { t } = useTranslation();
  const [focus, setFocus] = useState(false);
  const [phoneNumberCountryCode, setPhoneNumberCountryCode] = useState(
    props.defaultPhoneCountryCode
  );

  const [countriesDropdownTransition, setCountriesDropdownTransition] =
    useTransition({
      timeout: 200,
      preEnter: true,
    });

  const withTransition = props.type === "email-phone";
  const transitionClass = withTransition
    ? `transition ${countriesDropdownTransition}`
    : "fixed";

  let label: string;
  switch (props.type) {
    case "phone":
      label = t("site_credentials.phonenumber_association.definition.label");
      break;
    case "email-phone":
      label = t("site_credentials.email_association.definition.label");
      break;
  }

  const onChange = (input: string, phoneNumberCountryCode: string) => {
    props.onChange(input, phoneNumberCountryCode);
  };

  const onInputChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>): void => {
    const isCurrentInputValueNumber = isNumber(props.value);
    const isNewInputValueNumber = isNumber(value);

    switch (props.type) {
      case "email-phone":
        if (!isCurrentInputValueNumber && isNewInputValueNumber) {
          setCountriesDropdownTransition(true);
        }

        if (isCurrentInputValueNumber && !isNewInputValueNumber) {
          setCountriesDropdownTransition(false);
        }
    }

    onChange(value, phoneNumberCountryCode);
  };

  useEffect(() => {
    if (props.type === "phone" && props.value !== "") {
      setCountriesDropdownTransition(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCountriesDropdownChange = (country: Country) => {
    setPhoneNumberCountryCode(country.phoneNumberCode);
    onChange(props.value, country.phoneNumberCode);
  };

  const onFocusChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>): void => {
    setFocus(true);
  };

  const onBlurChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>): void => {
    setFocus(false);
  };
  return (
    <>
      <div className={`d-flex sc-username-input ${transitionClass}`}>
        <div className="sc-countries-dropdown">
          <CountriesDropdown
            onChange={onCountriesDropdownChange}
            isError={props.isError}
            isFocus={focus}
            disabled={props.disabled}
            defaultCountryCode={props.defaultCountryCode}
            defaultPhoneNumberCode={props.defaultPhoneCountryCode}
          />
        </div>
        <div className="flex-grow-1">
          <Input
            labelText={label}
            isError={props.isError}
            errorMsg={props.errorMsg}
            isFilled={props.isFilled}
            disabled={props.disabled}
            onChange={onInputChange}
            value={props.value}
            onBlur={onBlurChange}
            onFocus={onFocusChange}
            showError={false}
          />
        </div>
      </div>
      <div className="d-inline-flex align-items-center errorDiv">
        {props.isError && (
          <img src={alertCircle} alt="" className="alert-cicle" />
        )}
        <span className="errorLabel">{props.errorMsg}</span>
      </div>
    </>
  );
}

UsernameInput.defaultProps = defaultProps;

export default UsernameInput;
