import React, { useEffect, useState } from "react";
import CredentialsDigitInput from "../digit-input/DigitInput";
import { useTranslation } from "react-i18next";
import { otpService } from "../../../services/continente-credentials/common/OtpService";
import { mfaService } from "../../../services/continente-credentials/common/MfaService";

type InputProps = {
  onChange: (otp: string) => void;
  length?: number;
  isError?: boolean;
  errorMsg?: string;
  jwtToken: string;
  isLoyalty?: boolean;
  onResendClick: (loading: boolean) => void;
  isEmail?: boolean;
  isMfa?: boolean;
  mfaUsername?: string;
};

export default function OtpInput({
  length = 6,
  isLoyalty = false,
  isEmail = false,
  isMfa = false,
  ...props
}: InputProps) {
  const [otp, setOtp] = useState<string[]>(
    Array.from({ length: length }, (_) => "")
  );
  // const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [otpInputError, setOtpInputError] = useState<string>("");
  const [seconds, setSeconds] = useState(0);
  const [otpInputJwt, setOtpInputJwt] = useState<string>(props.jwtToken);
  const alertCircle =
    require("../../../assets/images/alert-circle.svg") as string;

  useEffect(() => {
    const initialInput = document.getElementById(`digit${0}`);

    if (initialInput != null) {
      initialInput.focus();
    }
  }, []);

  useEffect(() => {
    const timerId = setTimeout(() => setTimer(), 1000);
    return () => clearTimeout(timerId);
  });

  const setTimer = () => {
    if (seconds > 0) {
      setSeconds(seconds - 1);
      setOtpInputError(
        t("otp_validation.resend_time_error", { time: seconds })
      );
    } else {
      setOtpInputError("");
      setSeconds(0);
    }
  };

  const changeInput = (event: any, position: number) => {
    const value = event.target.value;
    if (!isNaN(+value)) {
      //true if its a number
      if (value.length > 1) {
        const code = value.split("").map((char: any, index: any) => {
          if (Number.isInteger(Number(char))) {
            return Number(char);
          }

          return "";
        });

        const newOtp = [...otp];
        code.forEach((value: any, index: any) => {
          if (index < length) {
            newOtp[index] = value;
          }
        });
        setOtp(newOtp);

        props.onChange(newOtp.join(""));

        const initialInput = document.getElementById(`digit${0}`);

        if (initialInput != null) {
          initialInput.focus();
        }
      } else {
        const nextInput = document.getElementById(`digit${position + 1}`);

        if (nextInput != null) {
          nextInput.focus();
        }

        const newOtp = [...otp];
        newOtp[position] = value;

        setOtp(newOtp);

        props.onChange(newOtp.join(""));
      }
    }
  };

  const handleBackspace = (position: number) => {
    const actualInput = document.getElementById(
      `digit${position}`
    ) as HTMLInputElement;

    if (actualInput != null && !actualInput.value) {
      const prevInput = document.getElementById(`digit${position - 1}`);

      if (prevInput != null) {
        prevInput.focus();
      }

      const newOtp = [...otp];
      newOtp[position - 1] = "";

      setOtp(newOtp);

      props.onChange(newOtp.join(""));
    } else {
      actualInput.focus();
      const newOtp = [...otp];
      newOtp[position] = "";

      setOtp(newOtp);

      props.onChange(newOtp.join(""));
    }
  };

  const resendOTP = async () => {
    if (seconds > 0) {
      return;
    }

    setOtp(Array.from({ length: length }, (_) => ""));

    props.onChange(otp.join(""));
    setOtpInputError("");

    props.onResendClick(true);

    let response;

    if (isLoyalty) {
      response = await otpService.resendLoyaltyOTP({
        jwtToken: otpInputJwt,
        time: "0",
      });
    } else if (isEmail) {
      response = await otpService.resendEmailOTP({
        jwtToken: otpInputJwt,
        time: "0",
      });
    } else if (isMfa) {
      response = await mfaService.ResendMfaChalllenge(props.mfaUsername ?? "");
    } else {
      response = await otpService.resendOTP({
        jwtToken: otpInputJwt,
        time: "0",
      });
    }

    switch (response.kind) {
      case "success":
        setOtpInputJwt(response.value.jwtToken);

        setSeconds(parseInt(response.value.time));
        setOtpInputError(
          t("otp_validation.resend_time_error", { time: response.value.time })
        );
        break;
      case "error":
        if (response.code === "100010") {
          setSeconds(parseInt(response.message) - 1);
          setOtpInputError(
            t("otp_validation.resend_time_error", { time: response.message })
          );
          break;
        }

        setOtpInputError(t("otp_validation.otp_error"));
        break;
      default:
        setOtpInputError(t("common.unexpected_error"));
        break;
    }

    props.onResendClick(false);
  };

  return (
    <>
      <div className="verification-code">
        {otp.map((d, i) => (
          <CredentialsDigitInput
            {...props}
            id={`digit${i}`}
            key={i}
            value={d}
            onChange={(e) => changeInput(e, i)}
            onBackspace={() => handleBackspace(i)}
          />
        ))}
        <div className="d-flex align-items-center errorDiv">
          {props.isError && (
            <img src={alertCircle} alt="" className="alert-cicle" />
          )}
          <span className="errorLabel">
            <div
              className="text1"
              style={{ textAlign: "left", paddingTop: "13px" }}
              dangerouslySetInnerHTML={{
                __html: props.errorMsg ? props.errorMsg.toString() : "",
              }}
            />
          </span>
        </div>
      </div>
      <div className="verification-resend-code">
        {!isEmail && t("otp_validation.code_not_received_message1")}
        {isEmail && t("otp_validation.email_not_received_message1")}&nbsp;
        {otpInputError === "" && (
          <span className="verification-resend-code link" onClick={resendOTP}>
            {" "}
            {!isEmail && t("otp_validation.code_not_received_message2")}{" "}
            {isEmail && t("otp_validation.email_not_received_message2")}{" "}
          </span>
        )}
        {otpInputError !== "" && (
          <span className="verification-resend-code link">
            {" "}
            {otpInputError}{" "}
          </span>
        )}
      </div>
      {/* {process.env.REACT_APP_NODE_ENV !== "production" && (
        <div style={{ backgroundColor: "#ee7171", color: "white" }}>
          Os Otps serão ativados para testes! Se o valor 111111 não funcionar
          deverão procurar no Contacto o código enviado.
        </div>
      )} */}
    </>
  );
}
