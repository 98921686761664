import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-solid-svg-icons";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import "./checkbullet.css";

type CheckBulletProps = {
  checked: boolean;
  text: string;
};

const defaultProps = {
  checked: false,
};

function CheckBullet(props: CheckBulletProps & typeof defaultProps) {
  return (
    <div className="check-bullet d-inline-flex align-items-center">
      {props.checked ? (
        <FontAwesomeIcon icon={faCheckSquare} />
      ) : (
        <FontAwesomeIcon icon={faSquare} />
      )}
      <span className="" style={{ fontSize: 15 }}>
        {props.text}
      </span>
    </div>
  );
}

CheckBullet.defaultProps = defaultProps;

export default CheckBullet;
