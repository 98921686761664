import React, { useEffect, useState, ChangeEvent } from "react";
import { useForm, SubmitHandler, UseFormRegister } from "react-hook-form";

import CredentialsHeader from "../../components/site-credentials/header/Header";
//import Title from "../../components/site-credentials/title/Title";
import SiteCredentialsInput from "../../components/site-credentials/input/input";
import Footer from "../../components/site-credentials/footer/Footer";
import TermsAndConditionsCheckbox from "../../components/site-credentials/terms-and-conditions-checkbox/TermsAndConditionsCheckbox";
import CheckBullet from "../../components/site-credentials/bullets/CheckBullet";

import { SetPassword } from "../../models/site-credentials/mobile/SetPassword";
import { FormError } from "../../models/site-credentials/common/FormError";
import { EmptyResponse } from "../../models/site-credentials/mobile/EmptyResponse";

import { setPasswordService } from "../../services/site-credentials/mobile/SetPasswordService";

import { Config } from "../../config/Config";
import {
  verifySpecialChars,
  verifyLowerCase,
  verifyMinChar,
  verifyNumber,
  verifyUpperCase,
} from "../../utils/passwordRules";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";

type Form = SetPassword & FormError;

export default function PasswordDefinition() {
  const history = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [labelPasswordVisible, setLabelPasswordVisible] =
    useState<boolean>(false);
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [acceptedTermsConditions, setAcceptedTermsConditions] =
    useState<boolean>(true);
  const [minCharacters, setMinCharacters] = useState<boolean>(false);
  const [containNumber, setContainNumber] = useState<boolean>(false);
  const [containLowercase, setContainLowercase] = useState<boolean>(false);
  const [containSpecialChar, setContainSpecialChar] = useState<boolean>(false);
  const [passwordsMatch, setPasswordsMatch] = useState<boolean>(false);
  const [isButtonEnabled, setButtonEnabled] = useState<boolean>(false);
  const [isCheckBoxChecked, setCheckBoxChecked] = useState<boolean>(false);
  const [containUppercase, setContainUppercase] = useState<boolean>(false);
  const location = useLocation<EmptyResponse>();
  const goToFaq = (anchor?: string) => {
    if (anchor) anchor = "#" + anchor;
    history.push("/site-credentials/faq" + anchor);
  };
  const ANCHOR_NEED_HELP = "";
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<Form>({ mode: "onSubmit", reValidateMode: "onSubmit" });

  const handlePasswordChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    clearErrors();
    setPassword(value);
    setValue("password", value);
    checkAndEnableCheckBox(value, passwordConfirmation);
    setLabelPasswordVisible(value.length > 0 ? true : false);
  };

  const [
    labelPasswordConfirmationVisible,
    setLabelPasswordConfirmationVisible,
  ] = useState<boolean>(false);

  const handlePasswordConfirmationChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    clearErrors();
    setPasswordConfirmation(value);
    setValue("passwordConfirmation", value);
    checkAndEnableCheckBox(password, value);
    setLabelPasswordConfirmationVisible(value.length > 0 ? true : false);
  };

  const handleAcceptedTermsConditionsChange = (): void => {
    setAcceptedTermsConditions((acceptedTermsConditions) => {
      return !acceptedTermsConditions;
    });
    setValue("acceptedTermsConditions", acceptedTermsConditions);
    setCheckBoxChecked(acceptedTermsConditions);
    checkAndEnableCheckBox(
      password,
      passwordConfirmation,
      acceptedTermsConditions
    );
  };

  const onSubmit: SubmitHandler<SetPassword> = async (data) => {
    setLoading(true);
    if (password === passwordConfirmation) {
      try {
        const response = await setPasswordService.setPassword(
          {
            password: data.password,
            passwordConfirmation: data.passwordConfirmation,
            acceptedTermsConditions: data.acceptedTermsConditions,
          },
          Config.SiteCredentials.PasswordDefinitionUrl
        );

        switch (response.kind) {
          case "success":
            history.push(response.value.nextStep.substring(4), response.value);

            break;
          default:
            setError("submitError", { message: t("common.unexpected_error") });

            break;
        }

        setLoading(false);
      } catch (e) {
        setError("submitError", { message: t("common.unexpected_error") });
        setLoading(false);
      }
    } else {
      setError("password", {
        message: t("password_definition.passwords_not_match"),
      });
      setError("passwordConfirmation", {
        message: t("password_definition.passwords_not_match"),
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!verifyMinChar(password)) setMinCharacters(false);
    else setMinCharacters(true);

    if (verifyNumber(password)) setContainNumber(true);
    else setContainNumber(false);

    if (verifyUpperCase(password)) setContainUppercase(true);
    else setContainUppercase(false);

    if (verifyLowerCase(password)) setContainLowercase(true);
    else setContainLowercase(false);

    if (verifySpecialChars(password)) setContainSpecialChar(true);
    else setContainSpecialChar(false);
  }, [password]);

  useEffect(() => {
    if (password.length < 6) {
      setMinCharacters(false);
    } else setMinCharacters(true);

    if (/(?=.*\d)(?=.*[A-Za-z])/.test(password)) {
      setContainNumber(true);
    } else setContainNumber(false);

    if (/(?=.*[A-Z])/.test(password)) {
      setContainUppercase(true);
    } else setContainUppercase(false);
  }, [password]);

  useEffect(() => {
    const passwordValidator = (register: UseFormRegister<Form>) =>
      register("password", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
        pattern: {
          value: /^((?=.*\d)(?=.*[A-Za-z])).{6,}$/,
          message: t("password_definition.password_invalid"),
        },
      });
    const passwordConfirmationValidator = (register: UseFormRegister<Form>) =>
      register("passwordConfirmation", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
        pattern: {
          value: /^((?=.*\d)(?=.*[A-Za-z])).{6,}$/,
          message: t("password_definition.password_invalid"),
        },
      });
    const acceptedTermsConditionsValidator = (
      register: UseFormRegister<Form>
    ) =>
      register("acceptedTermsConditions", {
        required: {
          value: true,
          message: "",
        },
      });
    passwordValidator(register);
    passwordConfirmationValidator(register);
    acceptedTermsConditionsValidator(register);
  }, [register, t]);

  function verifyPasswordsMatch(pw: string, pwConfirmation: string) {
    if (pw === pwConfirmation && pw !== "") {
      setPasswordsMatch(true);
      return true;
    }

    setPasswordsMatch(false);
    return false;
  }

  function checkAndEnableCheckBox(
    pw: string,
    pwConfirmation: string,
    isChecked: boolean | null = null
  ) {
    const checked = isChecked ?? isCheckBoxChecked;

    if (
      verifyPasswordsMatch(pw, pwConfirmation) &&
      verifyMinChar(pw) &&
      verifyNumber(pw) &&
      verifyLowerCase(pw) &&
      verifyUpperCase(pw) &&
      verifySpecialChars(pw) &&
      checked
    ) {
      setButtonEnabled(true);
    } else {
      setButtonEnabled(false);
    }
  }

  return (
    <>
      <form
        id="SetUserPassword"
        autoComplete="on"
        onSubmit={(e) => {
          clearErrors();
          handleSubmit(onSubmit)(e);
        }}
      >
        <div className="row">
          <CredentialsHeader
            title={t("site_credentials.define_password")}
            subtitle={t("site_credentials.information_password")}
            showLoading={loading}
            isTextAlignCenter={false}
          />
        </div>

        <div className="row d-flex  ">
          <div className="input-outline-eye">
            <SiteCredentialsInput
              labelText={t("site_credentials.password_input")}
              isFilled={labelPasswordVisible}
              isError={errors.password ? true : false}
              errorMsg={errors.password ? errors.password.message : ""}
              onChange={handlePasswordChange}
              type="password"
            />
            <SiteCredentialsInput
              labelText={t("site_credentials.confirm_password")}
              isFilled={labelPasswordConfirmationVisible}
              isError={errors.passwordConfirmation ? true : false}
              errorMsg={
                errors.passwordConfirmation
                  ? errors.passwordConfirmation.message
                  : ""
              }
              onChange={handlePasswordConfirmationChange}
              type="password"
            />
          </div>
          <div className="form-check-group-copy">
            <div className="form-check">
              <TermsAndConditionsCheckbox
                onClick={handleAcceptedTermsConditionsChange}
                isError={errors.acceptedTermsConditions ? true : false}
              />
            </div>{" "}
            <div className="form-check-group mt-4">
              <div className="d-flex w-75 m-auto flex-wrap flex-column">
                <CheckBullet
                  checked={minCharacters}
                  text={t(
                    "site_credentials.password_validation_length_maximum"
                  )}
                />
                <CheckBullet
                  checked={containNumber}
                  text={t("site_credentials.password_validation_alphanumeric")}
                />
                <CheckBullet
                  checked={containUppercase}
                  text={t("site_credentials.password_validation_uppercase")}
                />
                <CheckBullet
                  checked={containLowercase}
                  text={t("password_definition.password_validation_lowercase")}
                />
                <CheckBullet
                  checked={containSpecialChar}
                  text={t(
                    "password_definition.password_validation_special_char"
                  )}
                />
                <CheckBullet
                  checked={passwordsMatch}
                  text={t("password_definition.passwords_match")}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row form-error-message-copy2">
          {errors.submitError && <div>{errors.submitError.message}</div>}
        </div>
        <div className="sc-contextual-information-header text-align-center">
          <span
            className="verification-resend-code link"
            onClick={() => goToFaq(ANCHOR_NEED_HELP)}
          >
            {t("common.need_help")}
          </span>
        </div>
        <div className="row d-flex flex-column">
          <Footer />
        </div>
      </form>
    </>
  );
}
