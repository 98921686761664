import React, { useContext, useEffect, useState, ChangeEvent } from "react";
import OutsideClickHandler from "../../OutsideClickHandler";
import { FlagOption, Flag } from "./Flag";
import SimpleBar from "simplebar-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faChevronUp,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

import { UserContext } from "../../../contexts/continente-credentials/UserContext";
import countries from "../../continente-credentials/countries-dropdown/phone_data.json.js";
import countryInfo from "./CountryLabels";

const excludedCountries = ["AX", "IO", "GG", "MF", "PM", "VC", "SJ"];

const countryLabels = countryInfo.labels;

const countryOptions = Object.entries(countries.countries)
  .map(([code, phoneNumberCodes]) => ({
    label: countryLabels[code],
    value: code,
    countryCode: code,
    phoneNumberCode: phoneNumberCodes[0],
  }))
  .filter((o) => o.label)
  .filter((o) => !excludedCountries.includes(o.countryCode))
  .sort((a, b) => a.label.localeCompare(b.label));

type CountryCodeSelectProps = {
  defaultCountryCode: string;
  defaultPhoneNumberCode: string;
  isError?: boolean;
  isFocus?: boolean;
  onChange: (country: Country) => void;
  disabled?: boolean;
};

export type Country = {
  code: string;
  phoneNumberCode: string;
};

function CountryCodeSelect(props: CountryCodeSelectProps) {
  const [openSelect, setOpenSelect] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] = useState(
    props.defaultCountryCode
  );
  const userContext = useContext(UserContext);

  const handleSelectOnChange = (countryCode: string) => {
    const { onChange } = props;

    const option = options().find((o: any) => o.countryCode === countryCode);

    setOpenSelect(false);
    setSearchText("");

    if (option) {
      setSelectedCountryCode(option.countryCode);

      onChange({
        code: option.countryCode,
        phoneNumberCode: `+${option.phoneNumberCode}`,
      });
    }
  };

  useEffect(() => {
    if (userContext.user.countryCode) {
      setSelectedCountryCode(userContext.user.countryCode);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleButtonOnClick = () => {
    setOpenSelect(!openSelect);
    setSearchText("");
  };

  const handleSearchOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleOutsideClick = () => {
    if (openSelect) {
      setOpenSelect(false);
      setSearchText("");
    }
  };

  const options = () => {
    return searchText
      ? countryOptions.filter((o) =>
          o.label.toLowerCase().includes(searchText.toLowerCase())
        )
      : countryOptions;
  };

  const renderDropdownPanel = () => {
    const countries = options();

    return (
      <div className="country-code-menu">
        <div className="country-search">
          <div className="country-search-input">
            <FontAwesomeIcon icon={faSearch} />
            <input
              type="text"
              placeholder="Pesquisar por país..."
              value={searchText || ""}
              onChange={handleSearchOnChange}
            />
          </div>
        </div>
        <SimpleBar
          forceVisible="y"
          autoHide={false}
          className="socialLoginConsent_body_text socialLoginConsent"
          scrollbarMaxSize={50}
        >
          {countries.length ? (
            <div className="countries-list">
              {countries.map(({ label, countryCode, phoneNumberCode }) => (
                <FlagOption
                  key={`${countryCode}`}
                  countryCode={countryCode}
                  label={label}
                  selected={countryCode === selectedCountryCode}
                  phoneNumberCode={phoneNumberCode}
                  onClick={handleSelectOnChange}
                />
              ))}
            </div>
          ) : (
            <div className="w-100 text-center">Sem Resultados</div>
          )}
        </SimpleBar>
      </div>
    );
  };

  return (
    <OutsideClickHandler outsideclick={handleOutsideClick}>
      <div className="country-code-panel">
        <button
          type="button"
          disabled={props.disabled}
          className={`${props.isFocus ? "focus" : ""}`}
          onClick={handleButtonOnClick}
        >
          <Flag countryCode={selectedCountryCode || ""} />
          {!props.disabled &&
            (openSelect ? (
              <FontAwesomeIcon icon={faChevronUp} />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} />
            ))}
        </button>
        {openSelect && renderDropdownPanel()}
      </div>
    </OutsideClickHandler>
  );
}

export default CountryCodeSelect;
