import { Config } from "../../../config/Config";
import { Response } from "../../../models/continente-credentials/common/Response";
import {
  CreateUser,
  CreateUserResponse,
} from "../../../models/continente-credentials/tests/CreateUser";
import { DeleteUser } from "../../../models/continente-credentials/tests/DeleteUser";

export interface IUserService {
  UserCreate(createUser: CreateUser): Promise<Response<CreateUserResponse>>;
  DeleteUser(username: DeleteUser): Promise<Response<null>>;
}

export class UserService implements IUserService {
  async UserCreate(
    createUser: CreateUser
  ): Promise<Response<CreateUserResponse>> {
    const resp = await fetch(Config.ContinenteCredentials.UserTestsUrl, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(createUser),
    });
    if (resp.ok) {
      return { kind: "success", value: await resp.json() };
    } else if (resp.status === 400) {
      const error = await resp.json();

      return {
        kind: "error",
        code: error.code,
        message: error.message,
      };
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }

  async DeleteUser(DeleteUser: DeleteUser): Promise<Response<null>> {
    const resp = await fetch(Config.ContinenteCredentials.UserTestsUrl, {
      method: "DELETE",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(DeleteUser),
    });
    if (resp.ok) {
      return { kind: "success", value: null };
    } else if (resp.status === 400) {
      const error = await resp.json();

      return {
        kind: "error",
        code: error.code,
        message: error.message,
      };
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }
}

export const userService = new UserService();
