import React, { useContext } from "react";
import CredentialsHeader from "../../components/continente-credentials/header/Header";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import callCenter from "../../assets/images/call-center.svg";
import { DigitalAssetContext } from "../../contexts/continente-credentials/DigitalAssetContext";
import { ContactCenterModel } from "../../models/ContactCenterModel";

export default function ContactCenter() {
  const { t } = useTranslation();
  const history = useHistory();

  let previousStepBackXTimes = 1;

  const location = useLocation<ContactCenterModel>();
  const backStep: string | undefined =
    location.state?.properties?.backStep?.substring(4);

  const ANCHOR_NEED_HELP = "";

  const digitalAssetContext = useContext(DigitalAssetContext);

  const goToFaq = (anchor?: string) => {
    if (anchor) anchor = "#" + anchor;
    history.push("/continente-credentials/faq" + anchor);
  };

  const goToDeadEnd = () => {
    history.push(
      `/dead-end?clientId=${digitalAssetContext.digitalAsset.clientId}`
    );
  };

  const backStepCardAssociation = () => {
    if (backStep) {
      if (backStep === "/card/continente-card-association") {
        previousStepBackXTimes = 2;
        return true;
      }

      if (backStep === "/loyalty/cards/verify-otp") {
        previousStepBackXTimes = 3;
        return true;
      }
    }

    return false;
  };

  return (
    <>
      <form id="ContactCenter" autoComplete="off">
        <div className="row justify-content-center">
          {backStepCardAssociation() ? (
            <CredentialsHeader
              title={t("new_contact_center.header_title")}
              subtitle={t("new_contact_center.subtitle")}
              isTextAlignCenter
              previousStepBackXTimes={previousStepBackXTimes}
            />
          ) : backStep ? (
            <CredentialsHeader
              title={t("new_contact_center.header_title")}
              subtitle={t("new_contact_center.subtitle")}
              isTextAlignCenter
              backClickToPushPath={backStep}
            />
          ) : (
            <CredentialsHeader
              title={t("new_contact_center.header_title")}
              subtitle={t("new_contact_center.subtitle")}
              isTextAlignCenter
              previousStepBackXTimes={previousStepBackXTimes}
            />
          )}
        </div>
      </form>
      <div className="row justify-content-center">
        <img src={callCenter} className="contact-center-icon" alt="" />
      </div>
      <div className="contextual-information-header text-align-center">
        <span
          className="verification-resend-code link"
          onClick={() => goToFaq(ANCHOR_NEED_HELP)}
        >
          {t("common.need_help")}
        </span>
      </div>
      {location.state.properties.isFromManualCardAssociation &&
        !digitalAssetContext.digitalAsset.requiredCardAssociation && (
          <div className="contextual-information-header text-align-center">
            <span
              className="verification-resend-code link"
              onClick={() => goToDeadEnd()}
            >
              {t("new_contact_center.reg_card_later")}
            </span>
          </div>
        )}
    </>
  );
}
