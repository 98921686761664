import { Config } from "../../../config/Config";
import { AuthorizeRequest } from "../../../models/continente-credentials/common/AuthorizeRequest";
import { AuthorizeResponse } from "../../../models/continente-credentials/common/AuthorizeResponse";
import {
  generateRandomString,
  challenge_from_verifier,
} from "../../../utils/pkce";
import { Response } from "../../../models/continente-credentials/common/Response";
import { AuthorizeSSORequest } from "../../../models/continente-credentials/common/AuthorizeSSORequest";
import { AuthorizeSSOResponse } from "../../../models/continente-credentials/common/AuthorizeSSOResponse";

const codeChallengeMethod = "S256";

export class AuthorizationService {
  async authorize(
    authorizeRequest: AuthorizeRequest
  ): Promise<Response<AuthorizeResponse>> {
    const codeVerifier = generateRandomString();
    const codeChallenge = await challenge_from_verifier(codeVerifier);

    const params = new URLSearchParams({
      clientId: authorizeRequest.clientId,
      codeChallenge: codeChallenge,
      codeChallengeMethod: codeChallengeMethod,
    });

    try {
      const resp = await fetch(
        `${Config.ContinenteCredentials.authorizeUrl}?${params}`
      );

      switch (resp.status) {
        case 200: {
          if (resp.redirected) {
            return { kind: "redirect", url: resp.url };
          }

          const body = await resp.json();
          console.log("Authorize Body Response:" + JSON.stringify(body));
          return {
            kind: "success",
            value: {
              authorizationCode: body.authorizationCode,
              codeVerifier: codeVerifier,
            },
          };
        }
        default:
          return { kind: "unexpected_error" };
      }
    } catch {
      return { kind: "unexpected_error" };
    }
  }
  async authorizeSSO(
    authorizeRequest: AuthorizeSSORequest
  ): Promise<Response<AuthorizeSSOResponse>> {
    const params = new URLSearchParams({
      clientId: authorizeRequest.clientId,
      sessionId: authorizeRequest.sessionId,
      ssoToken: authorizeRequest.ssoToken,
    });

    try {
      const resp = await fetch(
        `${Config.ContinenteCredentials.authorizeSSOUrl}?${params}`
      );

      switch (resp.status) {
        case 200: {
          if (resp.redirected) {
            return { kind: "redirect", url: resp.url };
          }

          return {
            kind: "success",
            value: await resp.json(),
          };
        }
        default:
          return { kind: "unexpected_error" };
      }
    } catch {
      return { kind: "unexpected_error" };
    }
  }
}

export const authorizationService = new AuthorizationService();
