import { UserSignIn } from "../../../models/continente-credentials/mobile/UserSignIn";
import { UserSignInResponse } from "../../../models/continente-credentials/mobile/UserSignInResponse";
import { Config } from "../../../config/Config";
import { Response } from "../../../models/continente-credentials/common/Response";

export interface IUserSignInService {
  userSignIn(userSignIn: UserSignIn): Promise<Response<UserSignInResponse>>;
}

export class UserSignInService implements IUserSignInService {
  async userSignIn(
    userSignIn: UserSignIn
  ): Promise<Response<UserSignInResponse>> {
    const resp = await fetch(Config.ContinenteCredentials.mobileUserSignInUrl, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userSignIn),
    });
    if (resp.ok) {
      return { kind: "success", value: await resp.json() };
    } else if (resp.status === 400) {
      const error = await resp.json();

      return {
        kind: "error",
        code: error.code,
        message: error.message,
      };
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }
}

export const userSignInService = new UserSignInService();
