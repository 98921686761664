import React from "react";

type toastElementProps = {
  id: number;
  type: string;
  message: string;
};

type Props = {
  toastList: toastElementProps[];
};

export default function Toast({ ...props }: Props) {
  return (
    <>
      {props.toastList.map((toast, i) => (
        <div key={i} className={`notification-container ${toast.type}`}>
          <label className={`notification-text ${toast.type}`} htmlFor="">
            {toast.message}
          </label>
        </div>
      ))}
    </>
  );
}
