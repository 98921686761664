import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { authorizationService } from "../../services/continente-credentials/common/AuthorizationService";

import LoadingLogo from "../../components/continente-credentials/loading-logo/loading-logo";
import { useAuthorizeSSOQueryParams } from "../../hooks/continente-credentials/useAuthorizeSSOQueryParams";
import { AuthorizeSSOQueryParams } from "../../models/continente-credentials/common/AuthorizeSSOQueryParams";

import SendMessage from "../../utils/send-message/send-message";

export default function AuthorizeSSO() {
  const history = useHistory();
  const authorizeQueryParams: AuthorizeSSOQueryParams =
    useAuthorizeSSOQueryParams();

  useEffect(() => {
    const initialize = async () => {
      const resp = await authorizationService.authorizeSSO({
        clientId: authorizeQueryParams.clientId || "",
        sessionId: authorizeQueryParams.sessionId || "",
        ssoToken: authorizeQueryParams.ssoToken || "",
      });

      switch (resp.kind) {
        case "success": {
          if (resp.value.nextStep.includes("dead-end")) {
            const resp = await authorizationService.authorize({
              clientId: authorizeQueryParams.clientId || "",
            });

            switch (resp.kind) {
              case "success":
                SendMessage({
                  type: "success",
                  authorization_code: resp.value.authorizationCode,
                  code_verifier: resp.value.codeVerifier,
                });
                break;
              case "redirect":
                window.location.href = resp.url;
                break;
            }
            break;
          } else {
            history.push(resp.value.nextStep.substring(4), resp.value);
          }
          break;
        }
        case "redirect":
          window.location.href = resp.url;
          break;
      }
    };
    initialize();
  }, [
    authorizeQueryParams.clientId,
    authorizeQueryParams.ssoToken,
    authorizeQueryParams.sessionId,
    history,
  ]);

  return (
    <>
      <LoadingLogo />
    </>
  );
}
