import React, { Component } from "react";
import { Container } from "reactstrap";
import "simplebar/dist/simplebar.min.css";
import "./../../../css/default.css";

// export default class Layout extends Component {
//   render() {
//     return (
//       <div>
//         <Container>{this.props.children}</Container>
//       </div>
//     );
//   }
// }
interface ILayoutProps {
  className?: string;
}

export default class Layout extends Component<ILayoutProps> {
  render() {
    return (
      <div>
        {this.props.className !== null && this.props.className !== undefined && (
          <Container>
            <div className={`${this.props.className}`}>
              {this.props.children}
            </div>
          </Container>
        )}
        {this.props.className === null ||
          (this.props.className === undefined && (
            <Container>{this.props.children}</Container>
          ))}
      </div>
    );
  }
}
