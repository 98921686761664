import React, { useEffect, useState, ChangeEvent, useContext } from "react";
import { useForm, SubmitHandler, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";

import ErrorMessage from "../../../../components/continente-credentials/error-message/error-message";
import MantraButton from "../../../../components/continente-credentials/mantra-button/MantraButton";
import CredentialsFooter from "../../../../components/continente-credentials/footer/Footer";
import CredentialsHeader from "../../../../components/continente-credentials/header/Header";
import CredentialsForm from "../../../../components/continente-credentials/Form/Form";
import CredentialsInput from "../../../../components/continente-credentials/input/Input";
import TermsAndConditionsCheckbox from "../../../../components/continente-credentials/terms-and-conditions-checkbox/TermsAndConditionsCheckbox";

import { UserSignInResponse } from "../../../../models/continente-credentials/mobile/UserSignInResponse";
import { ValidatePassword } from "../../../../models/continente-credentials/mobile/ValidatePassword";
import { FormError } from "../../../../models/continente-credentials/common/FormError";

import { validatePasswordService } from "../../../../services/continente-credentials/mobile/ValidatePasswordService";
import { emailAssociationService } from "../../../../services/continente-credentials/mobile/EmailAssociationService";

import { divideEmail } from "../../../../utils/EmailDivider";

import { UserContext } from "../../../../contexts/continente-credentials/UserContext";
import { DigitalAssetContext } from "../../../../contexts/continente-credentials/DigitalAssetContext";

type Form = ValidatePassword & FormError;

export default function SetUserPasswordEmailAssociation() {
  const history = useHistory();
  const [labelPasswordVisible, setLabelPasswordVisible] =
    useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [, setPassword] = useState<string>("");
  const { t } = useTranslation();
  const location = useLocation<UserSignInResponse>();
  const email = location.state != null ? location.state.properties.email : "";
  const [acceptedTermsConditions, setAcceptedTermsConditions] =
    useState<boolean>(true);
  const [isCheckBoxChecked, setCheckBoxChecked] = useState<boolean>(false);

  const digitalAssetContext = useContext(DigitalAssetContext);
  const userContext = useContext(UserContext);

  const goToFaq = (anchor?: string) => {
    if (anchor) anchor = "#" + anchor;
    history.push("/continente-credentials/faq" + anchor);
  };
  const ANCHOR_NEED_HELP = "";

  const handleAcceptedTermsConditionsChange = (): void => {
    setAcceptedTermsConditions((acceptedTermsConditions) => {
      return !acceptedTermsConditions;
    });

    setValue("acceptedTermsConditions", acceptedTermsConditions);
    setCheckBoxChecked(acceptedTermsConditions);
  };

  const formState = useForm<Form>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = formState;

  const handlePasswordChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    clearErrors();
    setPassword(value);
    setValue("password", value);
    setLabelPasswordVisible(value.length > 0 ? true : false);
  };

  const skipAssociation = async () => {
    setLoading(true);
    try {
      const response = await emailAssociationService.emailAssociation(
        {
          email: "",
          skipAssociation: true,
        },
        location.state != null ? location.state.nextStep : ""
      );

      switch (response.kind) {
        case "success":
          userContext.updateUser({
            ...userContext.user,
            email: "",
          });
          history.push(response.value.nextStep.substring(4), response.value);
          break;
        default:
          setError("submitError", { message: t("common.unexpected_error") });

          break;
      }

      setLoading(false);
    } catch (e) {
      setError("submitError", { message: t("common.unexpected_error") });
      setLoading(false);
    }
  };

  const passwordRecover = async () => {
    setLoading(true);

    try {
      const response = await validatePasswordService.validatePassword(
        {
          passwordRecover: true,
          password: "",
          acceptedTermsConditions: false,
        },
        location.state != null ? location.state.nextStep : ""
      );

      switch (response.kind) {
        case "success":
          history.push(response.value.nextStep.substring(4), response.value);
          break;

        case "error":
          setError("submitError", { message: t("common.unexpected_error") });
          break;

        default:
          setError("submitError", { message: t("common.unexpected_error") });
          break;
      }

      setLoading(false);
    } catch (e) {
      setError("submitError", { message: t("common.unexpected_error") });
      setLoading(false);
    }
  };

  const onSubmit: SubmitHandler<ValidatePassword> = async (data) => {
    setLoading(true);
    try {
      const response = await validatePasswordService.validatePassword(
        {
          passwordRecover: false,
          password: data.password,
          acceptedTermsConditions: data.acceptedTermsConditions,
        },
        location.state != null ? location.state.nextStep : ""
      );

      switch (response.kind) {
        case "success":
          history.push(response.value.nextStep.substring(4), response.value);
          break;

        case "error":
          if (response.code === "100008") {
            setError("password", {
              message: t("password_definition.password_invalid"),
            });
            break;
          } else if (response.code === "300002") {
            setError("submitError", {
              message: t("common.unexpected_error"),
            });
            break;
          } else if (response.code === "300130") {
            setError("submitError", {
              message: t("common.unexpected_error"),
            });
            break;
          } else {
            setError("submitError", {
              message: t("common.unexpected_error"),
            });
            break;
          }
          break;

        default:
          setError("submitError", { message: t("common.unexpected_error") });
          break;
      }

      setLoading(false);
    } catch (e) {
      setError("submitError", { message: t("common.unexpected_error") });
      setLoading(false);
    }
  };

  useEffect(() => {
    const passwordValidator = (register: UseFormRegister<Form>) =>
      register("password", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
      });
    const contextualInformationValidator = (register: UseFormRegister<Form>) =>
      register("acceptedTermsConditions", {
        required: {
          value: true,
          message: "",
        },
      });
    passwordValidator(register);

    if (location.state.properties.mustAcceptTermsConditions) {
      contextualInformationValidator(register);
    }
  }, [location.state.properties.mustAcceptTermsConditions, register, t]);

  return (
    <>
      <CredentialsForm
        id="SetUserPassword"
        form={formState}
        autoComplete="on"
        onSubmit={(e) => {
          clearErrors();
          handleSubmit(onSubmit)(e);
        }}
      >
        <div className="row justify-content-center">
          <CredentialsHeader
            title={t(
              "login_phoneNumber_email_password_validation.header_title"
            )}
            subtitle={
              t("login_phoneNumber_email_password_validation.header_message") +
              " " +
              divideEmail(email)
            }
            showLoading={loading}
            previousStepBackXTimes={1}
          />
        </div>
        <div className="row d-flex justify-content-center">
          <div className="input-outline-eye">
            <CredentialsInput
              labelText={t(
                "login_phoneNumber_email_password_validation.password_input"
              )}
              isFilled={labelPasswordVisible}
              isError={errors.password ? true : false}
              errorMsg={errors.password ? errors.password.message : ""}
              onChange={handlePasswordChange}
              type={"password"}
            />
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="validate-password-resend-code">
            <span
              className="validate-password-resend-code link"
              onClick={passwordRecover}
            >
              {t(
                "login_phoneNumber_email_password_validation.forgot_password_message1"
              )}{" "}
            </span>
          </div>
        </div>
        {location.state.properties.mustAcceptTermsConditions && (
          <div className="form-check-group-copy">
            <div className="form-check">
              <TermsAndConditionsCheckbox
                onClick={handleAcceptedTermsConditionsChange}
                isError={errors.acceptedTermsConditions ? true : false}
              />
            </div>
          </div>
        )}
        <ErrorMessage
          error={errors.submitError}
          acceptTermsAndConditions={errors.acceptedTermsConditions}
        />
        <div className="row d-flex justify-content-center">
          <MantraButton
            text={t(
              "login_phoneNumber_email_password_validation.button_next_message"
            )}
            type="submit"
          />
        </div>
        <div className="row d-flex justify-content-center">
          {digitalAssetContext.digitalAsset
            .requiredEmailAssociation ? undefined : (
            <MantraButton
              text={t("email_association.jump_next_button_message")}
              type="button"
              color="secondary"
              onClick={skipAssociation}
            />
          )}
        </div>
        <div className="contextual-information-header text-align-center">
          <span
            className="verification-resend-code link"
            onClick={() => goToFaq(ANCHOR_NEED_HELP)}
          >
            {t("common.need_help")}
          </span>
        </div>
        <div className="row d-flex flex-column">
          <CredentialsFooter />
        </div>
      </CredentialsForm>
    </>
  );
}
