import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

type FlagProps = {
  countryCode: string;
};

type FlagOptionProps = {
  onClick: (country: string) => void;
  countryCode: string;
  label: string;
  phoneNumberCode: string;
  selected: boolean;
};

export function Flag(props: FlagProps) {
  return <div className={`fflag ff-md fflag-${props.countryCode} ff-app`} />;
}

export function FlagOption(props: FlagOptionProps) {
  return (
    <div
      className="flag-option"
      onClick={() => props.onClick(props.countryCode)}
    >
      <Flag countryCode={props.countryCode} />
      <span className={`country-label ${props.selected ? "selected" : ""}`}>
        {props.label}
      </span>
      <span className="country-code">(+{props.phoneNumberCode})</span>
      {props.selected && <FontAwesomeIcon icon={faCheck} />}
    </div>
  );
}
