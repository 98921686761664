import { Config } from "../config/Config";
import { Response } from "../models/Response";

const userUrl = Config.apiUrl + "users/me/verifylogin";

export class UserService {
  async get(): Promise<Response<void>> {
    const resp = await fetch(userUrl);

    if (resp.ok) {
      return { kind: "success", value: undefined };
    } else if (resp.status === 401) {
      return { kind: "unauthorized" };
    } else {
      const body = await resp.json();

      return { kind: "error", code: body.code };
    }
  }
}

export const userService = new UserService();
