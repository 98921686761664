import React from "react";
import { useTranslation } from "react-i18next";
import back from "../../../assets/images/back.svg";
import Loading from "../../continente-credentials/loading/Loading";
import { useHistory } from "react-router-dom";

type HeaderInputProps = {
  title?: string;
  subtitle?: string;
  isTextAlignCenter?: boolean;
  showLoading?: boolean;
  previousStep?: boolean;
  previousStepMultiple?: boolean;
  previousStepTriple?: boolean;
  previousStepBackXTimes?: number;
  skipStep?: Function;
  helpLink?: boolean;
  backClickToPushPath?: string;
};

export default function Header({
  isTextAlignCenter = false,
  showLoading = false,
  ...props
}: HeaderInputProps) {
  const { t } = useTranslation();
  const history = useHistory();

  const clickBackXTimes = (numBack: number) => {
    if (numBack >= history.length) numBack = history.length - 1;

    const numBackMinus = -Math.abs(numBack);
    history.go(numBackMinus);
  };

  const clickSkip = () => {
    if (props.skipStep != null) {
      props.skipStep();
    }
  };

  const historyPush = (path: string) => {
    history.push(path);
  };

  return (
    <>
      <div className="loading-bar">{showLoading && <Loading />}</div>
      <div className="header__container">
        <div>
          {props.backClickToPushPath && (
            <img
              src={back}
              alt="back"
              onClick={() => {
                historyPush(props.backClickToPushPath as string);
              }}
              className="back-icon"
            />
          )}

          {props.previousStepBackXTimes && (
            <img
              src={back}
              alt="back"
              onClick={() =>
                clickBackXTimes(props.previousStepBackXTimes as number)
              }
              className="back-icon"
            />
          )}
        </div>

        <div className="header__right_container">
          {props.skipStep && (
            <span onClick={clickSkip} className="skip-span">
              {" "}
              {t("common.skip_message")}{" "}
            </span>
          )}
        </div>
      </div>
      <div>
        <div
          className={`welcome ${isTextAlignCenter ? "text-align-center" : ""}`}
        >
          <p>{props.title}</p>
        </div>

        {props.subtitle && (
          <div
            className={`contextual-information-header ${
              isTextAlignCenter ? "text-align-center" : ""
            }`}
          >
            <div
              className="text subtitle-text"
              dangerouslySetInnerHTML={{ __html: props.subtitle }}
            />
          </div>
        )}
      </div>
    </>
  );
}
