import React from "react";

type CheckboxProps = {
  isError: boolean;
  onClick: () => void;
  label: React.ReactNode;
};

const defaultProps = {
  isError: false,
  onClick: (): void => void 0,
};

function Checkbox(props: CheckboxProps & typeof defaultProps) {
  return (
    <div className="d-inline-flex">
      <input
        id="checkbox"
        className={
          props.isError
            ? "form-check-input square error"
            : "form-check-input square"
        }
        type="checkbox"
        onClick={props.onClick}
      />
      <label style={{ marginLeft: 5 }} htmlFor="checkbox">
        {props.label}
      </label>
    </div>
  );
}

Checkbox.defaultProps = defaultProps;

export default Checkbox;
