import React from "react";

import { User } from "../../models/continente-credentials/common/User";

export const defaultUser: User = {
  email: "",
  phoneNumber: "",
  countryCode: "",
  phoneNumberCode: "",
  primaryContact: "",
  skippedPhoneNumberAssociation: false,
};

export const UserContext = React.createContext({
  user: defaultUser,
  updateUser: (user: User) => {},
});
