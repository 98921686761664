import React from "react";
import continenteOnlineCard from "../../../assets/images/img-novo-login.png";
import { useTranslation } from "react-i18next";

type WhatIsAcountMergeProps = {
  isPage?: boolean;
};

export default function WhatIsAccountMerge({ isPage = false }: WhatIsAcountMergeProps) {
  const { t } = useTranslation();

  return (
    <>
      <div className="row what-account-merge-title">
        <p className="header">{t("merge_accounts_faq.header_title")}</p>
      </div>
      <div className="row d-flex justify-content-center">
        <img src={continenteOnlineCard} className="img" alt="" />
      </div>
      <div className="row what-is-account-merge-contextual-information">
        <p>{t("merge_accounts_faq.message")}</p>
      </div>
    </>
  );
}
