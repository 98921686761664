import { PinValidationResponse } from "../../../models/continente-credentials/mobile/PinValidationResponse";
import { Response } from "../../../models/continente-credentials/common/Response";
import { PinRecover } from "../../../models/continente-credentials/mobile/PinRecover";
import { PinRecoverResponse } from "../../../models/continente-credentials/mobile/PinRecoverResponse";

export interface IPinRecoverService {
  pinRecover(
    pinRecover: PinRecover,
    pinRecoverUrl: string
  ): Promise<Response<PinRecoverResponse>>;
}

export class PinRecoverService implements IPinRecoverService {
  async pinRecover(
    pinRecover: PinRecover,
    pinRecoverUrl: string
  ): Promise<Response<PinValidationResponse>> {
    const resp = await fetch(pinRecoverUrl, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(pinRecover),
    });
    if (resp.ok) {
      return { kind: "success", value: await resp.json() };
    } else if (resp.status === 400) {
      const error = await resp.json();

      const returnedError = error.find((c: any) => c.Code != null);
      return {
        kind: "error",
        code: returnedError.Code ? returnedError.Code : 0,
        message: returnedError.message ? returnedError.message : "",
      };
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }
}

export const pinRecoverService = new PinRecoverService();
