import { PhoneNumberDuplicated } from "../../../models/site-credentials/mobile/PhoneNumberDuplicated";
import { EmptyResponse } from "../../../models/site-credentials/mobile/EmptyResponse";
import { Response } from "../../../models/site-credentials/common/Response";

export interface IPhoneNumberDuplicatedService {
  phoneNumberDuplicated(
    phoneNumberDuplicated: PhoneNumberDuplicated,
    phoneNumberDuplicatedUrl: string
  ): Promise<Response<EmptyResponse>>;
}

export class PhoneNumberDuplicatedService
  implements IPhoneNumberDuplicatedService
{
  async phoneNumberDuplicated(
    phoneNumberDuplicated: PhoneNumberDuplicated,
    phoneNumberDuplicatedUrl: string
  ): Promise<Response<EmptyResponse>> {
    const resp = await fetch(phoneNumberDuplicatedUrl, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(phoneNumberDuplicated),
    });
    if (resp.ok) {
      return { kind: "success", value: await resp.json() };
    } else if (resp.status === 400) {
      const error = await resp.json();

      const returnedError = error.find((c: any) => c.Code != null);
      return {
        kind: "error",
        code: returnedError.Code ? returnedError.Code : 0,
        message: returnedError.message ? returnedError.message : "",
      };
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }
}

export const phoneNumberDuplicatedService = new PhoneNumberDuplicatedService();
