import { Response } from "../models/site-credentials/common/Response";
import { GetSiteCredentialsUrlResponse } from "../models/test-page/GetSiteCredentialsUrlResponse";
import { Config } from "../config/Config";

const siteCredentialsUrl = Config.apiUrl + "tests/getSiteCredentialsUrlTest";

export interface ITestPageService {
  getUserInfo(id: string): Promise<Response<string>>;
  getSiteCredentialsUrl(
    authorizationCode: string,
    codeVerifier: string,
    clientId: string
  ): Promise<Response<GetSiteCredentialsUrlResponse>>;
}

export class TestPageService implements ITestPageService {
  async getUserInfo(id: string): Promise<Response<string>> {
    const encodedValue = encodeURIComponent(id);
    const resp = await fetch(
      `${Config.apiUrl}tests/getUserInfo?id=${encodedValue}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      }
    );

    if (resp.ok) {
      return { kind: "success", value: await resp.json() };
    } else if (resp.status === 400) {
      const error = await resp.json();

      return {
        kind: "error",
        code: error[0].Code,
        message: error[0].Message,
      };
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }

  async getSiteCredentialsUrl(
    authorizationCode: string,
    codeVerifier: string,
    clientId: string
  ): Promise<Response<GetSiteCredentialsUrlResponse>> {
    const url = new URL(siteCredentialsUrl);
    url.searchParams.append("authorizationCode", authorizationCode);
    url.searchParams.append("codeVerifier", codeVerifier);
    url.searchParams.append("clientId", clientId);

    const resp = await fetch(url.toString());

    try {
      if (resp.ok) {
        return { kind: "success", value: await resp.json() };
      } else {
        return {
          kind: "unexpected_error",
        };
      }
    } catch (e) {
      return {
        kind: "unexpected_error",
      };
    }
  }
}

export const testpageService = new TestPageService();
