import React from "react";
import Header from "../../components/site-credentials/header/Header";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import timeOutError from "../../assets/images/pictograma-ampulheta-off.png";

export default function TimeOutError() {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <form id="TimeOutError" autoComplete="off">
        <div className="row justify-content-center">
          <Header
            title={t("timeout_error.header_title")}
            subtitle={t("timeout_error.subtitle")}
            isTextAlignCenter
          />
        </div>
      </form>
      <div className="row justify-content-center">
        <img src={timeOutError} className="contact-center-icon" alt="" />
      </div>
    </>
  );
}
