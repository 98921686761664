import { ValidatePassword } from "../../../models/site-credentials/mobile/ValidatePassword";
import { ValidatePasswordResponse } from "../../../models/site-credentials/mobile/ValidatePasswordResponse";
import { Response } from "../../../models/site-credentials/common/Response";

export interface IValidatePasswordService {
  validatePassword(
    validatePassword: ValidatePassword,
    validatePasswordUrl: string
  ): Promise<Response<ValidatePasswordResponse>>;
}
export class ValidatePasswordService implements IValidatePasswordService {
  async validatePassword(
    validatePassword: ValidatePassword,
    validatePasswordUrl: string
  ): Promise<Response<ValidatePasswordResponse>> {
    const resp = await fetch(validatePasswordUrl, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(validatePassword),
    });

    if (resp.ok) {
      return { kind: "success", value: await resp.json() };
    } else if (resp.status === 400) {
      const error = await resp.json();

      if (error) {
        const returnedError = error.find((c: any) => c.Code != null);
        return {
          kind: "error",
          code: returnedError.Code ? returnedError.Code : 0,
          message: returnedError.message ? returnedError.message : "",
        };
      } else {
        return {
          kind: "unexpected_error",
        };
      }
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }
}

export const validatePasswordService = new ValidatePasswordService();
