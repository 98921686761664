import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler, UseFormRegister } from "react-hook-form";

import ErrorMessage from "../../../components/continente-credentials/error-message/error-message";
import CredentialsOtpInput from "../../../components/site-credentials/otp-input/OtpInput";
import Footer from "../../../components/site-credentials/footer/Footer";
import Header from "../../../components/site-credentials/header/Header";

import { FormError } from "../../../models/site-credentials/common/FormError";
import { OtpValidation } from "../../../models/site-credentials/mobile/OtpValidation";
import { LoyaltyCardOtpOptionsResponse } from "../../../models/site-credentials/loyaltycard/LoyaltyCardOtpOptionsResponse";

import { cardAssociationService } from "../../../services/site-credentials/mobile/CardAssociationService";

type Form = OtpValidation & FormError;

export default function LoyaltyCardOtpValidation() {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const location = useLocation<LoyaltyCardOtpOptionsResponse>();
  const contact =
    location.state != null ? location.state.properties.contact.contact : "";
  const jwtToken =
    location.state != null ? location.state.properties.jwtToken : "";

  const goToFaq = (anchor?: string) => {
    if (anchor) anchor = "#" + anchor;
    history.push("/site-credentials/faq" + anchor);
  };
  const ANCHOR_NEED_HELP = "";

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<Form>({ mode: "onSubmit", reValidateMode: "onSubmit" });

  const handleOTPChange = (value: string) => {
    clearErrors();
    setValue("code", value.trim());
  };

  const onSubmit: SubmitHandler<OtpValidation> = async (data) => {
    setLoading(true);
    try {
      const response = await cardAssociationService.verifyOtp(
        data.code,
        location.state != null ? location.state.nextStep : ""
      );

      switch (response.kind) {
        case "success":
          history.push(response.value.nextStep.substring(4), {
            ...response.value,
            responseCode: "100003",
          });
          break;
        case "error":
          if (response.code === "100004") {
            setError("code", {
              message: t("otp_validation.invalid_code"),
            });
            break;
          } else if (response.code === "100015") {
            setError("submitError", {
              message: t("otp_validation.invalid_nonce"),
            });
            break;
          } else if (response.code === "100016") {
            setError("submitError", {
              message: t("otp_validation.invalid_nonce"),
            });
            break;
          }
          setError("submitError", { message: t("common.unexpected_error") });
          break;

        default:
          setError("submitError", { message: t("common.unexpected_error") });

          break;
      }

      setLoading(false);
    } catch (e) {
      setError("submitError", { message: t("common.unexpected_error") });
      setLoading(false);
    }
  };

  useEffect(() => {
    const otpValidator = (register: UseFormRegister<Form>) =>
      register("code", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
        pattern: {
          value: /^[0-9]*$/,
          message: t("otp_validation.code_must_be_numeric"),
        },
      });
    otpValidator(register);
  }, [register, t]);

  const headerMessage =
    location.state.properties.contact.type === "MobilePhone"
      ? `${t(
          "card_association_otp_validation.header_message_phone"
        )} ${contact}`
      : `${t(
          "card_association_otp_validation.header_message_email"
        )} ${contact}`;

  return (
    <>
      <form
        id="VerifyOtp"
        autoComplete="off"
        onSubmit={(e) => {
          clearErrors();
          handleSubmit(onSubmit)(e);
        }}
      >
        <div className="row justify-content-center">
          <Header
            title={t("card_association_otp_validation.header_title")}
            subtitle={headerMessage}
            showLoading={loading}
            previousStepBackXTimes={1}
          />
        </div>
        <div className="row justify-content-center">
          <CredentialsOtpInput
            isError={errors.code ? true : false}
            errorMsg={errors.code ? errors.code.message : ""}
            onChange={handleOTPChange}
            jwtToken={jwtToken}
            isLoyalty
            onResendClick={setLoading}
          />
        </div>
        <ErrorMessage error={errors.submitError} isHtml={true} />
        <div className="sc-contextual-information-header text-align-center">
          <span
            className="verification-resend-code link"
            onClick={() => goToFaq(ANCHOR_NEED_HELP)}
          >
            {t("common.need_help")}
          </span>
        </div>
        <div className="row d-flex flex-column">
          <Footer />
        </div>
      </form>
    </>
  );
}
