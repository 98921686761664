import React from "react";
import { useFormContext } from "react-hook-form";

type InputProps = {
  text: string;
  type: "button" | "submit" | "reset";
  size?: "normal" | "large";
  isDisabled?: boolean;
  color?: "primary" | "secondary";
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
};

export default function MantraButton({
  text,
  type,
  size = "normal",
  isDisabled = false,
  color = "primary",
  onClick,
}: InputProps) {
  const form = useFormContext();

  const isSubmitting = form?.formState?.isSubmitting;
  const disabled = isDisabled || (type === "submit" && isSubmitting);

  return (
    <button
      disabled={disabled}
      type={type}
      className={"mt-4 mantra-button mantra-" + color + "-button"}
      onClick={onClick}
    >
      {!disabled ? (
        <div className={"mantra-" + color + "-button-text"}>{text}</div>
      ) : (
        <div className="mantra-button-text-disabled">{text}</div>
      )}
    </button>
  );
}
