import i18n from "i18next"; // assuming you got an i18n instance configured and exported like in the samples - else just import i18n from 'i18next';

export class ErrorService {
  errorMessage(errorCode: string) {
    const t = i18n.t;
    let errorMessage: string = t("common.unexpected_error");

    switch (errorCode) {
      case "100020":
        errorMessage = t("card_validation.invalid_loyaltycard");
        break;

      case "100014":
        errorMessage = t("common.unexpected_error");
        break;

      case "100008":
        errorMessage = t("password_recover.password_invalid");
        break;

      default:
        break;
    }
    return errorMessage;
  }
}

export const errorService = new ErrorService();
