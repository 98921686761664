import { useLocation } from "react-router";

import { AuthorizeQueryParams } from "../models/AuthorizeQueryParams";

export const useAuthorizeQueryParams = (): AuthorizeQueryParams => {
  const urlSearchParams = new URLSearchParams(useLocation().search);

  return {
    client_id: urlSearchParams.get("client_id"),
    scope: urlSearchParams.get("scope"),
    response_type: urlSearchParams.get("response_type"),
    redirect_uri: urlSearchParams.get("redirect_uri"),
    state: urlSearchParams.get("state"),
  } as AuthorizeQueryParams;
};
