export function verifySpecialChars(value: string) {
  if (/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value)) return true;

  return false;
}

export function verifyLowerCase(value: string) {
  if (/(?=.*[a-z])/.test(value)) return true;

  return false;
}

export function verifyUpperCase(value: string) {
  if (/(?=.*[A-Z])/.test(value)) return true;

  return false;
}

export function verifyNumber(value: string) {
  if (/\d/.test(value)) return true;

  return false;
}

export function verifyMinChar(value: string) {
  if (value.length < 8) return false;

  return true;
}
