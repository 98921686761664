import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { authorizeSiteCredentialsService } from "../../services/AuthorizationService";
import { useAuthorizeQueryParams } from "../../hooks/site-credentials/useAuthorizeQueryParams";
import LoadingLogo from "../../components/continente-credentials/loading-logo/loading-logo";

import { DigitalAsset } from "../../models/site-credentials/common/DigitalAsset";
import { DigitalAssetContext } from "../../contexts/site-credentials/DigitalAssetContext";
import { digitalAssetService } from "../../services/site-credentials/common/DigitalAssetService";

export default function Authorization() {
  const history = useHistory();
  const authorizeQueryParams = useAuthorizeQueryParams();

  const digitalAssetContext = useContext(DigitalAssetContext);

  useEffect(() => {
    const authorize = async () => {
      const resp = await authorizeSiteCredentialsService.authorize({
        clientId: authorizeQueryParams.clientId || "",
        token: authorizeQueryParams.token || "",
      });

      if (resp.kind === "success") {
        const digitalAssetResult = await digitalAssetService.getDigitalAsset(
          authorizeQueryParams.clientId || ""
        );

        if (digitalAssetResult.kind === "success") {
          digitalAssetContext.updateDigitalAsset(
            digitalAssetResult.value as DigitalAsset
          );
        }

        history.push(resp.value.url, { nextStep: "/api" + resp.value.url });
      }
    };

    authorize();
  }, [
    authorizeQueryParams.clientId,
    authorizeQueryParams.token,
    digitalAssetContext,
    history,
  ]);

  return (
    <>
      <LoadingLogo />
    </>
  );
}
