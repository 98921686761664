import React from "react";
import { ErrorMessage } from "@hookform/error-message";

type ErrorInputProps = {
  name: string;
  errors: any;
  children: any;
};

export default function ErrorInput({
  name,
  errors,
  children,
}: ErrorInputProps) {
  return (
    <div className="error-input">
      {children}
      <div className="error-message">
        <ErrorMessage errors={errors} name={name} />
      </div>
    </div>
  );
}
