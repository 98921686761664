import React, { useEffect, useState, useContext } from "react";
import CredentialsHeader from "../../components/continente-credentials/header/Header";
import SendMessage from "../../utils/send-message/send-message";
import { authorizationService } from "../../services/continente-credentials/common/AuthorizationService";
import { useAuthorizeQueryParams } from "../../hooks/continente-credentials/useAuthorizeQueryParams";
import { testpageService } from "../../services/TestPageService";
import useGetDigitalAssetId from "../../hooks/useGetDigitalAssetId";
import LoadingLogo from "../../components/continente-credentials/loading-logo/loading-logo";
import { DigitalAssetContext } from "../../contexts/continente-credentials/DigitalAssetContext";

export default function SignIn() {
  const authorizeQueryParams = useAuthorizeQueryParams();
  const [authorizationCode, setAuthorizationCode] = useState("");
  const [codeVerifier, setCodeVerifier] = useState("");
  const digitalAssetId = useGetDigitalAssetId();

  const digitalAssetContext = useContext(DigitalAssetContext);

  useEffect(() => {
    const authorize = async () => {
      const resp = await authorizationService.authorize({
        clientId: authorizeQueryParams.clientId || "",
      });

      switch (resp.kind) {
        case "success":
          setAuthorizationCode(resp.value.authorizationCode);
          setCodeVerifier(resp.value.codeVerifier);

          SendMessage({
            type: "success",
            authorization_code: resp.value.authorizationCode,
            code_verifier: resp.value.codeVerifier,
          });
          break;
        case "redirect":
          window.location.href = resp.url;
          break;
      }
    };

    authorize();
  }, [authorizeQueryParams.clientId]);

  const handleOpenSiteCredentialsClick = () => {
    if (authorizationCode === "" || codeVerifier === "") {
      alert(
        "Authorization Code ou Code Verifier não estavam prontos. Tente Novamente"
      );
    } else {
      const siteCredentialsUrl = async () => {
        const response = await testpageService.getSiteCredentialsUrl(
          authorizationCode,
          codeVerifier,
          digitalAssetId || ""
        );

        switch (response.kind) {
          case "success": {
            window.location.href =
              "/site-credentials/authorize?clientId=" +
              (digitalAssetId || "") +
              "&token=" +
              (response.value.siteCredenciaisToken || "");
            break;
          }
          case "error":
            alert("error");
            break;
        }
      };
      siteCredentialsUrl();
    }
  };

  return (
    <>
      {digitalAssetContext.digitalAsset.showSiteCredentialsLink && (
        <div className="row justify-content-center">
          <span
            onClick={handleOpenSiteCredentialsClick}
            className="button-as-link"
            style={{
              zIndex: 10000,
              position: "absolute",
              marginTop: "100px",
            }}
          >
            Abrir Site Credenciais
          </span>
        </div>
      )}
      <LoadingLogo />
    </>
  );
}
