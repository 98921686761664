import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import CredentialsHeader from "../../../components/continente-credentials/header/Header";
import CredentialsFooter from "../../../components/continente-credentials/footer/Footer";
import { useTranslation } from "react-i18next";
import { LoyaltyCardAssociationResponse } from "../../../models/continente-credentials/loyaltycard/LoyaltyCardAssociationResponse";
import { useLocation } from "react-router-dom";
import MantraButton from "../../../components/continente-credentials/mantra-button/MantraButton";

export default function LoyaltyCcvCommunication() {
  const location = useLocation<LoyaltyCardAssociationResponse>();
  const { t } = useTranslation();
  const [, setLoading] = useState(false);
  const goToFaq = (anchor?: string) => {
    if (anchor) anchor = "#" + anchor;
    history.push("/continente-credentials/faq" + anchor);
  };
  const ANCHOR_NEED_HELP = "";
  const history = useHistory();
  const handleSubmit = async () => {
    setLoading(true);
    historyPush(location.state.properties.deadEnd.substring(4));
    setLoading(false);
  };

  const historyPush = (path: string) => {
    history.push(path);
  };

  window.addEventListener("popstate", (event) => {
    historyPush("/");
  });

  return (
    <>
      <div className="row justify-content-center">
        <CredentialsHeader title={t("loyalty_ccv_comunication.header_title")} />
      </div>
      <div className="row d-flex flex-column">
        <CredentialsFooter
          contextualInformationVisible
          contextualInformationLink={false}
          footerAppsWebsitesIconsVisible
          message={t("loyalty_ccv_comunication.header_message")}
        />
      </div>
      {/* <div
        className="contextual-information-copy-2"
        style={{ visibility: "hidden" }}
      >
        {t("loyalty_ccv_comunication.ccv_message_1")}{" "}
        {location?.state?.properties?.loyaltyCardNumber}{" "}
        {t("loyalty_ccv_comunication.ccv_message_2")}
      </div> */}
      <div className="row d-flex justify-content-center">
        <MantraButton
          text={t("loyalty_ccv_comunication.button_close_message")}
          type="button"
          size="large"
          onClick={handleSubmit}
        />
      </div>
      <div className="contextual-information-header text-align-center">
        <span
          className="verification-resend-code link"
          onClick={() => goToFaq(ANCHOR_NEED_HELP)}
        >
          {t("common.need_help")}
        </span>
      </div>
    </>
  );
}
