import React from "react";
import { useLocation } from "react-router-dom";
import mail from "../../../../assets/images/img-email-validado.svg";
import CredentialsFooter from "../../../../components/continente-credentials/footer/Footer";
import CredentialsHeader from "../../../../components/continente-credentials/header/Header";
import MantraButton from "../../../../components/continente-credentials/mantra-button/MantraButton";
import { SetPasswordResponse } from "../../../../models/continente-credentials/mobile/SetPasswordResponse";
import { useTranslation } from "react-i18next";

export default function EmailValidated() {
  const { t } = useTranslation();
  const location = useLocation<SetPasswordResponse>();
  const email = location.state != null ? location.state.properties.email : "";

  return (
    <>
      <div className="row justify-content-center">
        <CredentialsHeader
          title={t("email_validated.header_title")}
          subtitle={
            t("email_validated.header_message1") +
            " " +
            email +
            " " +
            t("email_validated.header_message2")
          }
          previousStepBackXTimes={1}
        />
      </div>
      <div className="row d-flex flex-column">
        <img src={mail} className="mail-validated" alt="" />
      </div>
      <div className="row d-flex justify-content-center">
        <MantraButton
          text={t("email_association.button_next_message")}
          type="submit"
        />
      </div>
      <div className="row d-flex flex-column">
        <CredentialsFooter />
      </div>
    </>
  );
}
