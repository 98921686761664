import React from "react";
import { useTranslation } from "react-i18next";

type LoyaltyCardAdvantagesProps = {
  isPage?: boolean;
};

export default function LoyaltyCardAdvantages({
  isPage = false,
}: LoyaltyCardAdvantagesProps) {
  const { t } = useTranslation();

  return (
    <>
      <div className="row loyalty-card-advantages-title">
        <p>{t("loyalty_card_advantages.header_title")}</p>
      </div>
      <div className="row loyalty-card-advantages-information">
        <p>{t("loyalty_card_advantages.message")}</p>
      </div>
    </>
  );
}
