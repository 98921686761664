import React, { useEffect, useState, useCallback, useContext } from "react";
import { useForm, SubmitHandler, UseFormRegister } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";

import CredentialsFooter from "../../components/continente-credentials/footer/Footer";
import CredentialsForm from "../../components/continente-credentials/Form/Form";
import CredentialsHeader from "../../components/continente-credentials/header/Header";
import MantraButton from "../../components/continente-credentials/mantra-button/MantraButton";
import UsernameInput from "../../components/continente-credentials/username-input/UsernameInput";

import { UserSignIn } from "../../models/continente-credentials/mobile/UserSignIn";
import { FormError } from "../../models/continente-credentials/common/FormError";
import { UserSignInResponse } from "../../models/continente-credentials/mobile/UserSignInResponse";

import { userSignInService } from "../../services/continente-credentials/mobile/UserSignInService";
import { digitalAssetService } from "../../services/continente-credentials/common/DigitalAssetService";
import { useTranslation } from "react-i18next";
import useGetDigitalAssetId from "../../hooks/useGetDigitalAssetId";
import { DigitalAsset } from "../../models/continente-credentials/common/DigitalAsset";
import { DigitalAssetContext } from "../../contexts/continente-credentials/DigitalAssetContext";
import { LangContext } from "../../contexts/continente-credentials/LangContext";
import { UserContext } from "../../contexts/continente-credentials/UserContext";

import { validatePortuguesePhoneNumber } from "../../utils/validatePortuguesePhoneNumber";
import ErrorMessage from "../../components/continente-credentials/error-message/error-message";
import {
  useGoogleRecaptcha,
  getRecaptchaToken,
  siteKey,
} from "../../hooks/continente-credentials/useGoogleRecaptcha";

type Form = UserSignIn & FormError;

export default function SignIn() {
  const history = useHistory();

  const location = useLocation<UserSignInResponse>();

  useGoogleRecaptcha(location.state);

  const [labelEmailOrPhoneNumberVisible, setLabelEmailOrPhoneNumberVisible] =
    useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const { t } = useTranslation();
  const digitalAssetId = useGetDigitalAssetId();
  const digitalAssetContext = useContext(DigitalAssetContext);
  const langContext = useContext(LangContext);
  const userContext = useContext(UserContext);
  const goToFaq = (anchor?: string) => {
    if (anchor) anchor = "#" + anchor;
    history.push("/continente-credentials/faq" + anchor);
  };
  const ANCHOR_NEED_HELP = "";
  const initialize = useCallback(async (digitalAssetId) => {
    setLoading(true);
    const query = new URLSearchParams(window.location.search);
    const language = query.get("language") || "pt";
    langContext.updateLang({
      ...langContext.lang,
      code: language,
    });

    const digitalAssetResult = await digitalAssetService.getDigitalAsset(
      digitalAssetId || ""
    );

    setLoading(false);

    if (digitalAssetResult.kind === "success") {
      digitalAssetContext.updateDigitalAsset(
        digitalAssetResult.value as DigitalAsset
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initialize(digitalAssetId);
  }, [initialize, digitalAssetId]);

  const updateUserContext = (username: string) => {
    const isEmail = isNaN(Number(username.substring(1)));
    if (isEmail) {
      userContext.updateUser({
        ...userContext.user,
        phoneNumber: "",
        email: username,
        primaryContact: "email",
      });
    } else {
      userContext.updateUser({
        ...userContext.user,
        email: "",
        phoneNumber: username,
        phoneCountryCode: countryCode,
        phoneNumberCode: countryCode,
        primaryContact: "phoneNumber",
      });
    }
  };

  const formState = useForm<Form>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = formState;

  const handleEmailOrPhoneNumberChange = useCallback(
    (input: string, phoneNumberCode: string) => {
      clearErrors();
      const value = isNumber(input)
        ? `${
            userContext.user.phoneNumberCode !== ""
              ? userContext.user.phoneNumberCode
              : phoneNumberCode
          }${input}`
        : input;
      const cleanedValue = value.trim();

      setPhoneNumber(input.trim());
      setCountryCode(
        userContext.user.phoneNumberCode !== ""
          ? userContext.user.phoneNumberCode
          : phoneNumberCode
      );
      setValue("username", cleanedValue);
      setLabelEmailOrPhoneNumberVisible(cleanedValue.length > 0 ? true : false);
    },
    [clearErrors, setValue, userContext]
  );

  useEffect(() => {
    if (userContext.user.primaryContact === "phoneNumber") {
      handleEmailOrPhoneNumberChange(
        userContext.user.phoneNumber,
        userContext.user.phoneNumberCode
      );

      //clear userContext.user.phoneNumberCode
      userContext.user.phoneNumberCode = "";
    } else {
      handleEmailOrPhoneNumberChange(userContext.user.email, "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function isNumber(input: string) {
    return !isNaN(Number(input)) && input.length > 2;
  }

  const onSubmit: SubmitHandler<UserSignIn> = async (data) => {
    setLoading(true);

    try {
      const response = await userSignInService.userSignIn({
        username: data.username,
        clientId: digitalAssetId || "",
        phoneNumberCountryCode: isNumber(data.username.replace("+", ""))
          ? countryCode
          : undefined,
        simplePhoneNumber: isNumber(data.username.replace("+", ""))
          ? phoneNumber
          : undefined,
        recaptchaToken: getRecaptchaToken(location.state),
      });

      switch (response.kind) {
        case "success":
          isNumber(data.username.replace("+", ""))
            ? updateUserContext(phoneNumber)
            : updateUserContext(data.username);
          history.push(response.value.nextStep.substring(4), response.value);

          break;
        case "error":
          if (response.code === "100015") {
            setError("submitError", {
              message: t("otp_validation.invalid_nonce"),
            });
            break;
          } else if (response.code === "100016") {
            setError("submitError", {
              message: t("otp_validation.invalid_nonce"),
            });
            break;
          }
          setError("submitError", { message: t("common.unexpected_error") });
          break;
        default:
          setError("submitError", { message: t("common.unexpected_error") });

          break;
      }

      setLoading(false);
    } catch (e) {
      setError("submitError", { message: t("common.unexpected_error") });
      setLoading(false);
    }
  };

  useEffect(() => {
    const emailOrPhoneValidator = (register: UseFormRegister<Form>) =>
      register("username", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
        pattern: {
          value:
            /(^\+?\d*$)|(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]*$)/,
          message: t("login_mobile_email.invalid_number_email_input"),
        },
        validate: {
          valid: (phoneNumber) =>
            validatePortuguesePhoneNumber(phoneNumber) ||
            t("common.error_invalid_pt_number"),
        },
      });
    emailOrPhoneValidator(register);
  }, [register, t]);

  return (
    <>
      <div className="row justify-content-center">
        <CredentialsHeader
          title={t("login_mobile_email.header_title")}
          subtitle={t("login_mobile_email.header_message")}
          isTextAlignCenter
          showLoading={loading}
        />
      </div>
      <CredentialsForm
        id="RegistrationUser"
        autoComplete="on"
        form={formState}
        onSubmit={(e) => {
          clearErrors();
          handleSubmit(onSubmit)(e);
        }}
      >
        <div className="row d-flex justify-content-center input-padding-24">
          <UsernameInput
            type="email-phone"
            isFilled={labelEmailOrPhoneNumberVisible}
            isError={errors.username ? true : false}
            errorMsg={errors.username ? errors.username.message : ""}
            onChange={handleEmailOrPhoneNumberChange}
            disabled={loading}
          />
        </div>
        <div
          id="g-recaptcha"
          className="row d-flex justify-content-center input-padding-24"
          data-sitekey={siteKey}
        />
        <ErrorMessage error={errors.submitError} />
        <div className="row d-flex justify-content-center">
          <MantraButton
            text={t("login_mobile_email.button_next_message")}
            type="submit"
            isDisabled={loading}
          />
        </div>
        <div className="contextual-information-header text-align-center">
          <span
            className="verification-resend-code link"
            onClick={() => goToFaq(ANCHOR_NEED_HELP)}
          >
            {t("common.need_help")}
          </span>
        </div>
        <div className="row d-flex flex-column">
          <CredentialsFooter
            contextualInformationVisible
            footerAppsWebsitesIconsVisible
          />
        </div>
      </CredentialsForm>
    </>
  );
}
