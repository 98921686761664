import React from "react";
import Option from "./Option";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";

type OptionsGroupProps = {
  options: Array<{
    icon?: IconDefinition;
    image?: any;
    text: string;
    value: string;
  }>;
  onSelect: (value: string) => void;
};

export default function OptionsGroup(props: OptionsGroupProps) {
  return (
    <>
      {props.options.map((o) => (
        <div className="sc-options-group" key={`options-group-${o.value}`}>
          <Option
            icon={o.icon}
            image={o.image}
            text={o.text}
            value={o.value}
            onClick={() => props.onSelect(o.value)}
          />
          <hr />
        </div>
      ))}
    </>
  );
}
