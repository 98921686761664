import { SetPassword } from "../../../models/site-credentials/mobile/SetPassword";
import { SetPasswordResponse } from "../../../models/site-credentials/mobile/SetPasswordResponse";
import { Response } from "../../../models/site-credentials/common/Response";

export interface ISetPasswordService {
  setPassword(
    setPassword: SetPassword,
    setPasswordUrl: string
  ): Promise<Response<SetPasswordResponse>>;
}

export class SetPasswordService implements ISetPasswordService {
  async setPassword(
    setPassword: SetPassword,
    setPasswordUrl: string
  ): Promise<Response<SetPasswordResponse>> {
    const resp = await fetch(setPasswordUrl, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(setPassword),
    });

    if (resp.ok) {
      return { kind: "success", value: await resp.json() };
    } else if (resp.status === 400) {
      const error = await resp.json();

      return {
        kind: "error",
        code: error[0].Code,
        message: error[0].Message,
      };
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }
}

export const setPasswordService = new SetPasswordService();
