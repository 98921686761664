import { RecoverPasswordResponse } from "../../../models/site-credentials/mobile/RecoverPasswordResponse";
import { Response } from "../../../models/site-credentials/common/Response";

export interface IRecoverPasswordService {
  recoverPassword(
    recoverPasswordUrl: string
  ): Promise<Response<RecoverPasswordResponse>>;
}
export class RecoverPasswordService implements IRecoverPasswordService {
  async recoverPassword(
    recoverPasswordUrl: string
  ): Promise<Response<RecoverPasswordResponse>> {
    const resp = await fetch(recoverPasswordUrl, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    });

    if (resp.ok) {
      return { kind: "success", value: await resp.json() };
    } else if (resp.status === 400) {
      const error = await resp.json();

      if (error) {
        const returnedError = error.find((c: any) => c.Code != null);
        return {
          kind: "error",
          code: returnedError.Code ? returnedError.Code : 0,
          message: returnedError.message ? returnedError.message : "",
        };
      } else {
        return {
          kind: "unexpected_error",
        };
      }
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }
}

export const recoverPasswordService = new RecoverPasswordService();
