import React from "react";
import continenteOnline from "../../../assets/images/img-novo-login.png";
import { useTranslation } from "react-i18next";

type WhereToFindCardNumberProps = {
  isPage?: boolean;
};

export default function WhereToFindCardNumber({
  isPage = false,
}: WhereToFindCardNumberProps) {
  const { t } = useTranslation();

  return (
    <>
      <div className="row where-to-find-card-number-title">
        <p>{t("where-to-find-card-number.header_title")}</p>
      </div>
      <div className="row d-flex justify-content-center">
        <img src={continenteOnline} className="img" alt="" />
      </div>
      <div className="row where-to-find-card-number-contextual-information">
        <p>{t("where-to-find-card-number.message")}</p>
      </div>
    </>
  );
}
