import React from "react";

type ButtonInputs = {
  text: string;
  invertedColor: boolean;
  type: "button" | "submit" | "reset";
  onClick?: () => Promise<void>;
};

export default function ButtonContinente({
  text,
  invertedColor,
  type = "button",
  onClick,
}: ButtonInputs) {
  return (
    <button
      type={type}
      className={
        "btn socialLogin_button " +
        (invertedColor ? "defaultButtonInverted" : "defaultButton")
      }
      onClick={onClick}
    >
      {text}
    </button>
  );
}
