import { PhoneNumberAssociation } from "../../../models/continente-credentials/mobile/PhoneNumberAssociation";
import { PhoneNumberAssociationResponse } from "../../../models/continente-credentials/mobile/PhoneNumberAssociationResponse";
import { Response } from "../../../models/continente-credentials/common/Response";

export interface IPhoneNumberAssociationService {
  PhoneNumberAssociation(
    phoneNumberAssociation: PhoneNumberAssociation,
    phoneNumberAssociationUrl: string
  ): Promise<Response<PhoneNumberAssociationResponse>>;
}

export class PhoneNumberAssociationService
  implements IPhoneNumberAssociationService
{
  async PhoneNumberAssociation(
    PhoneNumberAssociation: PhoneNumberAssociation,
    phoneNumberAssociationUrl: string
  ): Promise<Response<PhoneNumberAssociationResponse>> {
    const resp = await fetch(phoneNumberAssociationUrl, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(PhoneNumberAssociation),
    });
    if (resp.ok) {
      return { kind: "success", value: await resp.json() };
    } else if (resp.status === 400) {
      const error = await resp.json();

      const returnedError = error[0];

      return {
        kind: "error",
        code: returnedError.Code,
        message: returnedError.message,
      };
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }
}

export const phoneNumberAssociationService =
  new PhoneNumberAssociationService();
