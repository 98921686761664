import React, { FormEvent } from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";

type FormProps = {
  children: React.ReactNode;
  form: UseFormReturn<any, object>;
  onSubmit?: (e: FormEvent<HTMLFormElement>) => void;
  [key: string]: any;
};

export default function Form({
  children,
  form,
  onSubmit,
  ...props
}: FormProps) {
  return (
    <FormProvider {...form}>
      <form {...props} onSubmit={onSubmit}>
        {children}
      </form>
    </FormProvider>
  );
}
