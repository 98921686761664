import React from "react";

import { DigitalAsset } from "../../models/site-credentials/common/DigitalAsset";

export const defaultDigitalAsset: DigitalAsset = {
  clientId: "",
  isMobile: false,
};

export const DigitalAssetContext = React.createContext({
  digitalAsset: defaultDigitalAsset,
  updateDigitalAsset: (digitalAsset: DigitalAsset) => {},
});
