import { Config } from "../config/Config";
import { AuthorizeQueryParams } from "../models/AuthorizeQueryParams";

import { AuthorizeRequest } from "../models/AuthorizeRequest";
import { AuthorizeResponse } from "../models/AuthorizeResponse";
import { Response } from "../models/Response";

export class AuthorizationService {
  generateUrl(authorizeParams: AuthorizeQueryParams) {
    const url = new URL(Config.authorizeUrl);

    if (authorizeParams.client_id != null)
      url.searchParams.append("client_id", authorizeParams.client_id);
    if (authorizeParams.scope != null)
      url.searchParams.append("scope", authorizeParams.scope);
    if (authorizeParams.response_type != null)
      url.searchParams.append("response_type", authorizeParams.response_type);
    if (authorizeParams.redirect_uri != null)
      url.searchParams.append("redirect_uri", authorizeParams.redirect_uri);
    if (authorizeParams.state != null)
      url.searchParams.append("state", authorizeParams.state);

    return url;
  }
}

export class AuthorizeSiteCredentialsService {
  async authorize(
    authorizeRequest: AuthorizeRequest
  ): Promise<Response<AuthorizeResponse>> {
    const params = new URLSearchParams({
      clientId: authorizeRequest.clientId,
      token: authorizeRequest.token,
    });

    try {
      const resp = await fetch(
        `${Config.SiteCredentials.authorizeUrl}?${params}`
      );

      switch (resp.status) {
        case 200: {
          const body = await resp.json();
          return {
            kind: "success",
            value: {
              url: body.url,
            },
          };
        }
        default:
          return { kind: "unexpected_error" };
      }
    } catch {
      return { kind: "unexpected_error" };
    }
  }
}

export const authorizationService = new AuthorizationService();
export const authorizeSiteCredentialsService =
  new AuthorizeSiteCredentialsService();
