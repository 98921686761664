import React from "react";
import { ReactComponent as Svg } from "../../../assets/images/red.svg";

export default function LoadingLogo() {
  return (
    <>
      <div className="loading-screen">
        <Svg className="loading-icon" />
      </div>
    </>
  );
}
