import React, { useEffect, useState, ChangeEvent } from "react";
import { useForm, SubmitHandler, UseFormRegister } from "react-hook-form";

import ErrorInput from "../components/error-input/ErrorInput";
import PasswordInput from "../components/password-input/PasswordInput";
import UsernameInput from "../components/username-input/UsernameInput";
import Header from "../components/header/Header";
import ButtonContinente from "../components/button-continente/ButtonContinente";

import { UserCredentials } from "../models/UserCredentials";
import { FormError } from "../models/FormError";
import { loginService } from "../services/LoginService";
import { userService } from "../services/UserService";
import { authorizationService } from "../services/AuthorizationService";
import { useAuthorizeQueryParams } from "../hooks/useAuthorizeQueryParams";

import Loading from "../components/loading/Loading";

type Form = UserCredentials & FormError;

const requiredFieldError = "Campo de preenchimento obrigatório.";
const invalidEmailError = "Formato de e-mail inválido.";
const invalidCredentialsError =
  "E-mail ou palavra-passe incorrectos. Verifique os dados e tente novamente.";
const unexpectedError =
  "Temporariamente indisponivel. Por favor tente mais tarde.";

const usernameValidator = (register: UseFormRegister<Form>) =>
  register("username", {
    required: { value: true, message: requiredFieldError },
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: invalidEmailError,
    },
  });

const passwordValidator = (register: UseFormRegister<Form>) =>
  register("password", {
    required: {
      value: true,
      message: requiredFieldError,
    },
  });

export default function SocialLogin() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(true);

  const authorizeQueryParams = useAuthorizeQueryParams();

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<Form>({ mode: "onChange", reValidateMode: "onChange" });

  useEffect(() => {
    const getUser = async () => {
      const resp = await userService.get();

      switch (resp.kind) {
        case "success": {
          const redirectUrl =
            authorizationService.generateUrl(authorizeQueryParams);
          window.location.href = redirectUrl.toString();

          break;
        }
        default:
          setLoading(false);
          break;
      }
    };

    getUser();
  }, [authorizeQueryParams]);

  useEffect(() => {
    passwordValidator(register);
    usernameValidator(register);
  }, [register]);

  const handleUsernameChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setUsername(value);
    setValue("username", value, { shouldValidate: true });
  };

  const handlePasswordChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setPassword(value);
    setValue("password", value, { shouldValidate: true });
  };

  const onSubmit: SubmitHandler<UserCredentials> = async (data) => {
    setLoading(true);

    try {
      const response = await loginService.login({
        username: data.username,
        password: data.password,
      });

      if (response.kind === "success") {
        const redirectUrl =
          authorizationService.generateUrl(authorizeQueryParams);
        window.location.href = redirectUrl.toString();

        return;
      }

      switch (response.kind) {
        case "error":
          setError("submitError", { message: invalidCredentialsError });
          setLoading(false);
          break;
        default:
          setError("submitError", { message: unexpectedError });
          setLoading(false);
          break;
      }
    } catch (e) {
      setError("submitError", { message: unexpectedError });
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loading />}
      <form
        id="socialLogin"
        onSubmit={(e) => {
          clearErrors();
          handleSubmit(onSubmit)(e);
        }}
      >
        <Header title="Utilize o seu registo Continente Online" />
        <div className="socialLogin_body socialLogin_body_margin">
          <div className="socialLogin_body_text">
            <p>
              Caso opte pelo registo no site da Wells com o email e palavra
              passe da sua conta Continente Online,{" "}
              <b>o seu login deverá ser sempre feito por esta via. </b>
            </p>
            <p>
              Para prosseguir com este registo, preencha os campos abaixo e
              clique em Continuar.
            </p>
          </div>
          <div className="socialLogin_inputs">
            <div>
              <ErrorInput name="username" errors={errors}>
                <UsernameInput
                  value={username}
                  error={errors.username ? true : false}
                  onChange={handleUsernameChange}
                />
              </ErrorInput>
              <ErrorInput name="password" errors={errors}>
                <PasswordInput
                  value={password}
                  error={errors.password ? true : false}
                  onChange={handlePasswordChange}
                />
              </ErrorInput>
            </div>
          </div>
          {errors.submitError && (
            <div className="form-error-message">
              {errors.submitError.message}
            </div>
          )}

          <div className="socialLogin_buttons">
            <ButtonContinente
              text="CONTINUAR"
              invertedColor={false}
              type="submit"
            />
          </div>
        </div>
      </form>
    </>
  );
}
