import { PasswordRecoverValidateIdentifier } from "../../../models/site-credentials/mobile/PasswordRecoverValidateIdentifier";
import { PasswordRecoverResponse } from "../../../models/site-credentials/mobile/PasswordRecoverResponse";
import { Response } from "../../../models/site-credentials/common/Response";
import { PasswordRecoverVerifyOtp } from "../../../models/site-credentials/mobile/PasswordRecoverVerifyOtp";
import { PasswordRecoverSetPassword } from "../../../models/site-credentials/mobile/PasswordRecoverSetPassword";

export interface IPasswordRecoverService {
  validateIdentifier(
    passwordRecoverValidateIdentifier: PasswordRecoverValidateIdentifier,
    validateIdentifierUrl: string
  ): Promise<Response<PasswordRecoverResponse>>;

  otpValidation(
    passwordRecoverValidateOtp: PasswordRecoverVerifyOtp,
    validateOtpUrl: string
  ): Promise<Response<PasswordRecoverResponse>>;

  setPassword(
    passwordRecoverSetPassword: PasswordRecoverSetPassword,
    setPasswordUrl: string
  ): Promise<Response<PasswordRecoverResponse>>;
}

export class PasswordRecoverService implements IPasswordRecoverService {
  async validateIdentifier(
    passwordRecoverValidateIdentifier: PasswordRecoverValidateIdentifier,
    validateIdentifierUrl: string
  ): Promise<Response<PasswordRecoverResponse>> {
    const resp = await fetch(validateIdentifierUrl, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(passwordRecoverValidateIdentifier),
    });

    if (resp.ok) {
      return { kind: "success", value: await resp.json() };
    } else if (resp.status === 400) {
      const error = await resp.json();

      if (error) {
        const returnedError = error.find((c: any) => c.Code != null);
        return {
          kind: "error",
          code: returnedError.Code ? returnedError.Code : 0,
          message: returnedError.message ? returnedError.message : "",
        };
      } else {
        return {
          kind: "unexpected_error",
        };
      }
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }

  async otpValidation(
    passwordRecoverValidateOtp: PasswordRecoverVerifyOtp,
    validateOtpUrl: string
  ): Promise<Response<PasswordRecoverResponse>> {
    const resp = await fetch(validateOtpUrl, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(passwordRecoverValidateOtp),
    });

    if (resp.ok) {
      return { kind: "success", value: await resp.json() };
    } else if (resp.status === 400) {
      const error = await resp.json();

      if (error) {
        const returnedError = error.find((c: any) => c.Code != null);
        return {
          kind: "error",
          code: returnedError.Code ? returnedError.Code : 0,
          message: returnedError.message ? returnedError.message : "",
        };
      } else {
        return {
          kind: "unexpected_error",
        };
      }
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }

  async setPassword(
    passwordRecoverSetPassword: PasswordRecoverSetPassword,
    setPasswordUrl: string
  ): Promise<Response<PasswordRecoverResponse>> {
    const resp = await fetch(setPasswordUrl, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(passwordRecoverSetPassword),
    });

    if (resp.ok) {
      return { kind: "success", value: await resp.json() };
    } else if (resp.status === 400) {
      const error = await resp.json();

      if (error) {
        const returnedError = error.find((c: any) => c.Code != null);
        return {
          kind: "error",
          code: returnedError.Code ? returnedError.Code : 0,
          message: returnedError.message ? returnedError.message : "",
        };
      } else {
        return {
          kind: "unexpected_error",
        };
      }
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }
}

export const passwordRecoverService = new PasswordRecoverService();
