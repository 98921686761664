import React from "react";
import "./button.css";

import { useHistory } from "react-router-dom";

type InputProps = {
  text: string;
  isDisabled?: boolean;
  type: "button" | "submit" | "reset";
  size?: "normal" | "large" | "super-large";
  isBackButton?: boolean;
  previousStepBackXTimes?: number;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
};

export default function Button({
  text,
  type,
  size = "super-large",
  isBackButton = false,
  onClick,
  previousStepBackXTimes,
  isDisabled = false,
}: InputProps) {
  const history = useHistory();

  const clickBackXTimes = (numBack: number) => {
    if (numBack >= history.length) numBack = history.length - 1;
    const numBackMinus = -Math.abs(numBack);
    history.go(numBackMinus);
  };

  return (
    <button
      type={type}
      className={
        isBackButton
          ? "sc-buttons-secondary sc-buttons-secondary-" + size
          : "sc-buttons-primary sc-buttons-primary-" + size
      }
      onClick={
        isBackButton
          ? () => clickBackXTimes(previousStepBackXTimes as number)
          : onClick
      }
      disabled={isDisabled}
    >
      <div
        className={
          isBackButton
            ? "sc-buttons-secondary-text"
            : isDisabled
            ? "sc-buttons-primary-normal-text-disabled"
            : "sc-buttons-primary-text"
        }
      >
        {text}
      </div>
    </button>
  );
}
