import React, { useState, ChangeEvent } from "react";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";

type PasswordInputProps = {
  value: string;
  error: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

enum Mode {
  Text = "text",
  Password = "password",
}

export default function PasswordInput({
  value,
  onChange,
  error,
  ...props
}: PasswordInputProps) {
  const [isHidden, setIsHidden] = useState<boolean>(true);

  const activeMode = isHidden ? Mode.Password : Mode.Text;

  return (
    <div className="input_icon_group">
      <input
        {...props}
        type={activeMode}
        autoComplete="current-password"
        value={value}
        onChange={onChange}
        placeholder="* Palavra-passe"
        className={`form-control ${error ? "error" : ""}`}
      />
      {isHidden ? (
        <AiOutlineEyeInvisible
          className="input_icon"
          onClick={() => setIsHidden(false)}
        />
      ) : (
        <AiOutlineEye
          className="input_icon"
          onClick={() => setIsHidden(true)}
        />
      )}
    </div>
  );
}
