import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "../src/assets/languages/en/translation.json";
import translationPT from "../src/assets/languages/pt/translation.json";
import faqsPT from "../src/assets/languages/pt/faqs.json";
import faqsEN from "../src/assets/languages/en/faqs.json";
import termsandconditionsPT from "../src/assets/languages/pt/termsandconditions.json";
import termsandconditionsEN from "../src/assets/languages/en/termsandconditions.json";

const fallbackLng = ["pt"];
const availableLanguages = ["pt", "en"];

const resources = {
  en: {
    translation: translationEN,
    faqs: faqsEN,
    termsandconditions: termsandconditionsEN,
  },
  pt: {
    translation: translationPT,
    faqs: faqsPT,
    termsandconditions: termsandconditionsPT,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng,
  detection: {
    checkWhitelist: true,
  },
  react: {
    useSuspense: false,
  },
  debug: false,
  whitelist: availableLanguages,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
