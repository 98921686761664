import React, { useEffect, useState, ChangeEvent, useContext } from "react";
import { useForm, SubmitHandler, UseFormRegister } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import CredentialsFooter from "../../../components/continente-credentials/footer/Footer";
import CredentialsHeader from "../../../components/continente-credentials/header/Header";
import CredentialsForm from "../../../components/continente-credentials/Form/Form";
import CardInput from "../../../components/continente-credentials/card-input/CardInput";
import { FormError } from "../../../models/continente-credentials/common/FormError";
import { useTranslation } from "react-i18next";
import { PhoneNumberAssociationResponse } from "../../../models/continente-credentials/mobile/PhoneNumberAssociationResponse";
import BottomSheet from "../../../components/continente-credentials/bottom-sheet/BottomSheet";
import WhereToFindCardNumber from "../../../components/continente-credentials/where-to-find-card-number/WhereToFindCardNumber";
import { DigitalAssetContext } from "../../../contexts/continente-credentials/DigitalAssetContext";
import { CardAssociation } from "../../../models/continente-credentials/mobile/CardAssociation";
import { cardAssociationService } from "../../../services/continente-credentials/mobile/CardAssociationService";
import loyaltyCard from "../../../assets/images/cartoes.png";
import { UserContext } from "../../../contexts/continente-credentials/UserContext";
import ErrorMessage from "../../../components/continente-credentials/error-message/error-message";
import MantraButton from "../../../components/continente-credentials/mantra-button/MantraButton";

type Form = CardAssociation & FormError;

export default function LoyaltyCardAssociation() {
  const [loading, setLoading] = useState(false);
  const [isBottomSheetOpen, setBottomSheetOpen] = useState(false);
  const { t } = useTranslation();
  const location = useLocation<PhoneNumberAssociationResponse>();
  const history = useHistory();
  const digitalAssetContext = useContext(DigitalAssetContext);
  const [labelCardVisible, setLabelCardVisible] = useState<boolean>(false);
  const [cardValue, setCardValue] = useState("");
  const userContext = useContext(UserContext);
  const goToFaq = (anchor?: string) => {
    if (anchor) anchor = "#" + anchor;
    history.push("/continente-credentials/faq" + anchor);
  };
  const ANCHOR_NEED_HELP = "faq8";

  const formState = useForm<Form>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = formState;

  const closeBottomSheet = (isClosed: boolean) => {
    setBottomSheetOpen(!isClosed);
  };

  const skipAssociation = async () => {
    setLoading(true);
    try {
      if (
        location.state != null &&
        !location.state.nextStep.length &&
        !location.state.properties.email.length
      ) {
        throw new Error(
          "Location State does not have all the necessary fields."
        );
      }
      const response = await cardAssociationService.cardAssociation(
        {
          cardNumber: "0",
          addCC: false,
          skipAssociation: true,
        },
        location.state.nextStep
      );

      switch (response.kind) {
        case "success":
          history.push(response.value.nextStep.substring(4), response.value);
          break;
        default:
          setError("submitError", { message: t("common.unexpected_error") });
          break;
      }
      setLoading(false);
    } catch (e) {
      setError("submitError", { message: t("common.unexpected_error") });
      setLoading(false);
    }
  };

  const SignUpClick = async () => {
    try {
      const response = await cardAssociationService.cardAssociation(
        {
          cardNumber: "0",
          addCC: true,
          skipAssociation: false,
        },
        location.state != null ? location.state.nextStep : ""
      );

      switch (response.kind) {
        case "success": {
          userContext.updateUser({
            ...userContext.user,
            phoneNumber:
              userContext.user.phoneNumber === ""
                ? response.value.properties.phoneNumber
                : userContext.user.phoneNumber,
          });

          history.push(response.value.nextStep.substring(4), response.value);
          break;
        }
        default:
          setError("submitError", { message: t("common.unexpected_error") });
          break;
      }

      setLoading(false);
    } catch (e) {
      setError("submitError", { message: t("common.unexpected_error") });
      setLoading(false);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (
      e.key !== "Enter" &&
      (!e.key.match(/[0-9\s]/) ||
        (e.target as HTMLInputElement).value.length > 12)
    ) {
      e.preventDefault();
    }
  };

  const handleCardChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    clearErrors();

    let cardNumber = value.replace(/\s/g, "");

    setValue("cardNumber", cardNumber);
    setLabelCardVisible(value.length > 0 ? true : false);
    setCardValue(cardNumber);
  };

  const onSubmit: SubmitHandler<CardAssociation> = async (data) => {
    setLoading(true);
    try {
      const response = await cardAssociationService.cardAssociation(
        {
          cardNumber: data.cardNumber,
          addCC: false,
          skipAssociation: false,
        },
        location.state != null ? location.state.nextStep : ""
      );

      switch (response.kind) {
        case "success": {
          history.push(response.value.nextStep.substring(4), response.value);
          break;
        }
        case "error":
          switch (response.code) {
            case "100013":
              setError("cardNumber", {
                message: t("card_association.invalid_code"),
              });
              break;
            case "100003":
            case "100005":
            case "100006":
              setError("cardNumber", {
                message: t("card_association.invalid_loyaltycard"),
              });
              break;
            default:
              setError("submitError", {
                message: t("common.unexpected_error"),
              });
              break;
          }
          break;
        default:
          setError("submitError", { message: t("common.unexpected_error") });
          break;
      }

      setLoading(false);
    } catch (e) {
      setError("submitError", { message: t("common.unexpected_error") });
      setLoading(false);
    }
  };

  useEffect(() => {
    const CardValidator = (register: UseFormRegister<Form>) =>
      register("cardNumber", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
        pattern: {
          value: /^[0-9]{13}$/,
          message: t("card_association.invalid_loyaltycard"),
        },
      });
    CardValidator(register);
  }, [register, t]);

  return (
    <>
      <CredentialsForm
        id="CardAssociation"
        form={formState}
        autoComplete="off"
        onSubmit={(e) => {
          clearErrors();
          handleSubmit(onSubmit)(e);
        }}
      >
        <div className="row justify-content-center">
          <CredentialsHeader
            title={t("card_association.header_title")}
            subtitle={t("card_association.header_message")}
            showLoading={loading}
            skipStep={
              digitalAssetContext.digitalAsset.requiredCardAssociation
                ? undefined
                : skipAssociation
            }
          />
        </div>
        <div className="row justify-content-center">
          <img src={loyaltyCard} className="continente-card" alt="" />
        </div>
        <div className="row d-flex justify-content-center">
          <div className="input-outline-eye">
            <CardInput
              labelText={t("card_association.card_number_input")}
              isFilled={labelCardVisible}
              isError={errors.cardNumber ? true : false}
              errorMsg={errors.cardNumber ? errors.cardNumber.message : ""}
              onChange={handleCardChange}
              onKeyPress={handleKeyPress}
              value={cardValue}
            />
          </div>
        </div>
        <ErrorMessage error={errors.submitError} />
        <div className="row d-flex justify-content-center">
          <MantraButton
            text={t("card_association.button_next_message")}
            type="submit"
          />
        </div>
        <div className="sign-up">
          {t("card_association.card_not_exist_message1")}{" "}
          <span onClick={SignUpClick} className="what-is-link">
            {" "}
            {t("card_association.card_not_exist_message2")}{" "}
          </span>
        </div>
        <div className="contextual-information-header text-align-center">
          <span
            className="verification-resend-code link"
            onClick={() => goToFaq(ANCHOR_NEED_HELP)}
          >
            {t("common.need_help")}
          </span>
        </div>
        <div className="row d-flex flex-column">
          <CredentialsFooter
            contextualInformationVisible={false}
            footerAppsWebsitesIconsVisible={false}
          />
        </div>
      </CredentialsForm>
      {isBottomSheetOpen && (
        <BottomSheet
          content={<WhereToFindCardNumber />}
          closeBottomSheet={closeBottomSheet}
        />
      )}
    </>
  );
}
