import React from "react";
import Sheet, { SheetRef } from "react-modal-sheet";

type BottomSheetProps = {
  content: React.ReactFragment;
  closeBottomSheet: (isClosed: boolean) => void;
};

export default function BottomSheet({
  content,
  closeBottomSheet,
}: BottomSheetProps) {
  const [isOpen, setOpen] = React.useState(true);
  const ref = React.useRef<SheetRef>();
  const bottomSheetThreeQuartersHeight: number = window.innerHeight * 0.75;
  const bottomSheetMiddleHeight: number = window.innerHeight * 0.5;

  return (
    <>
      <Sheet
        ref={ref}
        isOpen={isOpen}
        onClose={() => {
          setOpen(false);
          closeBottomSheet(true);
        }}
        snapPoints={[
          bottomSheetThreeQuartersHeight,
          bottomSheetMiddleHeight,
          0,
        ]}
        initialSnap={1}
        onSnap={(snapIndex) =>
          console.log("> Current snap point index:", snapIndex)
        }
      >
        <Sheet.Container>
          <Sheet.Content>
            <div className="row d-flex justify-content-center">
              <div className="line"></div>
            </div>
            {content}
          </Sheet.Content>
        </Sheet.Container>

        <Sheet.Backdrop
          onTap={() => {
            setOpen(false);
            closeBottomSheet(true);
          }}
        />
      </Sheet>
    </>
  );
}
