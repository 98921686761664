import { CardAssociation } from "./../../../models/continente-credentials/mobile/CardAssociation";
import { Response } from "../../../models/continente-credentials/common/Response";
import { CardAssociationResponse } from "../../../models/continente-credentials/mobile/CardAssociationResponse";
import { LoyaltyCardOtpOptionsResponse } from "../../../models/continente-credentials/loyaltycard/LoyaltyCardOtpOptionsResponse";
import { LoyaltyCardOtpValidationResponse } from "../../../models/continente-credentials/loyaltycard/LoyaltyCardOtpValidationResponse";
import { LoyaltyCardAssociationResponse } from "../../../models/continente-credentials/loyaltycard/LoyaltyCardAssociationResponse";

export interface ICardAssociationService {
  cardAssociation(
    cardAssociation: CardAssociation,
    cardAssociationUrl: string
  ): Promise<Response<CardAssociationResponse>>;

  sendOtp(
    option: string,
    loyaltyOtpOptionsUrl: string
  ): Promise<Response<LoyaltyCardOtpOptionsResponse>>;

  verifyOtp(
    otp: string,
    loyaltyOtpVerificationUrl: string
  ): Promise<Response<LoyaltyCardOtpValidationResponse>>;
}

export class CardAssociationService implements ICardAssociationService {
  async cardAssociation(
    cardAssociation: CardAssociation,
    cardAssociationUrl: string
  ): Promise<Response<CardAssociationResponse>> {
    const resp = await fetch(cardAssociationUrl, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(cardAssociation),
    });

    if (resp.ok) {
      return { kind: "success", value: await resp.json() };
    } else if (resp.status === 400) {
      const error = await resp.json();

      const returnedError = error.find((c: any) => c.Code != null);
      return {
        kind: "error",
        code: returnedError.Code ? returnedError.Code : 0,
        message: returnedError.message ? returnedError.message : "",
      };
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }

  async sendOtp(
    option: string,
    loyaltyOtpOptionsUrl: string
  ): Promise<Response<LoyaltyCardOtpOptionsResponse>> {
    const resp = await fetch(loyaltyOtpOptionsUrl, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        selectedOption: option,
      }),
    });
    if (resp.ok) {
      return { kind: "success", value: await resp.json() };
    } else if (resp.status === 400) {
      const error = await resp.json();

      return {
        kind: "error",
        code: error.code,
        message: error.message,
      };
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }

  async verifyOtp(
    otp: string | undefined,
    loyaltyOtpVerificationUrl: string
  ): Promise<Response<LoyaltyCardOtpValidationResponse>> {
    const resp = await fetch(loyaltyOtpVerificationUrl, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        otp: otp,
      }),
    });

    if (resp.ok) {
      return { kind: "success", value: await resp.json() };
    } else if (resp.status === 400) {
      const error = await resp.json();

      const returnedError = error.find((c: any) => c.Code != null);
      return {
        kind: "error",
        code: returnedError.Code ? returnedError.Code : 0,
        message: returnedError.message ? returnedError.message : "",
      };
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }

  async outdatedData(
    loyaltyOutdatedDataUrl: string
  ): Promise<Response<LoyaltyCardAssociationResponse>> {
    const resp = await fetch(loyaltyOutdatedDataUrl, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    });

    if (resp.ok) {
      return { kind: "success", value: await resp.json() };
    } else if (resp.status === 400) {
      const error = await resp.json();

      const returnedError = error.find((c: any) => c.Code != null);
      return {
        kind: "error",
        code: returnedError.Code ? returnedError.Code : 0,
        message: returnedError.message ? returnedError.message : "",
      };
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }
}

export const cardAssociationService = new CardAssociationService();
