import React, { useState, useEffect } from "react";
import Header from "../../../components/site-credentials/header/Header";
import CredentialsIdentifier from "../../../components/site-credentials/identifier-input/IdentifierInput";
import { useForm, SubmitHandler, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IdentifierValidation } from "../../../models/site-credentials/loyaltycard/IdentifierValidation";
import { FormError } from "../../../models/continente-credentials/common/FormError";
import { loyaltyCardService } from "../../../services/site-credentials/loyaltycard/LoyaltyCardService";
import { useLocation, useHistory } from "react-router-dom";
import { LoyaltyCardAssociationResponse } from "../../../models/site-credentials/loyaltycard/LoyaltyCardAssociationResponse";
import alertCircle from "../../../assets/images/alert-circle.svg";
import Footer from "../../../components/site-credentials/footer/Footer";

type Form = IdentifierValidation & FormError;

export default function IdentificationVerification() {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation<LoyaltyCardAssociationResponse>();
  const requestedValues: number[] =
    location.state.properties.requestedIdentifierValues;
  const [loading, setLoading] = useState(false);

  const goToFaq = (anchor?: string) => {
    if (anchor) anchor = "#" + anchor;
    history.push("/site-credentials/faq" + anchor);
  };
  const ANCHOR_NEED_HELP = "";

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<Form>({ mode: "onSubmit", reValidateMode: "onSubmit" });

  setValue("requestedIdentifierValues", requestedValues);

  const handleIdentifierChange = (value: string[]) => {
    clearErrors();
    setValue("identifierValues", value);
  };

  const onSubmit: SubmitHandler<IdentifierValidation> = async (
    identifierValidation
  ) => {
    setLoading(true);
    try {
      const response = await loyaltyCardService.validateIdentifier(
        identifierValidation,
        location.state != null ? location.state.nextStep : ""
      );
      if (response.kind === "success") {
        history.push(response.value.nextStep.substring(4), {
          ...response.value,
          responseCode: "100003",
        });
      }
      if (response.kind === "error") {
        switch (response.code) {
          case "300007":
            setError("submitError", {
              message: t("identification_verification.input_error"),
            });
            break;
          default:
            setError("submitError", { message: t("common.unexpected_error") });
        }
      } else {
        setError("submitError", { message: t("common.unexpected_error") });
      }

      setLoading(false);
    } catch (e) {
      setError("submitError", { message: t("common.unexpected_error") });
      setLoading(false);
    }
  };

  useEffect(() => {
    const identifierValidator = (register: UseFormRegister<Form>) =>
      register("identifierValues", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
        pattern: {
          value: /^[0-9]*$/,
          message: t("identification_verification.identifier_must_be_numeric"),
        },
      });
    identifierValidator(register);
  }, [register, t]);

  return (
    <>
      <form
        id="IdentificationVerification"
        autoComplete="off"
        onSubmit={(e) => {
          clearErrors();
          handleSubmit(onSubmit)(e);
        }}
      >
        <div className="row justify-content-center">
          <Header
            title={t("identification_verification.header_title")}
            subtitle={t("identification_verification.header_message")}
            showLoading={loading}
            previousStepBackXTimes={1}
            // isTextAlignCenter
          />
        </div>

        <div className="row justify-content-center">
          <CredentialsIdentifier
            requestedValues={requestedValues}
            onChange={handleIdentifierChange}
          />
        </div>

        <div className="d-inline-flex align-items-center justify-content-center w-100">
          {errors.submitError && (
            <>
              <img src={alertCircle} alt="" className="alert-cicle" />
              <span className="errorLabel">{errors.submitError.message}</span>
            </>
          )}
        </div>
        <div className="sc-contextual-information-header text-align-center">
          <span
            className="verification-resend-code link"
            onClick={() => goToFaq(ANCHOR_NEED_HELP)}
          >
            {t("common.need_help")}
          </span>
        </div>
        <div className="row d-flex flex-column">
          <Footer />
        </div>
      </form>
    </>
  );
}
