import { LoyaltyCard } from "../../../models/site-credentials/loyaltycard/LoyaltyCard";
import { LoyaltyCardAssociationResponse } from "../../../models/site-credentials/loyaltycard/LoyaltyCardAssociationResponse";
import { Response } from "../../../models/site-credentials/common/Response";
import { IdentifierValidation } from "../../../models/site-credentials/loyaltycard/IdentifierValidation";
import { IdentifierValidationResponse } from "../../../models/site-credentials/loyaltycard/IdentifierValidationResponse";
import { ContactCenterRequest } from "../../../models/site-credentials/loyaltycard/ContactCenterRequest";

export interface ILoyaltyCardService {
  associateLoyaltyCard(
    loyaltyCard: LoyaltyCard,
    loyaltyCardUrl: string
  ): Promise<Response<LoyaltyCardAssociationResponse>>;
  validateIdentifier(
    identifierValidation: IdentifierValidation,
    identifierValidationUrl: string
  ): Promise<Response<IdentifierValidationResponse>>;
}

export class LoyaltyCardService implements ILoyaltyCardService {
  async associateLoyaltyCard(
    loyaltyCard: LoyaltyCard,
    loyaltyCardUrl: string
  ): Promise<Response<LoyaltyCardAssociationResponse>> {
    const resp = await fetch(loyaltyCardUrl, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(loyaltyCard),
    });
    if (resp.ok) {
      return { kind: "success", value: await resp.json() };
    } else if (resp.status === 400) {
      const error = await resp.json();

      let errorCode: string = "0";
      let errorMessage: string = "";
      if (error.length > 0) {
        if (error[0].Code) {
          errorCode = error[0].Code.toString();
          errorMessage = error[0].Message ? error[0].Message.toString() : "";
        }
      }
      return {
        kind: "error",
        code: errorCode,
        message: errorMessage,
      };
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }

  async validateIdentifier(
    identifierValidation: IdentifierValidation,
    identifierValidationUrl: string
  ): Promise<Response<IdentifierValidationResponse>> {
    const resp = await fetch(identifierValidationUrl, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(identifierValidation),
    });
    if (resp.ok) {
      return { kind: "success", value: await resp.json() };
    } else if (resp.status === 400) {
      const error = await resp.json();

      let errorCode: string = "0";
      let errorMessage: string = "";
      if (error.length > 0) {
        if (error[0].Code) {
          errorCode = error[0].Code.toString();
          errorMessage = error[0].Message ? error[0].Message.toString() : "";
        }
      }
      return {
        kind: "error",
        code: errorCode,
        message: errorMessage,
      };
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }

  async loyaltyCardCallCenterOption(
    contactCenterRequest: ContactCenterRequest,
    loyaltyCardCallCenterOptionUrl: string
  ): Promise<Response<IdentifierValidationResponse>> {
    const resp = await fetch(loyaltyCardCallCenterOptionUrl, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(contactCenterRequest),
    });
    if (resp.ok) {
      return { kind: "success", value: await resp.json() };
    } else if (resp.status === 400) {
      const error = await resp.json();

      let errorCode: string = "0";
      let errorMessage: string = "";
      if (error.length > 0) {
        if (error[0].Code) {
          errorCode = error[0].Code.toString();
          errorMessage = error[0].Message ? error[0].Message.toString() : "";
        }
      }
      return {
        kind: "error",
        code: errorCode,
        message: errorMessage,
      };
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }

  async loyaltyCardSuccessAssociation(
    loyaltyCardSuccessAssociationUrl: string
  ): Promise<Response<IdentifierValidationResponse>> {
    const resp = await fetch(loyaltyCardSuccessAssociationUrl, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    });
    if (resp.ok) {
      return { kind: "success", value: await resp.json() };
    } else if (resp.status === 400) {
      const error = await resp.json();

      let errorCode: string = "0";
      let errorMessage: string = "";
      if (error.length > 0) {
        if (error[0].Code) {
          errorCode = error[0].Code.toString();
          errorMessage = error[0].Message ? error[0].Message.toString() : "";
        }
      }
      return {
        kind: "error",
        code: errorCode,
        message: errorMessage,
      };
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }
}

export const loyaltyCardService = new LoyaltyCardService();
