import React, { useEffect, useState, ChangeEvent, useCallback } from "react";
import { useForm, SubmitHandler, UseFormRegister } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import CredentialsFooter from "../../../../components/continente-credentials/footer/Footer";
import CredentialsHeader from "../../../../components/continente-credentials/header/Header";
import MantraButton from "../../../../components/continente-credentials/mantra-button/MantraButton";
import CredentialsForm from "../../../../components/continente-credentials/Form/Form";
import CredentialsInput from "../../../../components/continente-credentials/input/Input";
import TermsAndConditionsCheckbox from "../../../../components/continente-credentials/terms-and-conditions-checkbox/TermsAndConditionsCheckbox";
import { ValidatePasswordResponse } from "../../../../models/continente-credentials/mobile/ValidatePasswordResponse";
import { ValidatePassword } from "../../../../models/continente-credentials/mobile/ValidatePassword";
import { FormError } from "../../../../models/continente-credentials/common/FormError";
import { validatePasswordService } from "../../../../services/continente-credentials/mobile/ValidatePasswordService";
import { useTranslation } from "react-i18next";
import ErrorMessage from "../../../../components/continente-credentials/error-message/error-message";
import {
  useGoogleRecaptcha,
  getRecaptchaToken,
  siteKey,
} from "../../../../hooks/continente-credentials/useGoogleRecaptcha";
type Form = ValidatePassword & FormError;

export default function SetUserPassword() {
  const history = useHistory();
  const [labelPasswordVisible, setLabelPasswordVisible] =
    useState<boolean>(false);
  const [checkAcceptedTermsConditions, setAcceptedTermsConditions] =
    useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  const [, setPassword] = useState<string>("");
  const { t } = useTranslation();
  const location = useLocation<ValidatePasswordResponse>();
  const phoneNumber =
    location.state != null ? location.state.properties.phoneNumber : "";
  const simplePhoneNumber =
    location.state != null ? location.state.properties.simplePhoneNumber : "";
  const goToFaq = (anchor?: string) => {
    if (anchor) anchor = "#" + anchor;
    history.push("/continente-credentials/faq" + anchor);
  };

  const ANCHOR_NEED_HELP = "";
  const formState = useForm<Form>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = formState;

  const handlePasswordChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    clearErrors();
    setPassword(value);
    setValue("password", value);
    setLabelPasswordVisible(value.length > 0 ? true : false);
  };

  const passwordRecover = useCallback(async () => {
    setLoading(true);
    try {
      const response = await validatePasswordService.validatePassword(
        {
          passwordRecover: true,
          password: "",
          acceptedTermsConditions: false,
          recaptchaToken: getRecaptchaToken(location.state),
        },
        location.state != null ? location.state.nextStep : ""
      );

      switch (response.kind) {
        case "success":
          history.push(response.value.nextStep.substring(4), response.value);
          break;

        case "error":
          setError("submitError", { message: t("common.unexpected_error") });
          break;

        default:
          setError("submitError", { message: t("common.unexpected_error") });
          break;
      }

      setLoading(false);
    } catch (e) {
      setError("submitError", { message: t("common.unexpected_error") });
      setLoading(false);
    }
  }, [history, location.state, setError, t]);

  useGoogleRecaptcha(location.state, passwordRecover);

  const handleAcceptedTermsConditionsChange = () => {
    setAcceptedTermsConditions((state) => {
      return !state;
    });
    setValue("acceptedTermsConditions", checkAcceptedTermsConditions);
  };
  const onSubmit: SubmitHandler<ValidatePassword> = async (data) => {
    setLoading(true);
    try {
      const response = await validatePasswordService.validatePassword(
        {
          passwordRecover: false,
          password: data.password,
          acceptedTermsConditions: data.acceptedTermsConditions,
        },
        location.state != null ? location.state.nextStep : ""
      );

      switch (response.kind) {
        case "success":
          history.push(response.value.nextStep.substring(4), response.value);
          break;

        case "error":
          if (response.code === "100008") {
            setError("password", {
              message: t("password_definition.password_invalid"),
            });
            break;
          } else if (response.code === "300002") {
            setError("submitError", {
              message: t("common.unexpected_error"),
            });
            break;
          }
          break;

        default:
          setError("submitError", { message: t("common.unexpected_error") });
          break;
      }

      setLoading(false);
    } catch (e) {
      setError("submitError", { message: t("common.unexpected_error") });
      setLoading(false);
    }
  };

  useEffect(() => {
    const passwordValidator = (register: UseFormRegister<Form>) =>
      register("password", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
      });
    const contextualInformationValidator = (register: UseFormRegister<Form>) =>
      register("acceptedTermsConditions", {
        required: {
          value: true,
          message: "",
        },
      });

    passwordValidator(register);

    if (location.state.properties.mustAcceptTermsConditions) {
      contextualInformationValidator(register);
    }
  }, [location.state.properties.mustAcceptTermsConditions, register, t]);

  return (
    <>
      <CredentialsForm
        id="SetUserPassword"
        form={formState}
        autoComplete="on"
        onSubmit={(e) => {
          clearErrors();
          handleSubmit(onSubmit)(e);
        }}
        noValidate
      >
        <div className="row">
          <div className="justify-content-center">
            <CredentialsHeader
              title={t("phonenumber_login_password_associated.header_title")}
              subtitle={
                t("phonenumber_login_password_associated.header_message") +
                " " +
                phoneNumber
              }
              showLoading={loading}
              previousStepBackXTimes={1}
            />
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          <div
            hidden
            // this needs the tag noValidate in form to work
          >
            <input type="email" value={simplePhoneNumber} />
          </div>
          <div className="input-outline-eye">
            <CredentialsInput
              labelText={t(
                "phonenumber_login_password_associated.password_input"
              )}
              isFilled={labelPasswordVisible}
              isError={errors.password ? true : false}
              errorMsg={errors.password ? errors.password.message : ""}
              onChange={handlePasswordChange}
              type={"password"}
            />
          </div>
        </div>
        {location.state.properties.mustAcceptTermsConditions && (
          <div className="row">
            <div className="d-flex justify-content-center">
              <div className="form-check-group-copy">
                <div className="form-check">
                  <TermsAndConditionsCheckbox
                    onClick={handleAcceptedTermsConditionsChange}
                    isError={errors.acceptedTermsConditions ? true : false}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <ErrorMessage
            error={errors.submitError}
            acceptTermsAndConditions={errors.acceptedTermsConditions}
          />
        </div>
        <div className="row">
          <div className="d-flex justify-content-center">
            <div>
              <MantraButton
                text={t("password_definition.button_next_message")}
                type="submit"
              />
            </div>
          </div>
          <div className="d-flex flex-column">
            <CredentialsFooter />
          </div>
        </div>
        <div className="row">
          <div className="d-flex justify-content-center">
            <div className="validate-password-resend-code">
              <span
                className="validate-password-resend-code link"
                onClick={passwordRecover}
              >
                {t(
                  "phonenumber_login_password_associated.forgot_password_message1"
                )}{" "}
              </span>
            </div>
          </div>
        </div>
        <div
          id="g-recaptcha"
          className="row d-flex justify-content-center input-padding-24"
          data-sitekey={siteKey}
        />
        <div className="contextual-information-header text-align-center">
          <span
            className="verification-resend-code link"
            onClick={() => goToFaq(ANCHOR_NEED_HELP)}
          >
            {t("common.need_help")}
          </span>
        </div>
      </CredentialsForm>
    </>
  );
}
