import React, { useEffect, useState, ChangeEvent } from "react";
import { useForm, SubmitHandler, UseFormRegister } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import Footer from "../../../components/site-credentials/footer/Footer";
import Header from "../../../components/site-credentials/header/Header";
import SiteCredentialsInput from "../../../components/site-credentials/input/input";
import { UserSignInResponse } from "../../../models/site-credentials/mobile/UserSignInResponse";
import { ValidatePassword } from "../../../models/site-credentials/mobile/ValidatePassword";
import { FormError } from "../../../models/site-credentials/common/FormError";
import { validatePasswordService } from "../../../services/site-credentials/mobile/ValidatePasswordService";
import { useTranslation } from "react-i18next";
import { recoverPasswordService } from "../../../services/site-credentials/mobile/RecoverPasswordService";

import ErrorMessage from "../../../components/continente-credentials/error-message/error-message";

type Form = ValidatePassword & FormError;

export default function SetUserPassword() {
  const history = useHistory();
  const [labelPasswordVisible, setLabelPasswordVisible] =
    useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [, setPassword] = useState<string>("");
  const { t } = useTranslation();
  const location = useLocation<UserSignInResponse>();
  const goToFaq = (anchor?: string) => {
    if (anchor) anchor = "#" + anchor;
    history.push("/site-credentials/faq" + anchor);
  };
  const ANCHOR_NEED_HELP = "";
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<Form>({ mode: "onSubmit", reValidateMode: "onSubmit" });
  const handlePasswordChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    clearErrors();
    setPassword(value);
    setValue("password", value);
    setLabelPasswordVisible(value.length > 0 ? true : false);
  };
  const passwordRecover = async () => {
    setLoading(true);
    try {
      const response = await recoverPasswordService.recoverPassword(
        "api/site-credentials/password/recover/validate-identifier"
      );
      switch (response.kind) {
        case "success":
          history.push(response.value.nextStep.substring(4), response.value);
          break;

        case "error":
          setError("submitError", { message: t("common.unexpected_error") });
      }

      setLoading(false);
    } catch (e) {
      setError("submitError", { message: t("common.unexpected_error") });
      setLoading(false);
    }
  };

  const onSubmit: SubmitHandler<ValidatePassword> = async (data) => {
    setLoading(true);
    try {
      const response = await validatePasswordService.validatePassword(
        {
          password: data.password,
          isEmailOtp: true,
        },
        // "api/site-credentials/password/definition/validate-password"
        location.state != null ? location.state.nextStep : ""
      );

      if (response.kind === "success") {
        history.push(response.value.nextStep.substring(4), {
          ...response.value,
          responseCode: "100000",
        });
      } else if (response.kind === "error") {
        switch (response.code) {
          case "100008":
            setError("password", {
              message: t("password_definition.password_invalid"),
            });
            break;
          case "100017":
            setError("password", {
              message: t("site_credentials.temp_blocked"),
            });
            break;
          case "300002":
            setError("submitError", {
              message: t("common.unexpected_error"),
            });
            break;
          case "100014":
            setError("submitError", {
              message: t("common.unexpected_error"),
            });
            break;
        }
      } else {
        setError("submitError", { message: t("common.unexpected_error") });
      }

      setLoading(false);
    } catch (e) {
      setError("submitError", { message: t("common.unexpected_error") });
      setLoading(false);
    }
  };

  useEffect(() => {
    const passwordValidator = (register: UseFormRegister<Form>) =>
      register("password", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
      });
    passwordValidator(register);
  }, [register, t]);

  return (
    <>
      <form
        id="SetUserPassword"
        autoComplete="on"
        onSubmit={(e) => {
          clearErrors();
          handleSubmit(onSubmit)(e);
        }}
      >
        <div className="row justify-content-center">
          <Header
            title={t(
              "site_credentials.email_association.validate_password.title"
            )}
            subtitle={t(
              "site_credentials.email_association.validate_password.subtitle"
            )}
            showLoading={loading}
            previousStepBackXTimes={1}
          />
        </div>
        <div className="row d-flex justify-content-center">
          <div className="input-outline-eye">
            <SiteCredentialsInput
              labelText={t(
                "site_credentials.email_association.validate_password.password"
              )}
              isFilled={labelPasswordVisible}
              isError={errors.password ? true : false}
              errorMsg={errors.password ? errors.password.message : ""}
              onChange={handlePasswordChange}
              type={"password"}
            />
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="validate-password-resend-code">
            <span
              className="validate-password-resend-code link"
              onClick={passwordRecover}
            >
              {" "}
              {t(
                "site_credentials.email_association.validate_password.forgot_password"
              )}{" "}
            </span>
          </div>
        </div>
        <ErrorMessage error={errors.submitError} />
        <div className="sc-contextual-information-header text-align-center">
          <span
            className="verification-resend-code link"
            onClick={() => goToFaq(ANCHOR_NEED_HELP)}
          >
            {t("common.need_help")}
          </span>
        </div>
        <div className="row d-flex flex-column">
          <Footer />
        </div>
      </form>
    </>
  );
}
