//support https://www.npmjs.com/package/react-mobile-datepicker
import React, { ChangeEvent } from "react";

import CredentialsInput from "../input/Input";
import DatePicker from "react-mobile-datepicker";

type InputProps = {
  labelText: string;
  isError?: boolean;
  errorMsg?: string;
  isFilled: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  confirmText?: string;
  cancelText?: string;
  time?: string;
  birthdate?: string;
  isOpen?: boolean;
  onClick?: () => void;
  onSelect?: (event: ChangeEvent<HTMLInputElement>) => void;
  onCancel?: (event: ChangeEvent<HTMLInputElement>) => void;
};

export default function MobileDatepicker({ ...props }: InputProps) {
  const dateConfig = {
    date: {
      format: "DD",
      caption: "Day",
      step: 1,
    },
    month: {
      format: "MM",
      caption: "Mon",
      step: 1,
    },
    year: {
      format: "YYYY",
      caption: "Year",
      step: 1,
    },
  };

  let actualDate = new Date();

  if (props.value !== undefined) {
    const day = parseInt(props.value.split("/")[0]);
    const month = parseInt(props.value.split("/")[1]);
    const year = parseInt(props.value.split("/")[2]);
    actualDate = new Date(year, month - 1, day);
  }

  const handleKeyPress = (e: React.KeyboardEvent) => {
    e.preventDefault();
  };

  return (
    <>
      <CredentialsInput
        labelText={props.labelText}
        isFilled={props.isFilled}
        isError={props.isError ? true : false}
        errorMsg={props.isError ? props.errorMsg : ""}
        value={props.value}
        onClick={props.onClick}
        onChange={props.onChange}
        onKeyPress={handleKeyPress}
        isDateInput={true}
        onFocus={props.onClick}
        inputMode="none"
      />
      <DatePicker
        value={
          new Date(
            actualDate.getFullYear(),
            actualDate.getMonth(),
            actualDate.getDate()
          )
        }
        isOpen={props.isOpen}
        onSelect={props.onSelect}
        onCancel={props.onCancel}
        confirmText={props.confirmText}
        cancelText={props.cancelText}
        theme="ios"
        showHeader={false}
        dateConfig={dateConfig}
        max={new Date()}
        min={new Date(1900, 0, 1)}
      />
    </>
  );
}
