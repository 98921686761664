import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import OptionsGroup from "../../../components/site-credentials/option-group/OptionsGroup";
import Footer from "../../../components/site-credentials/footer/Footer";
import Header from "../../../components/site-credentials/header/Header";
import { LoyaltyCardAssociationResponse } from "../../../models/site-credentials/loyaltycard/LoyaltyCardAssociationResponse";
import { cardAssociationService } from "../../../services/site-credentials/mobile/CardAssociationService";
import { faPhoneAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons";

export default function LoyaltyCardOtpOptions() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const location = useLocation<LoyaltyCardAssociationResponse>();
  const goToFaq = (anchor?: string) => {
    if (anchor) anchor = "#" + anchor;
    history.push("/site-credentials/faq" + anchor);
  };
  const ANCHOR_NEED_HELP = "";

  const handleOudatedLinkOnClick = async () => {
    setLoading(true);

    const resp = await cardAssociationService.outdatedData(
      "/api/site-credentials/loyalty/cards/outdated-data"
    );

    switch (resp.kind) {
      case "success":
        history.push(resp.value.nextStep.substring(4), resp.value);

        break;
      default:
        // todo add generic error
        break;
    }

    setLoading(false);
  };

  const handleOnSelect = async (option: string) => {
    setLoading(true);

    const resp = await cardAssociationService.sendOtp(
      option,
      location.state != null ? location.state.nextStep : ""
    );

    switch (resp.kind) {
      case "success":
        history.push(resp.value.nextStep.substring(4), resp.value);

        break;
      default:
        // todo add generic error
        break;
    }

    setLoading(false);
  };

  return (
    <div>
      <div className="row justify-content-center">
        <Header
          title={t("card_association_otp_options.header_title")}
          subtitle={t("card_association_otp_options.header_message")}
          showLoading={loading}
        />
      </div>

      <OptionsGroup
        options={location.state.properties.loyaltyCardOtpOptions.map((o) => ({
          icon: o.type === "MobilePhone" ? faPhoneAlt : faEnvelope,
          text: o.contact,
          value: o.type.toString(),
        }))}
        onSelect={handleOnSelect}
      />
      <span onClick={handleOudatedLinkOnClick} className="info-link">
        {t("card_association_otp_options.information_outdated")}
      </span>
      <div className="sc-contextual-information-header text-align-center">
        <span
          className="verification-resend-code link"
          onClick={() => goToFaq(ANCHOR_NEED_HELP)}
        >
          {t("common.need_help")}
        </span>
      </div>
      <div className="row d-flex flex-column">
        <Footer previousStepBackXTimes={1} isSubmitButtonVisible={false} />
      </div>
    </div>
  );
}
