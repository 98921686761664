import React from "react";
import { useTranslation } from "react-i18next";

type ContinenteCredentialsFaqProps = {
  isPage?: boolean;
};

export default function ContinenteCredentialsFaq({
  ...props
}: ContinenteCredentialsFaqProps) {
  const continenteOnline =
    require("../../../assets/images/continente-online.svg") as string;
  const appCC = require("../../../assets/images/app-cc.svg") as string;
  const siga = require("../../../assets/images/siga.svg") as string;
  const wells = require("../../../assets/images/wells.svg") as string;
  const continenteLabs =
    require("../../../assets/images/continente-labs.svg") as string;
  const group = require("../../../assets/images/group.svg") as string;

  const { t } = useTranslation();

  return (
    <>
      <div className="row faq-title">
        <p>{t("continente_credentials_faq.header_title")}</p>
      </div>
      <div className="container group">
        <div className="row align-items-center">
          <div className="col">
            <img
              src={continenteOnline}
              className="atoms-assets-images-novo-login"
              alt=""
            />
          </div>
          <div className="col">
            <img
              src={appCC}
              className="atoms-assets-images-novo-login"
              alt=""
            />
          </div>
          <div className="col">
            <img src={siga} className="atoms-assets-images-novo-login" alt="" />
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col icon-label">{t("common.continente_online")}</div>
          <div className="col icon-label">{t("common.cartao_continente")}</div>
          <div className="col icon-label">{t("common.continente_siga")}</div>
        </div>
        <div className="row align-items-center">
          <div className="col">
            <img
              src={wells}
              className="atoms-assets-images-novo-login"
              alt=""
            />
          </div>
          <div className="col">
            <img
              src={continenteLabs}
              className="atoms-assets-images-novo-login"
              alt=""
            />
          </div>
          <div className="col">
            <img
              src={group}
              className="atoms-assets-images-novo-login"
              alt=""
            />
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col icon-label">{t("common.wells_online")}</div>
          <div className="col icon-label">{t("common.comunidade_labs")}</div>
          <div className="col icon-label">{t("common.plug_charge")}</div>
        </div>
      </div>
      <div className="row faq-contextual-information">
        <p>{t("continente_credentials_faq.message")}</p>
      </div>
    </>
  );
}
