import React from "react";
import { Route } from "react-router";

import IFrame from "./pages/IFrame";
import Layout from "./components/continente-credentials/layout/Layout";
import UserSignIn from "./pages/continente-credentials/UserSignIn";
import DeadEnd from "./pages/continente-credentials/DeadEnd";
import ContactCenter from "./pages/continente-credentials/ContactCenter";
import Help from "./pages/continente-credentials/Help";
import Blocked from "./pages/continente-credentials/Blocked";

import TermsAndConditions from "./pages/continente-credentials/TermsAndConditions";

import LoyaltyCardAssociation from "./pages/continente-credentials/loyaltycard/LoyaltyCardAssociation";
import LoyaltyCardAssociationSuccess from "./pages/continente-credentials/loyaltycard/LoyaltyCardAssociationSuccess";
import LoyaltyCardCreation from "./pages/continente-credentials/loyaltycard/LoyaltyCardCreation";
import LoyaltyCardOtpOptions from "./pages/continente-credentials/loyaltycard/LoyaltyCardOtpOptions";
import LoyaltyCardOtpValidation from "./pages/continente-credentials/loyaltycard/LoyaltyCardOtpValidation";
import LoyaltyCardContactCenter from "./pages/continente-credentials/loyaltycard/LoyaltyCardContactCenter";
import IdentificationVerification from "./pages/continente-credentials/loyaltycard/IdentificationVerification";
import LoyaltyCcvCommunication from "./pages/continente-credentials/loyaltycard/LoyaltyCcvCommunication";

import VerifyOtp from "./pages/continente-credentials/phonenumber/registration/VerifyOtp";
import EmailVerifyOtp from "./pages/continente-credentials/phonenumber/registration/EmailVerifyOtp";
import EmailAssociation from "./pages/continente-credentials/phonenumber/registration/EmailAssociation";
import EmailValidated from "./pages/continente-credentials/phonenumber/registration/EmailValidated";
import SetPassword from "./pages/continente-credentials/phonenumber/registration/SetPassword";
import ValidatePassword from "./pages/continente-credentials/phonenumber/registration/ValidatePassword";

import PhonenumberLoginValidatePassword from "./pages/continente-credentials/phonenumber/login/ValidatePassword";
import PhonenumberLoginValidatePasswordEmailAssociation from "./pages/continente-credentials/phonenumber/login/ValidatePasswordEmailAssociation";
import PhonenumberLoginSetPasswordEmailAssociation from "./pages/continente-credentials/phonenumber/login/SetPasswordEmailAssociation";
import PhonenumberLoginEmailAssociation from "./pages/continente-credentials/phonenumber/login/EmailAssociation";
import PhonenumberLoginVerifyPin from "./pages/continente-credentials/phonenumber/login/VerifyPin";
import PhoneNumberLoginRecoverPin from "./pages/continente-credentials/phonenumber/login/RecoverPin";

import PhonenumberLoginVerifyOtp from "./pages/continente-credentials/phonenumber/login/VerifyOtp";
import PhonenumberLoginEmailAssociationVerifyOtp from "./pages/continente-credentials/phonenumber/login/VerifyOtp";
import AccountAssociationOptions from "./pages/continente-credentials/phonenumber/login/AccountAssociationOptions";

import EmailLoginValidatePassword from "./pages/continente-credentials/email/login/ValidatePassword";
import EmailLoginPhoneAssociation from "./pages/continente-credentials/email/login/PhoneAssociation";
import EmailLoginNewPhoneNumberVerifyOtp from "./pages/continente-credentials/email/login/NewPhoneNumberVerifyOtp";
import EmailLoginPhoneNumberVerifyOtp from "./pages/continente-credentials/email/login/PhoneNumberVerifyOtp";
import EmailLoginPhoneNumberPinVerifyOtp from "./pages/continente-credentials/email/login/PhoneNumberPinVerifyOtp";
import EmailLoginEmailMfaValidation from "./pages/continente-credentials/email/login/EmailLoginEmailMfaValidation";

import ValidatePinPhoneNumberAssociation from "./pages/continente-credentials/email/login/ValidatePinPhoneNumberAssociation";
import ValidatePasswordPhoneNumberAssociation from "./pages/continente-credentials/email/login/ValidatePasswordPhoneNumberAssociation";
import EmailLoginAccountAssociationOptions from "./pages/continente-credentials/email/login/EmailLoginAccountAssociationOptions";
import EmailLoginRecoverPin from "./pages/continente-credentials/email/login/RecoverPin";

import PhonenumberVerifyOtp from "./pages/continente-credentials/email/registration/VerifyOtp";
import EmailSetPassword from "./pages/continente-credentials/email/registration/SetPassword";
import EmailPinSetPassword from "./pages/continente-credentials/email/registration/PinSetPassword";
import PhonenumberSetPassword from "./pages/continente-credentials/email/registration/PhonenumberSetPassword";
import VerifyPin from "./pages/continente-credentials/email/registration/VerifyPin";
import EmailVerifyPassword from "./pages/continente-credentials/email/registration/VerifyPassword";
import EmailRegistrationRecoverPin from "./pages/continente-credentials/email/registration/RecoverPin";

import PasswordRecoverValidateIdentifier from "./pages/continente-credentials/password-recover/ValidateIdentifier";
import PasswordRecoverValidateOtp from "./pages/continente-credentials/password-recover/VerifyOtp";
import PasswordRecoverSetPassword from "./pages/continente-credentials/password-recover/SetPassword";
import PasswordRecoverOtpEmail from "./pages/continente-credentials/password-recover/VerifyOtpEmail";
import SuccessMessage from "./pages/continente-credentials/password-recover/SuccessMessage";
import LoyaltyCardValidation from "./pages/continente-credentials/password-recover/LoyaltyCardValidation";
import PasswordRecoverBlocked from "./pages/continente-credentials/password-recover/Blocked";

import PhoneAssociation from "./pages/continente-credentials/email/registration/PhoneAssociation";
import Faq from "./pages/continente-credentials/Faq";
import PhoneNumberLoginEmailMfaValidation from "./pages/continente-credentials/phonenumber/login/PhoneNumberLoginEmailMfaValidation";
import PhoneNumberRegistrationEmailMfaValidation from "./pages/continente-credentials/phonenumber/registration/PhoneNumberRegistrationEmailMfaValidation";
import AuthorizeSSO from "./pages/continente-credentials/AuthorizeSSO";
import PhoneNumberLoginPhoneNumberMfaValidation from "./pages/continente-credentials/phonenumber/login/PhoneNumberLoginPhoneNumberMfaValidation";
import EmailLoginPhoneNumberMfaValidation from "./pages/continente-credentials/email/login/EmailLoginPhoneNumberMfaValidation";
import EmailRegistrationPhoneNumberMfaValidation from "./pages/continente-credentials/email/registration/EmailRegistrationPhoneNumberMfaValidation";

// TODO: move to SessionMiddleware
const { fetch: originalFetch } = window;

const cookies: Record<string, string> = {};

async function fetch(
  resource: RequestInfo,
  init: RequestInit = {}
): Promise<Response> {
  const cookieNames = Object.keys(cookies);

  if (cookieNames.length > 0) {
    const cookieHeader = cookieNames
      .map((c) => `${c}=${cookies[c]}`)
      .join("; ");

    init.headers = {
      ...init.headers,
      "X-Cookie": cookieHeader,
    };
  }

  const response = await originalFetch(resource, init);

  const setCookieHeader = response.headers.get("X-Set-Cookie");

  if (setCookieHeader) {
    setCookieHeader.split(",").map((cookie, index) => {
      const parts = cookie.split("=");
      if (parts.length >= 2) {
        cookies[parts[0]] = parts[1].split(";")[0];
      }
    });
  }

  return response;
}

window.fetch = fetch;

export default function SdkApp() {
  return (
    <Layout className="cc_max_width">
      <Route path="/iframe" component={IFrame} />
      <Route path="/user-register" component={UserSignIn} />
      <Route path="/dead-end" component={DeadEnd} />
      <Route path="/contact-center" component={ContactCenter} />
      <Route path="/help" component={Help} />
      <Route path="/authorize/sso" component={AuthorizeSSO} />
      <Route
        path="/loyalty/cards/otp-options"
        component={LoyaltyCardOtpOptions}
      />
      <Route
        path="/loyalty/cards/verify-otp"
        component={LoyaltyCardOtpValidation}
      />
      <Route
        path="/card/continente-card-association"
        component={LoyaltyCardAssociation}
      />
      <Route
        path="/card/continente-card-association-success"
        component={LoyaltyCardAssociationSuccess}
      />
      <Route
        path="/card/continente-card-creation"
        component={LoyaltyCardCreation}
      />
      <Route
        path="/card/call-center-loyalty-card"
        component={LoyaltyCardContactCenter}
      />
      <Route
        path="/card/identifier-verification"
        component={IdentificationVerification}
      />
      <Route
        path="/card/loyalty-card-association-success"
        component={LoyaltyCardAssociationSuccess}
      />

      <Route
        path="/phonenumber/registration/verify-otp"
        component={VerifyOtp}
      />
      <Route
        path="/phonenumber/registration/email-verify-otp"
        component={EmailVerifyOtp}
      />
      <Route
        path="/phonenumber/registration/email-association"
        component={EmailAssociation}
      />
      <Route
        path="/phonenumber/registration/email-validated"
        component={EmailValidated}
      />
      <Route
        path="/phonenumber/registration/set-password"
        component={SetPassword}
      />
      <Route
        path="/phonenumber/registration/validate-password"
        component={ValidatePassword}
      />
      <Route
        path="/phonenumber/registration/email-mfa-validation"
        component={PhoneNumberRegistrationEmailMfaValidation}
      />
      <Route
        path="/phonenumber/login/validate-password"
        component={PhonenumberLoginValidatePassword}
      />
      <Route
        path="/phonenumber/login/validate-password-email"
        component={PhonenumberLoginValidatePasswordEmailAssociation}
      />
      <Route
        path="/phonenumber/login/set-password-email"
        component={PhonenumberLoginSetPasswordEmailAssociation}
      />
      <Route
        path="/phonenumber/login/email-association"
        component={PhonenumberLoginEmailAssociation}
      />
      <Route
        path="/phonenumber/login/verify-pin"
        component={PhonenumberLoginVerifyPin}
      />
      <Route
        path="/phonenumber/login/recover-pin"
        component={PhoneNumberLoginRecoverPin}
      />
      <Route
        path="/phonenumber/login/verify-otp"
        component={PhonenumberLoginVerifyOtp}
      />
      <Route
        path="/phonenumber/login/email-association-verify-otp"
        component={PhonenumberLoginEmailAssociationVerifyOtp}
      />
      <Route
        path="/phonenumber/login/loyalty-card-association-option"
        component={AccountAssociationOptions}
      />
      <Route
        path="/phonenumber/login/email-mfa-validation"
        component={PhoneNumberLoginEmailMfaValidation}
      />
      <Route
        path="/phonenumber/login/phonenumber-mfa-validation"
        component={PhoneNumberLoginPhoneNumberMfaValidation}
      />
      <Route
        path="/email/login/validate-password"
        component={EmailLoginValidatePassword}
      />
      <Route
        path="/email/login/phonenumber-association"
        component={EmailLoginPhoneAssociation}
      />
      <Route
        path="/email/login/new-phonenumber-verify-otp"
        component={EmailLoginNewPhoneNumberVerifyOtp}
      />
      <Route
        path="/email/login/phonenumber-verify-otp"
        component={EmailLoginPhoneNumberVerifyOtp}
      />
      <Route
        path="/email/login/phonenumber-pin-verify-otp"
        component={EmailLoginPhoneNumberPinVerifyOtp}
      />
      <Route
        path="/email/login/validate-password-phonenumber"
        component={ValidatePasswordPhoneNumberAssociation}
      />
      <Route
        path="/email/login/validate-pin-phonenumber"
        component={ValidatePinPhoneNumberAssociation}
      />
      <Route path="/email/login/recover-pin" component={EmailLoginRecoverPin} />
      <Route
        path="/email/login/loyalty-card-association-option"
        component={EmailLoginAccountAssociationOptions}
      />
      <Route
        path="/email/login/email-mfa-validation"
        component={EmailLoginEmailMfaValidation}
      />
      <Route
        path="/email/login/phonenumber-mfa-validation"
        component={EmailLoginPhoneNumberMfaValidation}
      />
      <Route
        path="/email/registration/verify-otp"
        component={PhonenumberVerifyOtp}
      />
      <Route
        path="/email/registration/set-password"
        component={EmailSetPassword}
      />
      <Route path="/email/registration/verify-pin" component={VerifyPin} />
      <Route
        path="/email/registration/recover-pin"
        component={EmailRegistrationRecoverPin}
      />
      <Route
        path="/email/registration/phonenumber-association"
        component={PhoneAssociation}
      />
      <Route
        path="/email/registration/verify-password"
        component={EmailVerifyPassword}
      />
      <Route
        path="/email/registration/pin-set-password"
        component={EmailPinSetPassword}
      />
      <Route
        path="/email/registration/phonenumber-set-password"
        component={PhonenumberSetPassword}
      />
      <Route
        path="/email/registration/phonenumber-otp-validation"
        component={PhonenumberVerifyOtp}
      />
      <Route
        path="/email/registration/phonenumber-mfa-validation"
        component={EmailRegistrationPhoneNumberMfaValidation}
      />
      <Route
        path="/password-recover/blocked"
        component={PasswordRecoverBlocked}
      />
      <Route
        path="/password-recover/validate-identifier"
        component={PasswordRecoverValidateIdentifier}
      />
      <Route
        path="/password-recover/validate-otp"
        component={PasswordRecoverValidateOtp}
      />
      <Route
        path="/password-recover/set-password"
        component={PasswordRecoverSetPassword}
      />
      <Route
        path="/password-recover/validate-otp-email"
        component={PasswordRecoverOtpEmail}
      />
      <Route
        path="/password-recover/success-message"
        component={SuccessMessage}
      />
      <Route
        path="/password-recover/loyalty-card-validation"
        component={LoyaltyCardValidation}
      />
      <Route
        path="/card/ccv-communication"
        component={LoyaltyCcvCommunication}
      />
      <Route
        path="/pin-recover/blocked"
        render={() => <Blocked faqAnchor={""} />}
      />
      <Route path="/terms-and-conditions" component={TermsAndConditions} />
      <Route path="/continente-credentials/faq" component={Faq} />
    </Layout>
  );
}
