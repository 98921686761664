//support https://www.npmjs.com/package/react-datepicker
import React, { ChangeEvent } from "react";

import CredentialsInput from "../input/Input";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import pt from "date-fns/locale/pt";
import en from "date-fns/locale/en-GB";
registerLocale("pt", pt);
registerLocale("en", en);

type InputProps = {
  labelText: string;
  isError?: boolean;
  errorMsg?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  value?: Date;
  lang: string;
};

export default function DesktopDatepicker({ ...props }: InputProps) {
  const handleKeyPress = (e: React.KeyboardEvent) => {
    e.preventDefault();
  };

  return (
    <>
      <DatePicker
        locale={props.lang === "" ? "pt" : props.lang}
        selected={props.value}
        onChange={props.onChange}
        customInput={
          <CredentialsInput
            labelText={props.labelText}
            isFilled={true}
            isError={props.isError ? true : false}
            errorMsg={props.isError ? props.errorMsg : ""}
            value={props.value?.toLocaleDateString().trim()}
            onChange={props.onChange}
            onKeyPress={handleKeyPress}
            isDateInput={true}
          />
        }
        dateFormat="dd/MM/yyyy"
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        maxDate={new Date()}
      />
    </>
  );
}
