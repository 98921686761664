import React from "react";

const backspaceKey = "Backspace";

type InputProps = {
  id: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBackspace: () => void;
};

export default function DigitInput(props: InputProps) {
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === backspaceKey) {
      props.onBackspace();
    }
  };

  return (
    <input
      type="text"
      id={props.id}
      name={props.id}
      value={props.value}
      placeholder=""
      className="input-validator"
      onChange={props.onChange}
      onKeyDown={handleKeyDown}
    />
  );
}
