import React, { useState, useContext, useEffect } from "react";
import { useForm, UseFormRegister } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import CredentialsFooter from "../../../../components/continente-credentials/footer/Footer";
import CredentialsHeader from "../../../../components/continente-credentials/header/Header";
import CredentialsForm from "../../../../components/continente-credentials/Form/Form";
import {
  AccountAssociation,
  LoyaltyCardAssociationType,
} from "../../../../models/continente-credentials/mobile/AccountAssociation";
import { FormError } from "../../../../models/continente-credentials/common/FormError";
import { accountAssociationService } from "../../../../services/continente-credentials/mobile/AccountAssociationService";
import { useTranslation } from "react-i18next";
import { PhoneNumberAssociationResponse } from "../../../../models/continente-credentials/mobile/PhoneNumberAssociationResponse";
import BottomSheet from "../../../../components/continente-credentials/bottom-sheet/BottomSheet";
import WhatIsAccountMerge from "../../../../components/continente-credentials/what-is-account-merge/WhatIsAccountMerge";
import { DigitalAssetContext } from "../../../../contexts/continente-credentials/DigitalAssetContext";

import OptionsGroup from "../../../../components/continente-credentials/option-group/OptionsGroup";
import ErrorMessage from "../../../../components/continente-credentials/error-message/error-message";

type Form = AccountAssociation & FormError;

export default function AccountAssociationOptions() {
  const [loading, setLoading] = useState(false);
  const [isBottomSheetOpen, setBottomSheetOpen] = useState(false);
  const { t } = useTranslation();
  const location = useLocation<PhoneNumberAssociationResponse>();
  const history = useHistory();
  const digitalAssetContext = useContext(DigitalAssetContext);
  const COLIcon = require("../../../../assets/images/col_cc.svg") as string;
  const AppCCIcon = require("../../../../assets/images/app_cc.svg") as string;

  const {
    email,
    phoneNumber,
    ccFirstName,
    ccLastName,
    colFirstName,
    colLastName,
  } = location.state?.properties;

  const formState = useForm<Form>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const {
    register,
    setError,
    formState: { errors },
  } = formState;

  const closeBottomSheet = (isClosed: boolean) => {
    setBottomSheetOpen(!isClosed);
  };

  const handleWhatIsAccountMergeClick = () => {
    if (digitalAssetContext.digitalAsset.isMobile) {
      setBottomSheetOpen(true);
    } else {
      window.open("/continentecredentialsfaq", "_blank"); //TODO: rever o link
    }
  };

  const handleOptionClick = (option: string) => {
    switch (option) {
      case "col":
        handleMergeCOLClick();
        break;
      case "app_cc":
        handleMergeAPPClick();
        break;
    }
  };

  const handleMergeCOLClick = () => {
    submit(LoyaltyCardAssociationType.COL);
  };

  const handleMergeAPPClick = () => {
    submit(LoyaltyCardAssociationType.CC);
  };

  const submit = async (associationType: LoyaltyCardAssociationType) => {
    setLoading(true);

    try {
      const response = await accountAssociationService.accountAssociation(
        {
          associationType: associationType,
        },
        location.state != null ? location.state.nextStep : ""
      );

      switch (response.kind) {
        case "success":
          history.push(response.value.nextStep.substring(4), response.value);
          break;
        case "error":
          if (response.code === "100013") {
            setError("submitError", {
              message: t("account_association.invalid_code"),
            });
            break;
          }
          setError("submitError", { message: t("common.unexpected_error") });
          break;

        default:
          setError("submitError", { message: t("common.unexpected_error") });
          break;
      }

      setLoading(false);
    } catch (e) {
      setError("submitError", { message: t("common.unexpected_error") });
      setLoading(false);
    }
  };

  useEffect(() => {
    const accountAssociationOptionValidator = (
      register: UseFormRegister<Form>
    ) =>
      register("associationType", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
        pattern: {
          value: /\b(CC|COL)\b/,
          message: t("account_association.not_correct_option"),
        },
      });
    accountAssociationOptionValidator(register);
  }, [register, t]);

  return (
    <>
      <CredentialsForm id="AccountMerge" autoComplete="off" form={formState}>
        <div className="row justify-content-center">
          <CredentialsHeader
            title={t("account_association.header_title")}
            subtitle={t("account_association.header_message")}
            showLoading={loading}
            previousStepBackXTimes={2}
          />
        </div>
        <div className="row justify-content-center">
          <div className="container">
            <OptionsGroup
              options={[
                {
                  text:
                    colFirstName != null
                      ? t("account_association.option2") +
                        email +
                        t("account_association.option2_1") +
                        " " +
                        colFirstName +
                        " " +
                        colLastName
                      : t("account_association.option2") + email,
                  value: "col",
                  image: () => <img src={COLIcon} alt="Continente online" />,
                },
                {
                  text:
                    ccFirstName != null
                      ? t("account_association.option1") +
                        phoneNumber +
                        t("account_association.option1_1") +
                        " " +
                        ccFirstName +
                        " " +
                        ccLastName
                      : t("account_association.option1") + phoneNumber,
                  value: "app_cc",
                  image: () => <img src={AppCCIcon} alt="App cartão" />,
                },
              ]}
              onSelect={handleOptionClick}
            />
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          <span
            onClick={handleWhatIsAccountMergeClick}
            className="what-is-link"
          >
            {" "}
            {t("account_association.footer_message")}{" "}
          </span>
        </div>
        <ErrorMessage error={errors.submitError} />

        <div className="row d-flex flex-column">
          <CredentialsFooter
            contextualInformationVisible={false}
            footerAppsWebsitesIconsVisible={false}
          />
        </div>
      </CredentialsForm>
      {isBottomSheetOpen && (
        <BottomSheet
          content={<WhatIsAccountMerge />}
          closeBottomSheet={closeBottomSheet}
        />
      )}
    </>
  );
}
