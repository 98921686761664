import React from "react";
import "./group.css";
import { Idm } from "../../../models/test-page/Idm";
import { GetCardAssociationResponse } from "../../../models/test-page/GetCardAssociation";
import { LoyaltyCardCustomer } from "../../../models/test-page/LoyaltyCardCustomer";
import { WhiteListResult } from "../../../models/test-page/WhiteListResult";
import { UserProfile } from "../../../models/test-page/UserProfile";

type InputProps = {
  title?: string;
  idm?: Idm;
  cardAssociation?: GetCardAssociationResponse;
  loyaltyCardCustomer?: LoyaltyCardCustomer;
  whiteListResult?: WhiteListResult;
  userProfile?: UserProfile;
  message?: string;
};

let clientIds = new Map<string, string>();
clientIds.set("1", "APP");
clientIds.set("2", "COL");
clientIds.set("oworMiDdWcp7oHMS", "COL");
clientIds.set("3T9ZGqXg78RKtvw3", "APP");
clientIds.set("LjwMwJGnH4ramfXV", "SIGA");

export default function SubTitle({ ...props }: InputProps) {
  return (
    <>
      <div className="group_box">
        <div className="group_title">
          <p> {props.title?.toUpperCase()}</p>
        </div>

        {props.message === "" ? undefined : (
          <div className="group_message">
            <p>{props.message}</p>
          </div>
        )}

        <div className="group_info">
          {props.idm != null ? (
            Object.entries(props.idm).map(([key, value], index) => {
              if (key !== "profile") {
                return (
                  <p key={index}>
                    <strong>{key} : </strong>
                    {String(value)}
                  </p>
                );
              }
              return <></>;
            })
          ) : (
            <></>
          )}
          {props.idm?.profile != null ? (
            Object.entries(props.idm.profile).map(([key, value], index) => {
              if (key !== "devices" && key !== "roles") {
                return (
                  <p key={index}>
                    <strong>profile.{key} : </strong>
                    {String(value)}
                  </p>
                );
              }
              return <></>;
            })
          ) : (
            <></>
          )}
          {props.idm?.profile?.devices != null ? (
            props.idm.profile.devices.map((x, index) => {
              return (
                <p key={index}>
                  <strong>profile.device: </strong>
                  id: {String(x.id)} addedOn: {x.addedOn}
                </p>
              );
            })
          ) : (
            <></>
          )}
          {props.idm?.profile?.roles != null ? (
            props.idm.profile.roles.map((x, index) => {
              return (
                <p key={index}>
                  <strong>profile.roles: </strong>
                  id: {String(x.id)} name: {x.name}
                </p>
              );
            })
          ) : (
            <></>
          )}
          {props.cardAssociation != null ? (
            Object.entries(props.cardAssociation).map(([key, value], index) => {
              return (
                <p key={index}>
                  <strong>{key} : </strong>
                  {String(value)}
                </p>
              );
            })
          ) : (
            <></>
          )}
        </div>
        {props.loyaltyCardCustomer == null ? undefined : (
          <div className="group_info">
            <p className="group_sub-title">citizenCards: </p>
            <strong>
              {" "}
              {props.loyaltyCardCustomer?.citizenCards != null &&
              props.loyaltyCardCustomer?.citizenCards?.length > 0
                ? props.loyaltyCardCustomer?.citizenCards?.map((m, i) => {
                    return <p key={i}>{m.citizenCardId}</p>;
                  })
                : "no citizen cards"}{" "}
            </strong>
            <p className="group_sub-title">emails: </p>
            <strong>
              {props.loyaltyCardCustomer?.emails != null &&
              props.loyaltyCardCustomer?.emails?.length > 0
                ? props.loyaltyCardCustomer?.emails?.map((m, i) => {
                    return (
                      <p key={i}>{"email: " + m.email + "| type: " + m.type}</p>
                    );
                  })
                : "no emails"}{" "}
            </strong>
            <p className="group_sub-title">phoneNumbers: </p>
            <strong>
              {props.loyaltyCardCustomer?.phoneNumbers != null &&
              props.loyaltyCardCustomer?.phoneNumbers?.length > 0
                ? props.loyaltyCardCustomer?.phoneNumbers?.map((m, i) => {
                    return (
                      <p key={i}>
                        {"phoneNumber: " + m.phoneNumber + "| type: " + m.type}
                      </p>
                    );
                  })
                : "no phone numbers"}{" "}
            </strong>
          </div>
        )}

        {props.whiteListResult == null ? undefined : (
          <div className="group_info">
            <p className="group_sub-title">clientIds: </p>3
            <strong>
              {" "}
              {props.whiteListResult?.clientIds != null &&
              props.whiteListResult?.clientIds?.length > 0
                ? props.whiteListResult?.clientIds?.map((m, i) => {
                    if (m == null) {
                      return <p key={i}>All</p>;
                    }
                    return <p key={i}>{clientIds.get(m) ?? m}</p>;
                  })
                : "no clientIds"}{" "}
            </strong>
          </div>
        )}

        {props.userProfile == null ? undefined : (
          <div className="group_info">
            {props.userProfile?.properties?.map((m, i) => {
              return (
                <p key={i}>
                  <strong>{m.name} : </strong> {String(m.value)}
                </p>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
}
