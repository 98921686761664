import React, { useState, useContext } from "react";
import Header from "../../../components/site-credentials/header/Header";
import CredentialsButton from "../../../components/site-credentials/button/Button";
import { useTranslation } from "react-i18next";
import CallCenterSvg from "../../../assets/images/call-center.svg";
import {
  ContactCenterRequest,
  ContactCenterOption,
} from "../../../models/site-credentials/loyaltycard/ContactCenterRequest";
import { useLocation, useHistory } from "react-router-dom";
import { IdentifierValidationResponse } from "../../../models/site-credentials/loyaltycard/IdentifierValidationResponse";
import { loyaltyCardService } from "../../../services/site-credentials/loyaltycard/LoyaltyCardService";
import { DigitalAssetContext } from "../../../contexts/site-credentials/DigitalAssetContext";
import ErrorMessage from "../../../components/continente-credentials/error-message/error-message";

export default function LoyaltyCardContactCenter() {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation<IdentifierValidationResponse>();
  const [loading, setLoading] = useState(false);
  const digitalAssetContext = useContext(DigitalAssetContext);
  const [error, setError] = useState("");
  const goToFaq = (anchor?: string) => {
    if (anchor) anchor = "#" + anchor;
    history.push("/site-credentials/faq" + anchor);
  };
  const ANCHOR_NEED_HELP = "";

  const handleChoosedOption = async (
    contactCenterOption: ContactCenterOption
  ) => {
    setLoading(true);

    const contactCenterRequest: ContactCenterRequest = {
      contactCenterOption: contactCenterOption,
    };

    if (
      contactCenterRequest.contactCenterOption === ContactCenterOption.CALL &&
      digitalAssetContext.digitalAsset.isMobile === true
    ) {
      await window.open("tel:+911111111", "_self");
    }

    try {
      const response = await loyaltyCardService.loyaltyCardCallCenterOption(
        contactCenterRequest,
        location.state != null ? location.state.nextStep : ""
      );
      if (response.kind === "success") {
        history.push(response.value.nextStep.substring(4), response.value);
      }
      if (response.kind === "error") {
        switch (response.code) {
          case "300007":
            setError(t("identification_verification.input_error"));
            break;
          default:
            setError(t("common.unexpected_error"));
        }
      } else {
        setError(t("common.unexpected_error"));
      }

      setLoading(false);
    } catch (e) {
      setError(t("common.unexpected_error"));
      setLoading(false);
    }
  };
  return (
    <>
      <div className="row justify-content-center">
        <Header
          title={t("call_center_loyalty_card.header_title")}
          subtitle={t("call_center_loyalty_card.header_message")}
          showLoading={loading}
        />
      </div>

      <div className="row justify-content-center">
        <img src={CallCenterSvg} className="call-center-loyalty-card" alt="" />
      </div>

      <div className="row d-flex justify-content-center">
        <CredentialsButton
          text={t("call_center_loyalty_card.button_call_center")}
          type="button"
          size="large"
          onClick={() => handleChoosedOption(ContactCenterOption.CALL)}
        />
      </div>

      <div className="row d-flex justify-content-center">
        <span
          onClick={() =>
            handleChoosedOption(ContactCenterOption.ANOTHER_LOYALTY_CARD)
          }
          className="span-options"
        >
          {" "}
          {t("call_center_loyalty_card.another_card")}{" "}
        </span>
      </div>
      <div className="row d-flex justify-content-center">
        <span
          onClick={() =>
            handleChoosedOption(ContactCenterOption.NEW_LOYALTY_CARD)
          }
          className="span-options"
        >
          {" "}
          {t("call_center_loyalty_card.new_card")}{" "}
        </span>
      </div>
      <div className="row d-flex justify-content-center">
        <span
          onClick={() => handleChoosedOption(ContactCenterOption.HOME)}
          className="span-options"
        >
          {" "}
          {t("call_center_loyalty_card.home")}{" "}
        </span>
      </div>
      <ErrorMessage errorString={error} />
      <div className="contextual-information-header text-align-center">
        <span
          className="verification-resend-code link"
          onClick={() => goToFaq(ANCHOR_NEED_HELP)}
        >
          {t("common.need_help")}
        </span>
      </div>
    </>
  );
}
