import React, { ChangeEvent, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

type InputProps = {
  labelText: string;
  isError?: boolean;
  errorMsg?: string;
  errorMsgBlocked?: string;
  isFilled: boolean;
  type?: "password" | "text";
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  disabled?: boolean;
  showCheckIcon?: boolean;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  showError?: boolean;
  autoComplete?: string;
  onKeyPress?: (e: React.KeyboardEvent) => void;
  isDateInput?: boolean;
  placeholder?: string;
  name?: string;
  inputMode?:
    | "text"
    | "search"
    | "email"
    | "tel"
    | "url"
    | "none"
    | "numeric"
    | "decimal"
    | undefined;
};

export default function Input({
  showError = true,
  inputMode = "text",
  ...props
}: InputProps) {
  const [isHidden, setIsHidden] = useState<boolean>(props.type === "password");

  const check = require("../../../assets/images/check2-min.svg") as string;
  const alertCircle =
    require("../../../assets/images/alert-circle.svg") as string;

  const clickEye = () => {
    setIsHidden((state) => {
      return !state;
    });
  };
  return (
    <>
      <div className={`container-input ${showError ? "margin-bottom-12" : ""}`}>
        <input
          placeholder={props.placeholder}
          title={props.labelText}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          type={!isHidden ? "text" : props.type}
          className="input-fields"
          value={props.value}
          onChange={props.onChange}
          disabled={props.disabled}
          onClick={props.onClick}
          onKeyPress={props.onKeyPress}
          onKeyDown={(e: React.KeyboardEvent) => {
            if (props.isDateInput) {
              e.preventDefault();
            }
          }}
          autoComplete={props.autoComplete}
          name={props.name}
          id={props.name}
          inputMode={inputMode}
        />
        <label className={`label-input ${props.isFilled ? "filled" : ""}`}>
          {props.labelText}
        </label>
        {showError && (
          <div className="d-inline-flex errorDiv">
            {props.isError && (
              <img src={alertCircle} alt="" className="alert-cicle" />
            )}
            <span className="errorLabel">{props.errorMsg}</span>
            <span className="errorLabel-blocked">{props.errorMsgBlocked}</span>
          </div>
        )}
        {props.type === "password" && isHidden && (
          <div className="input-icon-eye">
            <FontAwesomeIcon icon={faEye} onClick={clickEye} />
          </div>
        )}
        {props.type === "password" && !isHidden && (
          <div className="input-icon-eye">
            <FontAwesomeIcon icon={faEyeSlash} onClick={clickEye} />
          </div>
        )}
        {props.showCheckIcon && (
          <img src={check} alt="" className="input-check" />
        )}
      </div>
    </>
  );
}
