import { useLocation } from "react-router";

import { AuthorizeSSOQueryParams } from "../../models/continente-credentials/common/AuthorizeSSOQueryParams";

export const useAuthorizeSSOQueryParams = (): AuthorizeSSOQueryParams => {
  const urlSearchParams = new URLSearchParams(useLocation().search);

  return {
    clientId: urlSearchParams.get("clientId"),
    sessionId: urlSearchParams.get("sessionId"),
    ssoToken: urlSearchParams.get("ssoToken"),
  };
};
