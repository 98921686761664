import { PhoneNumberMfaValidation } from "../../../models/site-credentials/mobile/PhoneNumberMfaValidation";
import { PhoneNumberMfaValidationResponse } from "../../../models/site-credentials/mobile/PhoneNumberMfaValidationResponse";
import { Response } from "../../../models/site-credentials/common/Response";

export interface IPhoneNumberMfaValidationService {
  PhoneNumberMfaValidation(
    PhoneNumberMfaValidation: PhoneNumberMfaValidation,
    phoneNumberMfaValidationUrl: string
  ): Promise<Response<PhoneNumberMfaValidationResponse>>;
}

export class PhoneNumberMfaValidationService
  implements IPhoneNumberMfaValidationService
{
  async PhoneNumberMfaValidation(
    PhoneNumberMfaValidation: PhoneNumberMfaValidation,
    phoneNumberMfaValidationUrl: string
  ): Promise<Response<PhoneNumberMfaValidationResponse>> {
    const resp = await fetch(phoneNumberMfaValidationUrl, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(PhoneNumberMfaValidation),
    });
    if (resp.ok) {
      return { kind: "success", value: await resp.json() };
    } else if (resp.status === 400) {
      const error = await resp.json();

      const returnedError = error[0];

      return {
        kind: "error",
        code: returnedError.Code,
        message: returnedError.message,
      };
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }
}

export const phoneNumberMfaValidationService =
  new PhoneNumberMfaValidationService();
