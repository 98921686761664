import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";
import { useForm, SubmitHandler, UseFormRegister } from "react-hook-form";
import React, { useEffect, useState, ChangeEvent, useContext } from "react";

import Checkbox from "../../../components/site-credentials/checkbox/Checkbox";
import UsernameInput from "../../../components/site-credentials/username-input/UsernameInput";
import CredentialsInput from "../../../components/site-credentials/input/input";
import MobileDatePicker from "../../../components/site-credentials/mobile-date-picker/mobile-date-picker";
import DesktopDatePicker from "../../../components/site-credentials/desktop-date-picker/desktop-date-picker";
import Footer from "../../../components/site-credentials/footer/Footer";
import Header from "../../../components/site-credentials/header/Header";
import { FormError } from "../../../models/site-credentials/common/FormError";
import { LangContext } from "../../../contexts/site-credentials/LangContext";
import { CardCreation } from "../../../models/site-credentials/loyaltycard/CardCreation";
import { DigitalAssetContext } from "../../../contexts/site-credentials/DigitalAssetContext";
import { cardCreationService } from "../../../services/site-credentials/loyaltycard/CardCreationService";
import { CardAssociationResponse } from "../../../models/site-credentials/loyaltycard/CardAssociationResponse";
import { validatePortuguesePhoneNumber } from "../../../utils/validatePortuguesePhoneNumber";

type Form = CardCreation & FormError;

export default function LoyaltyCardCreation() {
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation<CardAssociationResponse>();
  const [dateLocation, setDateLocation] = useState("pt-PT");
  const [labelNameVisible, setLabelNameVisible] = useState<boolean>(false);
  const [labelEmailVisible, setLabelEmailVisible] = useState<boolean>(false);
  const [labelPhoneNumberVisible] = useState<boolean>(true);
  const [labelBirthdateVisible, setLabelBirthdateVisible] =
    useState<boolean>(true);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [isEmailAlreadySet, setIsEmailAlreadySet] = useState<boolean>(false);
  const [isPhoneNumberAlreadySet, setIsPhoneNumberAlreadySet] =
    useState<boolean>(false);

  const [countryCode, setCountryCode] = useState("");
  const [birthdate, setBirthdate] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const langContext = useContext(LangContext);
  const digitalAssetContext = useContext(DigitalAssetContext);

  const [startDate, setStartDate] = useState<Date | undefined>();
  const [time, setTime] = useState<string>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [checkGeneralConditions, setCheckGeneralConditions] = useState(false);

  const ALLOWED_AGE_TO_CREATE_CARD = 16;

  const handleClick = () => {
    setIsOpen(true);
  };
  const handleCancel = () => {
    setIsOpen(false);
  };
  const handleSelect = (e: any) => {
    clearErrors();

    setTime(e);
    setIsOpen(false);
    setBirthdate(e.toLocaleDateString(dateLocation));
    setLabelBirthdateVisible(
      e.toLocaleDateString(dateLocation).length > 0 ? true : false
    );
    setValue("birthdate", e.toLocaleDateString(dateLocation).trim());
  };

  const handleDesktopDatePickerSelect = (e: any) => {
    clearErrors();
    setBirthdate(e.toLocaleDateString(dateLocation));
    setLabelBirthdateVisible(
      e.toLocaleDateString(dateLocation).length > 0 ? true : false
    );
    setValue("birthdate", e.toLocaleDateString(dateLocation).trim());
    setStartDate(e);
  };

  const goToFaq = (anchor?: string) => {
    if (anchor) anchor = "#" + anchor;
    history.push("/site-credentials/faq" + anchor);
  };

  const GENERAL_CONDITIONS = "";

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm<Form>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: {
      name: "",
    },
  });

  const handleCheckGeneralConditionsChange = () => {
    setValue("checkGeneralConditions", !checkGeneralConditions);
    setCheckGeneralConditions((state) => {
      return !state;
    });
  };

  const handlePhoneNumberChange = (
    input: string,
    phoneNumberCountryCode: string
  ) => {
    setValue(
      "phoneNumber",
      input.trim() !== "" ? `${phoneNumberCountryCode}${input}`.trim() : ""
    );
    setCountryCode(phoneNumberCountryCode);
    setPhoneNumber(input.trim());
    clearErrors();
  };

  useEffect(() => {
    const nameValidator = (register: UseFormRegister<Form>) =>
      register("name", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
        pattern: {
          value: /^([a-zA-ZÀ-ú']{2,40}(\s+|$)){2,}$/,
          message: t("site_credentials.add_card.invalid_name"),
        },
      });
    const emailValidator = (register: UseFormRegister<Form>) =>
      register("email", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
        pattern: {
          value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
          message: t("site_credentials.add_card.invalid_email"),
        },
      });
    const phoneNumberValidator = (register: UseFormRegister<Form>) =>
      register("phoneNumber", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
        pattern: {
          value: /^\+?\d*$/,
          message: t("site_credentials.add_card.invalid_phonenumber"),
        },
        validate: {
          valid: (phoneNumber) =>
            validatePortuguesePhoneNumber(phoneNumber) ||
            t("common.error_invalid_pt_number"),
        },
      });
    const birthdateValidator = (register: UseFormRegister<Form>) =>
      register("birthdate", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
        pattern: {
          value: /^[0-9]{2}[-|\/]{1}[0-9]{2}[-|\/]{1}[0-9]{4}$/,
          message: "",
        },
        validate: {
          yearsOld: (v) =>
            getAge(v) >= ALLOWED_AGE_TO_CREATE_CARD ||
            t("site_credentials.add_card.invalid_age"),
        },
      });

    const getAge = (inputBirthdate: string) => {
      const day = parseInt(inputBirthdate.split("/")[0]);
      const month = parseInt(inputBirthdate.split("/")[1]) - 1;
      const year = parseInt(inputBirthdate.split("/")[2]);

      const today = new Date();
      const birthDate = new Date(year, month, day);

      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      return Number.isInteger(age) ? age : 0;
    };

    const checkGeneralConditionsValidator = (register: UseFormRegister<Form>) =>
      register("checkGeneralConditions", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
      });

    nameValidator(register);
    emailValidator(register);
    phoneNumberValidator(register);
    birthdateValidator(register);
    checkGeneralConditionsValidator(register);
  }, [register, t]);

  useEffect(() => {
    clearErrors();
    if (
      location?.state?.properties?.email !== null &&
      location?.state?.properties?.email?.trim() !== ""
    ) {
      const emailAux = location?.state?.properties?.email?.trim();
      setValue("email", emailAux);
      setEmail(emailAux);

      setIsEmailAlreadySet(true);
    }

    if (
      location?.state?.properties?.phoneNumber !== null &&
      location?.state?.properties?.phoneNumber?.trim() !== ""
    ) {
      handlePhoneNumberChange(
        location.state.properties?.phoneNumber,
        location.state.properties?.phoneCountryCode ?? ""
      );
      setIsPhoneNumberAlreadySet(true);
    }

    setLabelEmailVisible(location?.state?.properties?.email?.trim().length > 0);

    switch (langContext.lang.code) {
      case "pt":
        setDateLocation("pt-PT");
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNameChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    clearErrors();
    setValue("name", value.trim());
    setName(value);
    setLabelNameVisible(value.length > 0 ? true : false);
  };

  const handleEmailChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    clearErrors();
    setValue("email", value.trim());
    setEmail(value);
    setLabelEmailVisible(value.length > 0 ? true : false);
  };

  const onSubmit: SubmitHandler<CardCreation> = async (data) => {
    setLoading(true);
    try {
      const response = await cardCreationService.cardCreation(
        {
          birthdate: data.birthdate,
          email: data.email,
          name: data.name,
          countryCode: countryCode,
          phoneNumber: phoneNumber,
          checkGeneralConditions: checkGeneralConditions,
        },
        "/api/site-credentials/card/continente-card-creation"
      );
      switch (response.kind) {
        case "success": {
          history.push(response.value.nextStep.substring(4), {
            ...response.value,
            responseCode: "100002",
          });
          break;
        }
        case "error":
          if (response.code === "100013") {
            setError("submitError", {
              message: t("card_association.invalid_code"),
            });
            break;
          }
          setError("submitError", { message: t("common.unexpected_error") });
          break;
        default:
          setError("submitError", { message: t("common.unexpected_error") });
          break;
      }
      setLoading(false);
    } catch (e) {
      setError("submitError", { message: t("common.unexpected_error") });
      setLoading(false);
    }
  };

  return (
    <>
      <form
        id="ContinenteCardCreation"
        autoComplete="on"
        onSubmit={(e) => {
          clearErrors();
          handleSubmit(onSubmit)(e);
        }}
      >
        <div className="row justify-content-center">
          <Header
            title={t("site_credentials.add_card.header_title")}
            subtitle={t("site_credentials.add_card.header_message")}
            showLoading={loading}
            previousStepBackXTimes={1}
          />
        </div>
        <div className="row d-flex justify-content-center">
          <CredentialsInput
            labelText={t("site_credentials.add_card.name_input")}
            isFilled={labelNameVisible}
            isError={errors.name ? true : false}
            errorMsg={errors.name ? errors.name.message : ""}
            onChange={handleNameChange}
            value={name}
          />
          <CredentialsInput
            labelText={t("site_credentials.add_card.email_input")}
            isFilled={labelEmailVisible}
            isError={errors.email ? true : false}
            errorMsg={errors.email ? errors.email.message : ""}
            disabled={isEmailAlreadySet}
            onChange={handleEmailChange}
            value={email}
          />
          <div className="container-input margin-bottom-12">
            <UsernameInput
              type="phone"
              isFilled={labelPhoneNumberVisible}
              isError={errors.phoneNumber ? true : false}
              errorMsg={errors.phoneNumber ? errors.phoneNumber.message : ""}
              onChange={handlePhoneNumberChange}
              disabled={isPhoneNumberAlreadySet}
              value={phoneNumber}
              defaultPhoneCountryCode={
                countryCode === "" ? "+351" : countryCode
              }
            />
          </div>
          {digitalAssetContext.digitalAsset.isMobile && (
            <MobileDatePicker
              labelText={t("site_credentials.add_card.dob_input")}
              isFilled={labelBirthdateVisible}
              isError={errors.birthdate ? true : false}
              errorMsg={errors.birthdate ? errors.birthdate.message : ""}
              value={birthdate}
              confirmText={t("site_credentials.add_card.confirm")}
              cancelText={t("site_credentials.add_card.cancel")}
              onSelect={handleSelect}
              onCancel={handleCancel}
              onClick={handleClick}
              isOpen={isOpen}
              time={time}
            />
          )}
          {!digitalAssetContext.digitalAsset.isMobile && (
            <DesktopDatePicker
              labelText={t("site_credentials.add_card.dob_input")}
              isError={errors.birthdate ? true : false}
              errorMsg={errors.birthdate ? errors.birthdate.message : ""}
              value={startDate}
              onChange={handleDesktopDatePickerSelect}
              lang={langContext.lang.code}
            />
          )}
        </div>

        <Checkbox
          onClick={handleCheckGeneralConditionsChange}
          isError={errors.checkGeneralConditions ? true : false}
          label=""
        />
        <div className="check-general-conditions-div">
          {t("common.check_general_conditions_message1")}{" "}
          <span
            onClick={() => goToFaq(GENERAL_CONDITIONS)}
            className="button-as-link"
          >
            <b>{t("common.check_general_conditions_message2")}</b>
          </span>
        </div>

        <div className="d-flex flex-column">
          <Footer isDisabled={checkGeneralConditions ? false : true} />
        </div>
      </form>
    </>
  );
}
