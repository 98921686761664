import React, { useEffect, useContext, useState, useCallback } from "react";
import { useForm, SubmitHandler, UseFormRegister } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import CredentialsFooter from "../../../components/continente-credentials/footer/Footer";
import CredentialsHeader from "../../../components/continente-credentials/header/Header";
import CredentialsForm from "../../../components/continente-credentials/Form/Form";
import { PasswordRecoverValidateIdentifierResponse } from "../../../models/continente-credentials/mobile/PasswordRecoverValidateIdentifierResponse";
import { FormError } from "../../../models/continente-credentials/common/FormError";
import { passwordRecoverService } from "../../../services/continente-credentials/mobile/PasswordRecoverService";
import { useTranslation } from "react-i18next";
import { PasswordRecoverValidateIdentifier } from "../../../models/continente-credentials/mobile/PasswordRecoverValidateIdentifier";
import { errorService } from "../../../services/continente-credentials/common/ErrorService";
import UsernameInput from "../../../components/continente-credentials/username-input/UsernameInput";
import ErrorMessage from "../../../components/continente-credentials/error-message/error-message";
import { UserContext } from "../../../contexts/continente-credentials/UserContext";
import MantraButton from "../../../components/continente-credentials/mantra-button/MantraButton";

type Form = PasswordRecoverValidateIdentifier & FormError;

export default function ValidateIdentifier() {
  const userContext = useContext(UserContext);
  const history = useHistory();
  const [labelIdentifierVisible, setLabelIdentifierVisible] =
    useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const location = useLocation<PasswordRecoverValidateIdentifierResponse>();
  const [identifier, setIdentifier] = useState<string>(
    location.state != null
      ? location.state.properties.email != null
        ? location.state.properties.email
        : location.state.properties.phoneNumber != null
        ? location.state.properties.phoneNumber
        : ""
      : ""
  );
  const email = location.state != null ? location.state.properties.email : "";
  const phoneNumber =
    location.state != null ? location.state.properties.phoneNumber : "";
  const goToFaq = (anchor?: string) => {
    if (anchor) anchor = "#" + anchor;
    history.push("/continente-credentials/faq" + anchor);
  };
  const ANCHOR_NEED_HELP = "";
  const formState = useForm<Form>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = formState;

  const onSubmit: SubmitHandler<PasswordRecoverValidateIdentifier> = async (
    data
  ) => {
    setLoading(true);

    try {
      const response = await passwordRecoverService.validateIdentifier(
        {
          identifier: data.identifier,
          nextStep: "",
        },
        location.state != null ? location.state.nextStep : ""
      );

      switch (response.kind) {
        case "success":
          history.push(response.value.nextStep.substring(4), response.value);
          break;

        case "error": {
          const errorMessage = errorService.errorMessage(response.code);
          setError("submitError", { message: errorMessage });
          break;
        }
        default:
          setError("submitError", { message: t("common.unexpected_error") });
          break;
      }

      setLoading(false);
    } catch (e) {
      setError("submitError", { message: t("common.unexpected_error") });
      setLoading(false);
    }
  };

  useEffect(() => {
    const passwordValidator = (register: UseFormRegister<Form>) =>
      register("identifier", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
      });
    passwordValidator(register);
  }, [register, t]);

  useEffect(() => {
    let value = "";
    value =
      location.state != null
        ? location.state.properties.email != null
          ? location.state.properties.email
          : location.state.properties.phoneNumber != null
          ? location.state.properties.phoneNumber
          : userContext.user.primaryContact === "phoneNumber"
          ? phoneNumber ??
            `${userContext.user.phoneNumberCode}${userContext.user.phoneNumber}`.trim()
          : email ?? userContext.user.email
        : userContext.user.primaryContact === "phoneNumber"
        ? phoneNumber ??
          `${userContext.user.phoneNumberCode}${userContext.user.phoneNumber}`.trim()
        : email ?? userContext.user.email;

    clearErrors();
    setValue("identifier", value);
    setIdentifier(value);
    setLabelIdentifierVisible(value.length > 0 ? true : false);
  }, [
    clearErrors,
    email,
    location.state,
    phoneNumber,
    setValue,
    userContext.user.email,
    userContext.user.phoneNumber,
    userContext.user.phoneNumberCode,
    userContext.user.primaryContact,
  ]);

  const handleEmailOrPhoneNumberChange = useCallback(
    (input: string, phoneNumberCode: string) => {
      clearErrors();
      const value = isNumber(input) ? `${phoneNumberCode}${input}` : input;

      setIdentifier(value);
      setValue("identifier", value);
      setLabelIdentifierVisible(value.length > 0 ? true : false);
    },
    [clearErrors, setValue]
  );

  function isNumber(input: string) {
    return !isNaN(Number(input)) && input.length > 2;
  }
  return (
    <>
      <CredentialsForm
        id="PasswordRecover"
        form={formState}
        autoComplete="on"
        onSubmit={(e) => {
          clearErrors();
          handleSubmit(onSubmit)(e);
        }}
      >
        <div className="row justify-content-center">
          <CredentialsHeader
            title={t("password_recover.header_title")}
            subtitle={t("password_recover.header_message")}
            showLoading={loading}
            previousStepBackXTimes={1}
          />
        </div>
        <div className="row d-flex justify-content-center">
          <UsernameInput
            type="email-phone"
            isFilled={labelIdentifierVisible}
            value={identifier}
            isError={errors.identifier ? true : false}
            errorMsg={errors.identifier ? errors.identifier.message : ""}
            onChange={handleEmailOrPhoneNumberChange}
            autocompleteWith={
              location.state != null
                ? location.state.properties.email != null
                  ? "email"
                  : location.state.properties.phoneNumber != null
                  ? "phone"
                  : "primaryContact"
                : "primaryContact"
            }
          />
        </div>
        <ErrorMessage error={errors.submitError} />
        <div className="row d-flex justify-content-center">
          <MantraButton
            text={t("password_definition.button_next_message")}
            type="submit"
          />
        </div>
        <div className="contextual-information-header text-align-center">
          <span
            className="verification-resend-code link"
            onClick={() => goToFaq(ANCHOR_NEED_HELP)}
          >
            {t("common.need_help")}
          </span>
        </div>
        <div className="row d-flex flex-column">
          <CredentialsFooter />
        </div>
      </CredentialsForm>
    </>
  );
}
