import { Config } from "../../../config/Config";
import { DigitalAsset } from "../../../models/continente-credentials/common/DigitalAsset";
import { Response } from "../../../models/continente-credentials/common/Response";

export interface IDigitalAssetService {
  getDigitalAsset(clientId: string): Promise<Response<DigitalAsset>>;
}

export class DigitalAssetService implements IDigitalAssetService {
  async getDigitalAsset(clientId: string): Promise<Response<DigitalAsset>> {
    const url =
      Config.ContinenteCredentials.digitalAssetUrl + "?clientId=" + clientId;

    const resp = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json, text/plain, */*",
      },
    });

    if (resp.ok) {
      return { kind: "success", value: await resp.json() };
    } else if (resp.status === 400) {
      const error = await resp.json();

      let errorCode: string = "0";
      let errorMessage: string = "";
      if (error.length > 0) {
        if (error[0].Code) {
          errorCode = error[0].Code.toString();
          errorMessage = error[0].Message ? error[0].Message.toString() : "";
        }
      }

      return {
        kind: "error",
        code: errorCode,
        message: errorMessage,
      };
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }
}

export const digitalAssetService = new DigitalAssetService();
