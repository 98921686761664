import React from "react";
import Header from "../../../components/site-credentials/header/Header";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import callCenter from "../../../assets/images/call-center.svg";

export default function Blocked() {
  const { t } = useTranslation();
  const history = useHistory();

  const ANCHOR_NEED_HELP = "";

  const goToFaq = (anchor?: string) => {
    if (anchor) anchor = "#" + anchor;
    history.push("/site-credentials/faq" + anchor);
  };

  const goToMenu = () => {
    history.push("/site-credentials");
  };

  return (
    <>
      <form id="ContactCenter" autoComplete="off">
        <div className="row justify-content-center">
          <Header
            title={t("password_recover_blocked.header_title")}
            subtitle={t("password_recover_blocked.subtitle")}
            isTextAlignCenter
          />
        </div>
      </form>
      <div className="row justify-content-center">
        <img src={callCenter} className="contact-center-icon" alt="" />
      </div>
      <div className="sc-contextual-information-header text-align-center">
        <span
          className="verification-resend-code link"
          onClick={() => goToFaq(ANCHOR_NEED_HELP)}
        >
          {t("common.need_help")}
        </span>
      </div>

      <div className="sc-contextual-information-header text-align-center">
        <span
          className="verification-resend-code link"
          onClick={() => goToMenu()}
        >
          {t("common.back")}
        </span>
      </div>
    </>
  );
}
