import { PinValidation } from "../../../models/continente-credentials/mobile/PinValidation";
import { PinValidationResponse } from "../../../models/continente-credentials/mobile/PinValidationResponse";
import { Response } from "../../../models/continente-credentials/common/Response";

export interface IPinValidationService {
  pinValidation(
    pinValidation: PinValidation,
    pinValidationUrl: string
  ): Promise<Response<PinValidationResponse>>;
}

export class PinValidationService implements IPinValidationService {
  async pinValidation(
    pinValidation: PinValidation,
    pinValidationUrl: string
  ): Promise<Response<PinValidationResponse>> {
    const resp = await fetch(pinValidationUrl, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(pinValidation),
    });
    if (resp.ok) {
      return { kind: "success", value: await resp.json() };
    } else if (resp.status === 400) {
      const error = await resp.json();

      const returnedError = error.find((c: any) => c.Code != null);
      return {
        kind: "error",
        code: returnedError.Code ? returnedError.Code : 0,
        message: returnedError.message ? returnedError.message : "",
      };
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }
}

export const pinValidationService = new PinValidationService();
