import { MfaValidationRequest } from "../../../models/continente-credentials/common/MfaValidationRequest";
import { MfaValidationResponse } from "../../../models/continente-credentials/common/MfaValidationResponse";
import { OtpResend } from "../../../models/continente-credentials/common/OtpResend";
import { Response } from "../../../models/continente-credentials/common/Response";

export interface IMfaService {
  ValidateMfaChallenge(
    MfaValidationRequest: MfaValidationRequest
  ): Promise<Response<MfaValidationResponse>>;
  ResendMfaChalllenge(useername: string): Promise<Response<OtpResend>>;
}

export class MfaService implements IMfaService {
  async ValidateMfaChallenge(
    MfaValidationRequest: MfaValidationRequest
  ): Promise<Response<MfaValidationResponse>> {
    const resp = await fetch(MfaValidationRequest.requestUrl, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ code: MfaValidationRequest.code }),
    });

    if (resp.ok) {
      return { kind: "success", value: await resp.json() };
    } else if (resp.status === 400) {
      const error = await resp.json();

      const returnedError = error.find((c: any) => c.Code != null);
      return {
        kind: "error",
        code: returnedError.Code ? returnedError.Code : 0,
        message: returnedError.message ? returnedError.message : "",
      };
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }

  async ResendMfaChalllenge(username: string): Promise<Response<OtpResend>> {
    const resp = await fetch("api/mfa/resend", {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username: username }),
    });

    if (resp.ok) {
      return { kind: "success", value: await resp.json() };
    } else if (resp.status === 400) {
      const error = await resp.json();

      const returnedError = error.find((c: any) => c.Code != null);
      return {
        kind: "error",
        code: returnedError.Code ? returnedError.Code : 0,
        message: returnedError.message ? returnedError.message : "",
      };
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }
}

export const mfaService = new MfaService();
