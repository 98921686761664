import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";

type CheckBulletProps = {
  checked: boolean;
  text: string;
};

const defaultProps = {
  checked: false,
};

function CheckBullet(props: CheckBulletProps & typeof defaultProps) {
  return (
    <div className="check-bullet d-inline-flex align-items-center">
      {props.checked ? (
        <FontAwesomeIcon icon={faCheckCircle} />
      ) : (
        <FontAwesomeIcon icon={faCircle} />
      )}
      <span className="">{props.text}</span>
    </div>
  );
}

CheckBullet.defaultProps = defaultProps;

export default CheckBullet;
