import React from "react";
import TicketBorder from "../ticket-border/TicketBorder";

type HeaderInputs = {
  title: string;
};

export default function Header({ title }: HeaderInputs) {
  const logocontinente = require("../../assets/images/logo-continente.png");
  return (
    <>
      <TicketBorder />
      <div className="title">
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <img src={logocontinente} alt="" className="titleLogo" />
                <span className="socialLogin_title socialLogin_title_text">
                  {title}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
