import React from "react";
import { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";

type InputProps = {
  isHtml?: Boolean | undefined;
  error?: FieldError | undefined;
  acceptTermsAndConditions?: FieldError | undefined;
  errorString?: String | undefined;
  alignCenter?: Boolean | undefined;
};

export default function ErrorMessage({
  alignCenter = true,
  isHtml = false,
  ...props
}: InputProps) {
  const { t } = useTranslation();
  const className = alignCenter ? "justify-content-center" : "";

  if (isHtml && props.error) {
    return (
      <div className={className}>
        <div
          dangerouslySetInnerHTML={{
            __html: props.error.message ? props.error.message.toString() : "",
          }}
        />
      </div>
    );
  }

  return (
    <div className={className}>
      {props.error && (
        <div className="form-error-message-copy2">{props.error.message}</div>
      )}
      {props.errorString && (
        <div className="form-error-message-copy2">{props.errorString}</div>
      )}
      {props.acceptTermsAndConditions && (
        <div className="form-error-message-copy2">
          {t("common.validate_password")}
        </div>
      )}
    </div>
  );
}
