import { HomeRequest } from "../../models/site-credentials/HomeRequest";
import { HomeResponse } from "../../models/site-credentials/HomeResponse";

import { Response } from "../../models/site-credentials/common/Response";

export interface IHomeService {
  nextPage(HomeRequest: HomeRequest): Promise<Response<HomeResponse>>;
}

const homeUrl = "/api/site-credentials";
export class HomeService implements IHomeService {
  async nextPage(HomeRequest: HomeRequest): Promise<Response<HomeResponse>> {
    const resp = await fetch(homeUrl, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(HomeRequest),
    });

    if (resp.ok) {
      return { kind: "success", value: await resp.json() };
    } else if (resp.status === 400) {
      const error = await resp.json();

      if (error) {
        const returnedError = error.find((c: any) => c.Code != null);
        return {
          kind: "error",
          code: returnedError.Code ? returnedError.Code : 0,
          message: returnedError.message ? returnedError.message : "",
        };
      } else {
        return {
          kind: "unexpected_error",
        };
      }
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }
}

export const homeService = new HomeService();
