import { EmailDefinition } from "../../../models/site-credentials/mobile/EmailDefinition";
import { EmailDefinitionResponse } from "../../../models/site-credentials/mobile/EmailDefinitionResponse";
import { Response } from "../../../models/site-credentials/common/Response";

export interface IEmailDefinitionService {
  EmailDefinition(
    EmailDefinition: EmailDefinition,
    emailDefinitionUrl: string
  ): Promise<Response<EmailDefinitionResponse>>;
}

export class EmailDefinitionService implements IEmailDefinitionService {
  async EmailDefinition(
    EmailDefinition: EmailDefinition,
    emailDefinitionUrl: string
  ): Promise<Response<EmailDefinitionResponse>> {
    const resp = await fetch(emailDefinitionUrl, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(EmailDefinition),
    });
    if (resp.ok) {
      return { kind: "success", value: await resp.json() };
    } else if (resp.status === 400) {
      const error = await resp.json();

      const returnedError = error[0];

      return {
        kind: "error",
        code: returnedError.Code,
        message: returnedError.message,
      };
    } else {
      return {
        kind: "unexpected_error",
      };
    }
  }
}

export const emailDefinitionService = new EmailDefinitionService();
