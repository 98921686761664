import React from "react";
import arrowRight from "../../../assets/images/arrow-right.svg";

type OptionProps = {
  labelText: string;
  informationText?: string | null;
  iconType?: "editable" | "readonly";
  type?: "tel" | "string";
  visible?: boolean;
  onClick?: () => void;
  selectedCountryCode?: string | null;
};

export default function Option({ visible = true, ...props }: OptionProps) {
  return (
    <div className="sc-option-container" onClick={props.onClick}>
      <div className={"sc-container"}>
        <div className="sc-label-container">
          <label className="sc-label-input">{props.labelText}</label>
          <div className="sc-container-text">
            <label className="sc-label">
              {visible ? props.informationText : "••••••"}
            </label>
          </div>
        </div>
        <div className="sc-container-image">
          <img src={arrowRight} alt="" className="" />
        </div>
      </div>
    </div>
  );
}
