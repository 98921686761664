import React, { useEffect, useState, ChangeEvent } from "react";
import { useForm, SubmitHandler, UseFormRegister } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import CredentialsFooter from "../../../../components/continente-credentials/footer/Footer";
import CredentialsHeader from "../../../../components/continente-credentials/header/Header";
import CredentialsForm from "../../../../components/continente-credentials/Form/Form";
import MantraButton from "../../../../components/continente-credentials/mantra-button/MantraButton";
import { EmailPinValidation } from "../../../../models/continente-credentials/mobile/EmailPinValidation";
import { FormError } from "../../../../models/continente-credentials/common/FormError";
import { pinValidationService } from "../../../../services/continente-credentials/mobile/PinValidationService";
import { useTranslation } from "react-i18next";
import { PhoneNumberAssociationResponse } from "../../../../models/continente-credentials/mobile/PhoneNumberAssociationResponse";
import PinInput from "../../../../components/continente-credentials/pin-input/PinInput";
import ErrorMessage from "../../../../components/continente-credentials/error-message/error-message";

type Form = EmailPinValidation & FormError;

export default function VerifyPin() {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const location = useLocation<PhoneNumberAssociationResponse>();
  const history = useHistory();
  const [labelPINVisible, setLabelPINVisible] = useState<boolean>(false);
  const phoneNumber =
    location.state != null ? location.state.properties.phoneNumber : "";
  const email = location.state != null ? location.state.properties.email : "";

  const formState = useForm<Form>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = formState;

  const goToFaq = (anchor?: string) => {
    if (anchor) anchor = "#" + anchor;
    history.push("/continente-credentials/faq" + anchor);
  };
  const ANCHOR_NEED_HELP = "";

  const handlePINChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    clearErrors();
    setValue("pin", value);
    setLabelPINVisible(value.length > 0 ? true : false);
  };

  const recoverPINClick = async () => {
    try {
      const response = await pinValidationService.pinValidation(
        {
          pin: "",
          email: email,
          phoneNumber: phoneNumber,
          forgotPIN: true,
        },
        location.state != null ? location.state.nextStep : ""
      );

      switch (response.kind) {
        case "success":
          history.push(response.value.nextStep.substring(4), response.value);
          break;

        case "error":
          if (response.code === "100017") {
            setError("pin", {
              message: t(
                "email_login_phonenumber_association_pin.not_correct_pin_message"
              ),
            });
            break;
          }
          break;

        default:
          setError("submitError", { message: t("common.unexpected_error") });
          break;
      }

      setLoading(false);
    } catch (e) {
      setError("submitError", { message: t("common.unexpected_error") });
      setLoading(false);
    }
  };

  const onSubmit: SubmitHandler<EmailPinValidation> = async (data) => {
    setLoading(true);

    try {
      const response = await pinValidationService.pinValidation(
        {
          pin: data.pin,
          email: email,
          phoneNumber: phoneNumber,
          forgotPIN: false,
        },
        location.state != null ? location.state.nextStep : ""
      );

      switch (response.kind) {
        case "success":
          history.push(response.value.nextStep.substring(4), response.value);
          break;
        case "error":
          if (
            response.code === "100013" ||
            response.code === "100008" ||
            response.code === "100017"
          ) {
            setError("pin", {
              message: t("pin_validation.not_correct_pin_message"),
            });
            break;
          }
          setError("submitError", { message: t("common.unexpected_error") });
          break;

        default:
          setError("submitError", { message: t("common.unexpected_error") });
          break;
      }

      setLoading(false);
    } catch (e) {
      setError("submitError", { message: t("common.unexpected_error") });
      setLoading(false);
    }
  };

  useEffect(() => {
    const pinValidator = (register: UseFormRegister<Form>) =>
      register("pin", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
        pattern: {
          value: /^[0-9]{4,6}/,
          message: t("pin_validation.not_correct_pin_message"),
        },
      });
    pinValidator(register);
  }, [register, t]);

  return (
    <>
      <CredentialsForm
        id="VerifyPin"
        form={formState}
        autoComplete="off"
        onSubmit={(e) => {
          clearErrors();
          handleSubmit(onSubmit)(e);
        }}
      >
        <div className="row justify-content-center">
          <CredentialsHeader
            title={t("pin_validation.header_title")}
            subtitle={t("pin_validation.header_message")}
            showLoading={loading}
            previousStepBackXTimes={2}
          />
        </div>
        <div className="row d-flex justify-content-center">
          <div className="input-outline-eye">
            <PinInput
              labelText={t("pin_validation.pin_input")}
              isFilled={labelPINVisible}
              isError={errors.pin ? true : false}
              errorMsg={errors.pin ? errors.pin.message : ""}
              onChange={handlePINChange}
              type={"password"}
            />
          </div>
        </div>
        <ErrorMessage error={errors.submitError} />
        <div className="row d-flex justify-content-center">
          <MantraButton
            text={t("pin_validation.button_next_message")}
            type="submit"
          />
        </div>
        <div className="contextual-information-header text-align-center">
          <span
            className="verification-resend-code link"
            onClick={() => goToFaq(ANCHOR_NEED_HELP)}
          >
            {t("common.need_help")}
          </span>
        </div>
        <div className="row d-flex justify-content-center">
          <span className="forgot-pin" onClick={recoverPINClick}>
            {" "}
            {t("pin_validation.forgot_message")}{" "}
          </span>
        </div>
        <div className="row d-flex flex-column">
          <CredentialsFooter
            contextualInformationVisible={false}
            footerAppsWebsitesIconsVisible={false}
          />
        </div>
      </CredentialsForm>
    </>
  );
}
