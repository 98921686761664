import React from "react";
import { Route, Switch } from "react-router";
import Layout from "./components/layout/Layout";
import SocialLogin from "./pages/SocialLogin";
import SocialLoginConsent from "./pages/SocialLoginConsent";

export default function SocialLoginApp() {
  return (
    <Switch>
      <Layout>
        <Route path="/SocialLogin" component={SocialLogin} />
        <Route path="/SocialLoginConsent" component={SocialLoginConsent} />
      </Layout>
    </Switch>
  );
}
