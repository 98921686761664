export type HomeRequest = {
  homeOption: HomeOption;
};

export enum HomeOption {
  Email = 0,
  PhoneNumber = 1,
  Password = 2,
  LoyaltyCard = 3,
}
