import React, { useEffect, useState } from "react";

import SimpleBar from "simplebar-react";
import ButtonContinente from "../components/button-continente/ButtonContinente";
import Header from "../components/header/Header";
import Loading from "../components/loading/Loading";

import { consentService } from "../services/ConsentService";
import { authorizationService } from "../services/AuthorizationService";
import { ClientConsents } from "../models/ClientConsents";
import { useAuthorizeQueryParams } from "../hooks/useAuthorizeQueryParams";

export default function SocialLoginConsent() {
  const [clientConsents, setClientConsents] = useState<ClientConsents>({
    clientName: "",
    consents: [],
  });
  const [loading, setLoading] = useState(true);
  const authorizeQueryParams = useAuthorizeQueryParams();

  useEffect(() => {
    const getConsents = async () => {
      try {
        const resp = await consentService.get(
          authorizeQueryParams.client_id || ""
        );

        switch (resp.kind) {
          case "success":
            setClientConsents(resp.value);
            break;
          case "unauthorized": {
            const redirectUrl =
              authorizationService.generateUrl(authorizeQueryParams);
            window.location.href = redirectUrl.toString();

            break;
          }
        }
      } finally {
        setLoading(false);
      }
    };

    getConsents();
  }, [authorizeQueryParams]);

  const onSubmit = async () => {
    setLoading(true);

    try {
      const resp = await consentService.accept({
        clientId: authorizeQueryParams.client_id || "",
        consentIds: clientConsents.consents.map((c) => c.id),
      });

      switch (resp.kind) {
        case "success":
        case "unauthorized": {
          const redirectUrl =
            authorizationService.generateUrl(authorizeQueryParams);

          window.location.href = redirectUrl.toString();

          break;
        }
        case "error":
          setLoading(false);

          break;
      }
    } catch {
      setLoading(false);
    }
  };

  const clickDeclinedOrCancelledError = async () => {
    await consentService.declinedOrCancelledError({
      state: authorizeQueryParams.state || "",
      redirect_uri: authorizeQueryParams.redirect_uri || "",
    });
  };

  return (
    <>
      {loading && <Loading />}
      <div id="socialLoginConsent">
        <Header title="Utilize o seu registo Continente Online" />

        <div className="socialLoginConsent_body">
          <div className="socialLoginConsent_body_text">
            Caso consinta, a {clientConsents.clientName} irá receber os
            seguintes dados pessoais da sua conta Continente Online:
            <ul className="socialLoginConsents_list">
              {clientConsents.consents.map((c) => (
                <li key={c.id}>
                  <span className="socialLoginConsent_body_text">
                    {c.displayText}
                  </span>
                </li>
              ))}
            </ul>
            <div className="personalDataList"></div>
            <SimpleBar
              forceVisible="y"
              autoHide={false}
              className="socialLoginConsent_body_text socialLoginConsent"
              scrollbarMaxSize={50}
            >
              <div className="me-4 consents-text">
                <p>
                  <span>
                    A entidade{" "}
                    <strong>
                      <u>Responsável pelo Tratamento dos seus Dados Pessoais</u>
                    </strong>{" "}
                    é a <strong>Modelo Continente Hipermercados, S.A.</strong>,
                    com sede na Rua João Mendonça, n.º 505, 4464-503 – Senhora
                    da Hora, matriculada na Conservatória do Registo
                    Predial/Comercial do Porto, sob o NIPC 502 011
                  </span>{" "}
                  <span>
                    475, <strong>exclusiva proprietária do site&nbsp;</strong>
                  </span>
                  <a href="http://www.continente.pt">
                    <strong>
                      <span>www.continente.pt</span>
                    </strong>
                  </a>
                  <span>, doravante ‘’MCH’’.</span>
                </p>
                <p>
                  <span>Caso pretenda que o seu registo em&nbsp;</span>
                  <a href="http://www.wells.pt">
                    <span>www.wells.pt</span>
                  </a>
                  <span>
                    &nbsp;seja criado através da utilização das credenciais
                    (e-mail &nbsp;e <em>password</em>) da sua conta Continente
                    Online (
                  </span>
                  <a href="http://www.continente.pt">
                    <span>www.continente.pt</span>
                  </a>
                  <span>
                    ) e que esse registo seja facilitado mediante o
                    pré-preenchimento de alguns campos – <strong>e</strong>
                    <strong>-mail</strong>,&nbsp;<strong>nome</strong>,
                    <strong>&nbsp;morada&nbsp;</strong>(morada indicada em&nbsp;
                  </span>
                  <a href="http://www.continente.pt">
                    <span>www.continente.pt</span>
                  </a>
                  <span>&nbsp;como sendo a morada de faturação)</span>
                  <strong>
                    <span>, número de telefone&nbsp;</span>
                  </strong>
                  <span>e</span>
                  <strong>
                    <span>&nbsp;número de Cartão Continente&nbsp;</span>
                  </strong>
                  <span>
                    (este último, apenas se existir na sua conta Continente
                    Online)
                  </span>
                  <span>
                    &nbsp;–, procederemos à transmissão desses dados pessoais,{" "}
                    <u>caso nos preste o seu consentimento</u>, para a{" "}
                    <strong>Pharmacontinente – Saúde e Higiene, S.A.</strong>,
                    com sede na Rua João Mendonça, n.º 529, 4464-503 – Senhora
                    da Hora, matriculada na Conservatória do Registo
                    Predial/Comercial do Porto, sob o NIPC 508 037
                  </span>{" "}
                  <span>
                    514, doravante{" "}
                    <strong>exclusiva proprietária do site</strong>&nbsp;
                  </span>
                  <a href="http://www.wells.pt">
                    <strong>
                      <span>www.wells.pt</span>
                    </strong>
                  </a>
                  <span>
                    , doravante ‘’Wells’’<strong>,&nbsp;</strong>entidade que
                    será, a partir do momento em que finalize o seu registo
                    nesse site,
                    <strong>
                      &nbsp;
                      <u>Responsável pelo Tratamento dos seus Dados Pessoais</u>
                    </strong>
                    , conforme{" "}
                    <strong>
                      Informação sobre o Tratamento de Dados Pessoais&nbsp;
                    </strong>
                    pela mesma disponibilizada.
                  </span>
                </p>
                <p>
                  <span>
                    Informamos que o seu <em>login&nbsp;</em>em&nbsp;
                  </span>
                  <a href="http://www.wells.pt">
                    <span>www.wells.pt</span>
                  </a>
                  <span>
                    &nbsp;terá de ser{" "}
                    <strong>
                      <u>sempre</u>
                    </strong>{" "}
                    efetuado com as credenciais da sua conta Continente Online e
                    que o apagamento desta conta implicará que deixe de
                    conseguir fazer <em>login</em> na sua conta Wells.
                  </span>
                </p>
                <p>
                  <span>
                    Mais esclarecemos que todos os dados pessoais <em>supra</em>{" "}
                    mencionados,{" "}
                    <u>com exceção do e-mail (credencial de acesso)</u>, poderão
                    ser alterados no momento da criação do seu registo e/ou na
                    sua área pessoal, em&nbsp;
                  </span>
                  <a href="http://www.wells.pt">
                    <span>www.wells.pt</span>
                  </a>
                  <span>.&nbsp;</span>
                </p>
                <p>
                  <span>
                    Se alterar a sua <em>password</em> na conta do Continente
                    Online, deverá fazer o seu <em>login</em> em&nbsp;
                  </span>
                  <a href="http://www.wells.pt">
                    <span>www.wells.pt</span>
                  </a>
                  <span>
                    &nbsp;com essa nova <em>password</em>.
                  </span>
                </p>
                <p>
                  <span>
                    Enquanto titular de dados pessoais, poderá, a todo e
                    qualquer momento, revogar o consentimento prestado à MCH
                    para a presente transmissão de dados, bem como exercer os
                    seus restantes direitos em matéria de proteção de dados –{" "}
                    <strong>
                      direito de acesso, direito de retificação, direito de
                      apagamento, direito de limitação, direito de portabilidade
                      e direito de oposição&nbsp;
                    </strong>
                    –<strong>&nbsp;</strong>mediante solicitação para o seguinte
                    endereço de e-mail:&nbsp;
                  </span>
                  <a href="mailto:dadospessoais@sonaemc.com">
                    <span>dadospessoais@sonaemc.com</span>
                  </a>
                </p>
                <p>
                  <u>
                    <span>
                      No que respeita à possibilidade de revogar o consentimento
                      prestado à MCH, cumpre advertir que tal não invalida o
                      tratamento de dados pessoais realizado até então
                    </span>
                  </u>
                  <span>.</span>
                </p>
                <p>
                  <span>
                    Procederemos à análise cuidada dos seus pedidos, avaliando a
                    sua legitimidade e pertinência, comprometendo-nos a dar
                    resposta no prazo legal para o efeito.&nbsp;
                  </span>
                </p>
                <p>
                  <span>
                    Caso necessite, poderá contactar o nosso Encarregado de
                    Proteção de Dados Pessoais (EPD), através do seguinte
                    endereço de e-mail:&nbsp;
                  </span>
                  <a href="mailto:dpo@sonaemc.com">
                    <span>dpo@sonaemc.com</span>
                  </a>
                </p>
                <p>
                  <span>
                    Se entender que não respeitámos os seus direitos, poderá
                    apresentar reclamação junto da Comissão Nacional de Proteção
                    de Dados (CNPD):&nbsp;
                  </span>
                </p>
                <p>
                  <strong>
                    <span>Endereço:&nbsp;</span>
                  </strong>
                  <span>
                    &nbsp;Av. D. Carlos I, 134 – 1.º, 1200-651 – Lisboa
                  </span>
                </p>
                <p>
                  <strong>
                    <span>Telefone:</span>
                  </strong>
                  <span>&nbsp;+351 213928400</span>
                </p>
                <p>
                  <strong>
                    <span>Fax:</span>
                  </strong>
                  <span>&nbsp;+351 213976832</span>
                </p>
                <p>
                  <strong>
                    <span>Endereço Eletrónico:</span>
                  </strong>
                  <span>&nbsp;</span>
                  <a href="mailto:geral@cnpd.pt">
                    <span>geral@cnpd.pt</span>
                  </a>
                </p>
              </div>
            </SimpleBar>
            <div className="socialLoginConsent_buttons mt-4">
              <ButtonContinente
                text="CANCELAR"
                invertedColor={true}
                type="button"
                onClick={clickDeclinedOrCancelledError}
              />

              <ButtonContinente
                text="CONSENTIR"
                invertedColor={false}
                type="button"
                onClick={onSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
