import React, { useEffect, useState, ChangeEvent } from "react";
import { useForm, SubmitHandler, UseFormRegister } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import CredentialsFooter from "../../../../components/continente-credentials/footer/Footer";
import CredentialsHeader from "../../../../components/continente-credentials/header/Header";
import MantraButton from "../../../../components/continente-credentials/mantra-button/MantraButton";
import CredentialsForm from "../../../../components/continente-credentials/Form/Form";
import CardInput from "../../../../components/continente-credentials/card-input/CardInput";
import { EmailPinRecover } from "../../../../models/continente-credentials/mobile/EmailPinRecover";
import { FormError } from "../../../../models/continente-credentials/common/FormError";
import { pinRecoverService } from "../../../../services/continente-credentials/mobile/PinRecoverService";
import { useTranslation } from "react-i18next";
import { PhoneNumberAssociationResponse } from "../../../../models/continente-credentials/mobile/PhoneNumberAssociationResponse";
import ErrorMessage from "../../../../components/continente-credentials/error-message/error-message";

type Form = EmailPinRecover & FormError;

export default function RecoverPin() {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const location = useLocation<PhoneNumberAssociationResponse>();
  const history = useHistory();
  const [labelPINVisible, setLabelPINVisible] = useState<boolean>(false);
  const [cardValue, setCardValue] = useState<string>();
  const phoneNumber =
    location.state != null ? location.state.properties.phoneNumber : "";
  const email = location.state != null ? location.state.properties.email : "";

  const formState = useForm<Form>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = formState;

  const goToFaq = (anchor?: string) => {
    if (anchor) anchor = "#" + anchor;
    history.push("/continente-credentials/faq" + anchor);
  };
  const ANCHOR_NEED_HELP = "";

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (
      e.key !== "Enter" &&
      (!e.key.match(/[0-9\s]/) ||
        (e.target as HTMLInputElement).value.length > 12)
    ) {
      e.preventDefault();
    }
  };

  const handleCardChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    clearErrors();

    let cardNumber = value.replace(/\s/g, "");

    setValue("cardNumber", cardNumber);
    setLabelPINVisible(cardNumber.length > 0 ? true : false);
    setCardValue(cardNumber);
  };

  const onSubmit: SubmitHandler<EmailPinRecover> = async (data) => {
    setLoading(true);

    try {
      const response = await pinRecoverService.pinRecover(
        {
          cardNumber: data.cardNumber,
          email: email,
          phoneNumber: phoneNumber,
        },
        location.state != null ? location.state.nextStep : ""
      );

      switch (response.kind) {
        case "success":
          history.push(response.value.nextStep.substring(4), response.value);
          break;
        case "error":
          if (response.code === "100003" || response.code === "100020") {
            setError("cardNumber", {
              message: t("pin_recover.invalid_card"),
            });
            break;
          }
          setError("submitError", { message: t("common.unexpected_error") });
          break;

        default:
          setError("submitError", { message: t("common.unexpected_error") });
          break;
      }

      setLoading(false);
    } catch (e) {
      setError("submitError", { message: t("common.unexpected_error") });
      setLoading(false);
    }
  };

  useEffect(() => {
    const CardValidator = (register: UseFormRegister<Form>) =>
      register("cardNumber", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
        pattern: {
          value: /^[0-9]{13}$/,
          message: t("card_association.invalid_loyaltycard"),
        },
      });
    CardValidator(register);
  }, [register, t]);

  return (
    <>
      <CredentialsForm
        id="RecoverPin"
        form={formState}
        autoComplete="off"
        onSubmit={(e) => {
          clearErrors();
          handleSubmit(onSubmit)(e);
        }}
      >
        <div className="row justify-content-center">
          <CredentialsHeader
            title={t("pin_recover.header_title")}
            subtitle={t("pin_recover.header_message")}
            showLoading={loading}
            previousStepBackXTimes={1}
          />
        </div>
        <div className="row d-flex justify-content-center">
          <div className="input-outline-eye">
            <CardInput
              labelText={t("pin_recover.card_input")}
              isFilled={labelPINVisible}
              isError={errors.cardNumber ? true : false}
              errorMsg={errors.cardNumber ? errors.cardNumber.message : ""}
              onChange={handleCardChange}
              onKeyPress={handleKeyPress}
              value={cardValue}
            />
          </div>
        </div>
        <ErrorMessage error={errors.submitError} />
        <div className="row d-flex justify-content-center">
          <MantraButton
            text={t("pin_recover.button_next_message")}
            type="submit"
          />
        </div>
        <div className="contextual-information-header text-align-center">
          <span
            className="verification-resend-code link"
            onClick={() => goToFaq(ANCHOR_NEED_HELP)}
          >
            {t("common.need_help")}
          </span>
        </div>
        <div className="row d-flex flex-column">
          <CredentialsFooter
            contextualInformationVisible={false}
            footerAppsWebsitesIconsVisible={false}
          />
        </div>
      </CredentialsForm>
    </>
  );
}
